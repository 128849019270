// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import Button from "../Button/Button";
import FilterDataClass from "../Filter/SearchClass";
import DialogList from "../List/DialogList";

// Manage Dependencies

const Chip = (props) => {

    const [json, setJson] = React.useState(props.json);
    const minCharsForFilterGlobal = 2;
    const [chipId, setChipId] = React.useState(null);
    const [chipValue, setChipValue] = React.useState("");
    const [chipData, setChipData] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState(props.json);
    const [count, setCount] = React.useState(0);
    const [chipDefined, setChipDefined] = React.useState(false);
    const [deleteChipHandler, setDeleteChipHandler] = React.useState(0);

    function filterData(input, data, keysToFilter, minCharsForFilter) {

        const f = new FilterDataClass();
        const filteredData = f.filter(input.value, data, keysToFilter, minCharsForFilterGlobal);

        if(input.value.length >= minCharsForFilter) {
            if(filteredData) {
                setFilteredData(filteredData);
            }
        }else{
            setFilteredData([]);
        }

        setCount(count + 1);
        setChipValue(chipValue => input.value);
    }

    function addChipToContainer(item) {
        item.teams.push("10");
        setChipDefined(true);
        setChipValue(`${item.name} ${item.surname}`);
        setChipId(item.player_id);

        props.canAdd(true);
        props.updateChipId(item.player_id);
        props.addItemToList(item);

        showFilteredData(false);
    }

    function removeChip() {
        setDeleteChipHandler(deleteChipHandler + 1);
    }

    function getData(item) {
        setChipId(item.player_id);
        setChipData(item);
        addChipToContainer(item);
    }

    function showFilteredData(toShow = false) {

        const filterResultContainer = document.getElementById("wg-filterResultContainer" + props.index);

        if(toShow) {
            if(!filterResultContainer.classList.contains('wg-filterResultContainerVisible')) {
                filterResultContainer.classList.add("wg-filterResultContainerVisible");
            }
        }else{
            if(filterResultContainer.classList.contains('wg-filterResultContainerVisible')) {
                filterResultContainer.classList.remove("wg-filterResultContainerVisible");
            }
        }
    }

    function setInputWidth(input) {

        const chipValueHidden = document.getElementById("wg-chipFakeValue" + props.index);

        chipValueHidden.textContent = input.value;
        input.style.width = chipValueHidden.offsetWidth + "px"
    }

    React.useEffect(() => {
        if(count > 0) {
            if(chipValue.length >= minCharsForFilterGlobal) {
                if(filteredData && filteredData.length <= 0) {
                    showFilteredData(false);
                }else{
                    showFilteredData(true);
                }
            }else{
                showFilteredData(false);
            }
        }
        // eslint-disable-next-line
    }, [count]);

    React.useEffect(() => {
        setJson(props.json);
    }, [props.json]);


    React.useEffect(() => {
        if(chipId) {
            props.deleteItemFromList(chipData.player_id ,props.index);
        }
        // eslint-disable-next-line
    }, [deleteChipHandler]);

    return (
        <div className={"wg-chip " + (chipDefined ? " wg-chipDefined" : "")} id={"wg-chipPlayerId_" + props.index}>
            <div className={"wg-chipSubContainer"} id={"wg-chip" + props.index} key={new Date().getTime()}>
                <span id={"wg-chipFakeValue" + props.index} className={"wg-chipValue"} />
                <input type={"text"} id={"wg-chipValue" + props.index} autoFocus={true}
                       onChange={(event) => filterData(event.currentTarget, json, ["name", "surname"], 2)}
                       onInput={(event) => setInputWidth(event.currentTarget)} value={chipValue}
                       disabled={(chipDefined)}
                />
                <Button customclasses={"wg-removeChip"} id={"wg-removeChip" + props.index} value={"X"} icon={""} size={"small"}
                        vtype={"simple"} state={"enabled"} onClick={() => removeChip()} />
                <div className={"wg-filterResultContainer"} id={"wg-filterResultContainer" + props.index}>
                    <DialogList data={filteredData} getData={getData} onClick={() => addChipToContainer()} />
                </div>
            </div>
        </div>
    );
};

export default Chip;