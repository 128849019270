// Importing WG Dependencies
import {toast} from "react-toastify";

class storage {// Define Session Variables

     static manageStorage(type, action, sessionName, sessionValue, storageValue = null) {

         if(!type || !action || !sessionName) {
             return;
         }

         // Check action value
         switch (action) {
             case 'get':
                 // Check session type
                 if (type === "local") {
                     if (localStorage.getItem(sessionName) !== null) {
                         storageValue = localStorage.getItem(sessionName);
                         return storageValue;
                     }
                 } else if (type === "session") {
                     if (sessionStorage.getItem(sessionName) !== null) {
                         storageValue = sessionStorage.getItem(sessionName);
                         return storageValue;
                     }
                 } else {
                     toast.error("Wrong session type");
                 }
                 break;
             case 'set':
                 if (type === "local") {
                     localStorage.setItem(sessionName, sessionValue);
                 } else if (type === "session") {
                     sessionStorage.setItem(sessionName, sessionValue);
                 } else {
                     toast.error("Wrong session type");
                 }
                 break;
             default:
                 return null;
         }

         return storageValue;
    }

}

export default storage;
