/* eslint-disable */

// Importing React Dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

// Importing GG Dependencies
import ListItem from "./ListItem";
import Button from "../Button/Button";
import FilterDataClass from "../Filter/SearchClass";
import SortDataClass from "../Filter/SortClass";
import NoResultFound from "../Filter/NoResultFound";
import Dialog from "../Dialog/Dialog";
import FilterArea from "../FilterArea/FilterArea";
import UserAvatar from "../../../assets/img/avata_default.png";

// Manage Dependencies


const ListItems = (listProps) => {

    // Role in local storage
    const role = localStorage.getItem("role");

    // List Items state
    const [itemList, setItemList] = React.useState(listProps.items);
    // Set Player ID
    const [playerId, setPlayerId] = React.useState(null);

    // Test for filter trigger
    const [triggerFilter, setTriggerFilter] = React.useState(0);
    const [triggerSort, setTriggerSort] = React.useState(0);
    const [triggerTeam, setTriggerTeam] = React.useState(0);

    // Filtered and sorted data
    const [filteredData, setFilteredData] = React.useState(itemList);
    const [teamPlayers, setTeamPlayers] = React.useState([]);

    // eslint-disable-next-line
    const [sortedData, setSortedData] = React.useState([]);



    const history = useHistory();

    // Dialog State
    // eslint-disable-next-line
    const [dialogVisible, setDialogVisible] = React.useState(listProps.dialogVisible);


    const [extraOptionsButtonActive, setExtraOptionsButtonActive] = React.useState("normal");

    // Render List with settings and roles
    const listAuth = listProps.auth;
    const avaiableActions = listProps.avaiableActions;
    const listColumns = listProps.columns;

    // Methods

    const deleteListItem = (itemId) => {
        if(!itemId) {
            return;
        }

        const deletePlayerAction = () => {
            setPlayerId(itemId);
        };

        ReactDOM.render(<Dialog visible={true} position={{centered: true}}
                                size={{width: 300, height: 300}} title={"Sicuro di voler cancellare il giocatore?"}
                                primarybtn={{
                                    enabled: true,
                                    value: "Conferma",
                                    action: deletePlayerAction
                                }}
                                secondarybtn={{
                                    enabled: true,
                                    value: "Annulla",
                                    action: () => ReactDOM.unmountComponentAtNode(document.getElementById("wg-deleteDialogPlayers"))
                                }}
        />, document.getElementById("wg-deleteDialogPlayers"));

    };

    const filterData = (input, data, keysToFilter, minCharsForFilter, filterByTeam) => {
        const f = new FilterDataClass();
        let filteredData;

        if(filterByTeam !== "Scegli squadra") {
            filteredData = f.filter(input, data, keysToFilter, minCharsForFilter, true, {selectedTeam: filterByTeam});
        }else{
            filteredData = f.filter(input, data, keysToFilter, minCharsForFilter, true, null);
        }

        if(input.length >= minCharsForFilter) {
            if(filteredData) {
                setFilteredData(filteredData);
            }
        }else{
            if(filterByTeam && filterByTeam !== "Scegli squadra") {
                setFilteredData(teamPlayers);
            }else{
                setFilteredData(data);
            }
        }
    };

    const filterByTeam = team => {
        if(!team) {
            return;
        }
        const f = new FilterDataClass();
        let filteredData = [];

        if(team === "Scegli squadra") {
            filteredData = itemList;
        }else{
            filteredData = f.filter(team, itemList, ["team_names"], 1, true, {selectedTeam: team});
            setTeamPlayers(filteredData);
        }

        setFilteredData(filteredData);

    }

    const sortData = (evt, data, keyToSort) => {
        if(evt && data) {
            const sortButton = document.getElementById("wg-sort");
            const direction = sortButton.classList.contains("wi-sort-desc") ? "asc" : "desc";

            if(direction) {
                if(direction === "asc") {
                    sortButton.classList.remove("wi-sort-desc");
                    sortButton.classList.add("wi-sort-asc");
                }else{
                    sortButton.classList.remove("wi-sort-asc");
                    sortButton.classList.add("wi-sort-desc");
                }
            }

            let sortedItemList;

            if(!triggerTeam) {
                sortedItemList = new SortDataClass().sortData(data, keyToSort, direction)
            }else{
                sortedItemList = new SortDataClass().sortData(teamPlayers, keyToSort, direction)
            }

            setSortedData(Array(sortedItemList));
        }
    };

    const extraOptions = (evt) => {
        if(!evt) {
            return;
        }

        let target = evt.currentTarget;
        let activeExtraOptionsMenu = document.getElementsByClassName("wg-extraOptions")[0];

        if(!target.parentElement.classList.contains("visible")) {
            setTimeout(() => {
                target.parentElement.classList.add("visible");
                setExtraOptionsButtonActive("active");
            }, 50);
        }else{
            target.parentElement.classList.remove("visible");
            setExtraOptionsButtonActive("normal");
        }

        document.addEventListener("click", evt => {

            const clickTarget = evt.target;

            if(activeExtraOptionsMenu.classList.contains("visible")) {
                if(clickTarget !== activeExtraOptionsMenu) {
                    activeExtraOptionsMenu.classList.remove("visible");
                }
            }
        });

    }

    const closeOptionsMenu = () => {
        const extraOptionsMenu = document.getElementsByClassName("wg-extraOptions");

        if(extraOptionsMenu.length > 0) {
            extraOptionsMenu[0].classList.remove("visible");
        }
    }

    const rowClick = (event, item) => {

        if(item) {
            if(listProps.argument) {
                switch(listProps.argument) {
                    case "registry":
                        history.push({
                            pathname: "/giocatore",
                            state: item
                        });
                        break;
                    case "teams":
                        history.push({
                            pathname: "/squadra",
                            state: item
                        });
                        break;
                    case "fees":
                    case "payments_fees":
                    case "addFee":
                        listProps.handleClick ? listProps.handleClick(item) : null;
                        break;
                    case "player_payments":
                        history.push({
                            pathname: "/pagamenti_giocatore",
                            state: item
                        });
                        break;
                    case "player_ratings":
                        history.push({
                            pathname: "/valutazione_giocatore",
                            state: item
                        });
                        break;
                }
            }
        }

    };

    const getSelectedTeam = team => {
        setTriggerTeam(team);
    }

    React.useEffect(() =>   {
        // Handler for update
    }, [listProps.dialogVisible]);

    React.useEffect(() => {
        setItemList(listProps.items);
        setFilteredData(listProps.items);
    }, [listProps.items]);

    React.useEffect(() => {
        // Filter method
        if(document.getElementById("inputPlayer")) {
            filterData(document.getElementById("inputPlayer").value, itemList, (listProps.keysToFilter ? listProps.keysToFilter : ["name", "surname", "birth_date", "certificate_expiration", "city", "team_names"]), 1, triggerTeam);
        }
    }, [triggerFilter]);

    React.useEffect(() => {
        // Call filter method
        if(triggerSort > 0) {
            sortData(document.getElementById("sortPlayer"), filteredData, "surname")
        }
    }, [triggerSort]);

    React.useEffect(() => {

        if(playerId) {

            // Remove item from database
            if (listProps.argument) {
                switch (listProps.argument) {
                    case 'teams':
                        if (listProps.onDelete) {
                            listProps.onDelete(playerId);
                        }
                        break;
                }
                // Remove item from state
                const newListItems = itemList.filter((item) => item.player_id !== playerId);
                const newFilteredData = filteredData.filter((item) => item.player_id !== playerId);
                setItemList(newListItems);
                setFilteredData(newFilteredData);
                ReactDOM.unmountComponentAtNode(document.getElementById("wg-deleteDialogPlayers"));
            }
        }
// eslint-disable-next-line
    }, [playerId]);

    return (
        <React.Fragment>
            <div className={"wg-listContainer"}>
                {itemList && itemList.length > 0  || listProps.ignoreLength ?
                    <React.Fragment>
                        {/*Show filter list section*/}
                        <div className={"wg-row-inline wg-filterTop"}>
                            {listProps.showFilterArea === false ?
                                null
                            :
                                <FilterArea
                                    argument={listProps.argument}
                                    teamId={listProps.teamId}
                                    placeholder={"Filtra per nome, cognome, ..."}
                                    onFilter={() => setTriggerFilter(new Date().getTime())}
                                    onSort={() => setTriggerSort(new Date().getTime())}
                                    onChangeTeam={filterByTeam}
                                    extraOptions={(evt) => extraOptions(evt)}
                                    extraOptionsButtonState={extraOptionsButtonActive}
                                    closeExtraOptionsMenu={closeOptionsMenu}
                                    showSort={listProps.showSort === false ? false : true}
                                    getSelectedTeam={team => getSelectedTeam(team)}
                                />
                            }

                        </div>

                        {!listProps.listType || listProps.listType !== "card" ?
                            <table>
                                {(listColumns && listColumns.length > 0) ?
                                    <thead>
                                    <tr>
                                        <th key={"--pictureIcon"}/>
                                        {listColumns.map((column, index) => (
                                            (column.unify && typeof (column.unify.visible) === "undefined" && column.unify.visible !== false) ?
                                                <th key={column.unify.key + index}>
                                                    {column.unify.value}
                                                </th>
                                                : (column.aggregate && typeof (column.aggregate.visible) === "undefined" && column.aggregate.visible !== false) ?
                                                <th key={column.aggregate.key + index}>
                                                    {column.aggregate.value}
                                                </th>
                                                : (column.dynamic && typeof (column.dynamic.visible) === "undefined" && column.dynamic.visible !== false &&
                                                    column.dynamic.group && column.dynamic.group.length > 0) ?
                                                    <th key={column.dynamic.key + column.dynamic.description}>
                                                        {column.dynamic.description}
                                                    </th>
                                                    : (column.visible !== false) ?
                                                        <th key={column.key + index}>
                                                            {column.value}
                                                        </th>
                                                        :
                                                        null
                                        ))}
                                    </tr>
                                    </thead>
                                    :
                                    null
                                }
                                <tbody>
                                {(filteredData && filteredData.length > 0) ?
                                    filteredData.map((item) =>
                                        <tr key={item.player_id} id={item.player_id} onClick={
                                            (avaiableActions && avaiableActions.rowActions && avaiableActions.rowActions.length > 0 && avaiableActions.rowActions.includes("click") ? (event) => rowClick(event, item) : null)
                                        }>
                                            <td>
                                                <div className={"wg-tableIcon"}>
                                                    <img src={item.photo ? item.photo : UserAvatar} alt={"User Avatar"} />
                                                </div>
                                            </td>
                                            <ListItem key={"item-" + item.player_id + "index"} columns={listColumns}
                                                      data={item} />

                                            {/* Row actions */}
                                            {listAuth && listAuth.edit && avaiableActions && avaiableActions.rowActions && avaiableActions.rowActions.length > 0 ?
                                                <td id={"wg-rowActions"} className={"wg-rowActions"}>
                                                    {
                                                        avaiableActions.rowActions.includes("edit") ?
                                                            <Button vtype={"simple"} state={"enabled"} size={"standard"}
                                                                    value={"Modifica"}
                                                                    />
                                                            :
                                                            null
                                                    }

                                                    {
                                                        avaiableActions.rowActions.includes("delete") ?
                                                            <Button vtype={"simple"} state={"enabled"} size={"standard"}
                                                                    value={"Rimuovi"}
                                                                    customclasses={"delete"}
                                                                    onClick={() => deleteListItem(item.player_id)} />
                                                            :
                                                            null
                                                    }
                                                </td>
                                                :
                                                null
                                            }
                                        </tr>)
                                    :
                                    null
                                }
                                </tbody>
                            </table>
                            :
                            <React.Fragment>
                                <div className={"wg-mainCardContainer"}>
                                    {listProps.addCard && (role === "admin" || role === "officeManager") ?
                                        <div className={"wg-cardItem addCard"} onClick={event => rowClick(event, {})}>
                                            <div className={"wg-cardIcon"}>
                                                <i className={"wi-plus"} aria-hidden={"true"}/>
                                            </div>
                                            <div className={"wg-cardContent"}>
                                                <span>{listProps.addCard.title ? listProps.addCard.title : "-"}</span>
                                                <span>{listProps.addCard.description ? listProps.addCard.description : "-"}</span>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {filteredData && filteredData.length > 0 ?
                                        filteredData.map((data, dataIndex) => {
                                          if(Number(data.is_subscription_fee) === 1)  {
                                              return(
                                                  <div className={"wg-cardItem"} key={data.description + data.fee + dataIndex} onClick={event => rowClick(event, data)}>
                                                      <div className={"wg-cardIcon"}>
                                                          <div style={(data.color ? {backgroundColor: data.color} : null)} className={"wg-cardBackground"}/>
                                                          <i className={"wi-teams"} aria-hidden={"true"} style={(data.color ? {color: data.color} : null)} />
                                                      </div>
                                                      <div className={"wg-cardContent"}>
                                                          <span>Tassa d'iscrizione</span>
                                                          <span id={data.description ? "fee_" + data.description : "span_" + dataIndex}>{data.fee ? data.fee + " €" : ""}</span>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                        })
                                        :
                                        null
                                    }

                                    {filteredData && filteredData.length > 0 ?
                                        filteredData.map((data, dataIndex) => {
                                            if(data && Number(data.is_subscription_fee) === 0 || listProps.argument === "teams" || listProps.argument === "payments_fees" || listProps.argument === "addFee") {
                                                if(listProps.columns && listProps.columns.length > 0) {

                                                    const id = data[listProps.columns[0]];
                                                    let firstInfo = data[listProps.columns[1]];
                                                    let secondInfo = data[listProps.columns[2]];

                                                    const secondInfoKey = listProps.columns[2];
                                                    let secondInfoArgument = "";

                                                    if(secondInfoKey === "players_count") {
                                                        secondInfoArgument = "giocatori";
                                                    }

                                                    if(secondInfoKey === "fee") {
                                                        secondInfoArgument = "€";
                                                    }

                                                    if(listProps.argument === "payments_fees") {
                                                        if(Number(data.all_teams) === 1) {
                                                            secondInfo = "Tutte le squadre";
                                                        }
                                                    }

                                                    if(listProps.argument === "addFee") {
                                                        firstInfo = firstInfo + "€";
                                                    }

                                                    return(
                                                        <div className={listProps.argument === "payments_fees" ? "wg-cardItem fees" : "wg-cardItem"} key={dataIndex}
                                                             onClick={event => rowClick(event, data)}>
                                                            <div className={"wg-cardIcon"}>
                                                                <div style={(data.color ? {backgroundColor: data.color} : null)} className={"wg-cardBackground"}/>
                                                                <i className={"wi-teams"} aria-hidden={"true"} style={(data.color ? {color: data.color} : null)} />
                                                            </div>
                                                            <div className={"wg-cardContent"}>
                                                                <span id={data.description ? "fee_name" +   data.description : "span_" + dataIndex}>{firstInfo ? firstInfo : "-"}</span>
                                                                <span id={data.description ? "fee_" + data.description : "span_" + dataIndex}>{secondInfo ? secondInfo + " " + secondInfoArgument : "-"}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                        })
                                        :
                                        null
                                    }
                                </div>
                            </React.Fragment>
                        }

                    </React.Fragment>
                    :
                    listProps.showErrorIcon ? <NoResultFound /> : typeof listProps.showErrorIcon === "undefined" ? <NoResultFound /> : null
                }
                {(listAuth && listAuth.edit && avaiableActions && avaiableActions.rowActions && avaiableActions.rowActions.length > 0) ?
                    avaiableActions.rowActions.includes("add") ?
                        <Button customclasses={"wg-addBtnDialog wg-addTeam"} vtype={"add"} state={(dialogVisible ? "disabled" : "enabled")} size={"standard"}
                                icon={"wi-plus"}
                                onClick={() => listProps.setDialogVisible(true)}
                        />
                        :
                        null
                    :
                    null
                }
                {(listProps.dialogVisible) ?
                    (listProps.dialogActions) ?
                        listProps.dialogActions(true)
                    :
                        null
                :
                    null
                }
            </div>
            <div id={"wg-deleteDialogPlayers"} />
        </React.Fragment>
    );
};

export default ListItems
