// Importing React Dependencies
import React from 'react';

// Importing Routes Dependencies
import {Router, Switch} from 'react-router-dom';

// Importing Notify Component
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// Importing WG Dependencies
import Routes from '../components/routes/Routes';
import MainMenu from "../components/common/Menu/MainMenu";
import ClassManager from "../components/utility/ClassManager/ClassManager";
import { useHistory } from 'react-router-dom';


// Manage Dependencies

function AppWrapper() {


    if(!localStorage.getItem("season") || !localStorage.getItem("role")) {
        document.cookie = "token=; expires=-1; Path=/";
        window.location.href = "/login";
    }

    const history = new useHistory();
    const role = localStorage.getItem("role") ? localStorage.getItem("role") : null;
    let authorized = true;

    const [routeTitle, setRouteTitle] = React.useState(history.location.pathname.replace(/[#_/]/g, " "));
    const [description, setDescription] = React.useState("Gestisci i dati dei giocatori appartenenti alla tuà società");
    const path = history.location.pathname;

    if(role && path) {
        switch (path) {
            case "/anagrafica":
            case "/pagamenti":
                if(role !== "admin" && role !== "officeManager") {
                    authorized = false;
                }
                break;
            case "/area_tecnica":
                if(role !== "admin" && role !== "trainer") {
                    authorized = false;
                }
                break;
            case "/squadre":
                if(role !== "admin" && role !== "officeManager" && role !== "trainer") {
                    authorized = false;
                }
                break;
            default:
                authorized = true;
        }

        if(!authorized) {
            window.location.href = "/";
        }
    }

    function classManager() {

        // Remove unnecessary class from DOM elements
        ClassManager.remove("wg-mainContainer", "reduced");
        ClassManager.remove("wg-playerDetailContainer", "open");
        ClassManager.remove("wg-mainContainer", "reducedLeft");
    }

    if(routeTitle === " ") {
        setRouteTitle("Anagrafica");
    }

    history.listen(() => {
        classManager();
        setRouteTitle(history.location.pathname.replace(/[#_/]/g, " "));


        switch(history.location.pathname) {
            case "/anagrafica":
                setDescription("Gestisci i dati dei giocatori appartenenti alla tuà società");
                break;
            case "/squadre":
                setDescription("Gestisci le squadre della tua società");
                break;
            case "/pagamenti":
                setDescription("Gestisci i pagamenti");
                break;
            case "/area_tecnica":
                setDescription("Gestisci gli eventi per la squadra");
                break;
            case "/valutazione_giocatori":
                setDescription("Gestisci le valutazioni dei tuoi giocatori");
                break;
            case "/template_scheda_valutazione":
                setDescription("Gestisci e configura il template della scheda di valutazione giocatore");
                break;
            default:
                setDescription("-");
        }
    });

    return (
        <React.Fragment>
            <Router history={history}>
                <MainMenu currentRoute={history.location.pathname} />
                <div className={"wg-mainContainer"} id={"wg-mainContainer"}>
                    <div className={"wg-mainTitle"} id={"wg-mainTitle"}>{routeTitle}</div>
                    <div className={"wg-mainDescription"} id={"wg-mainDescription"}>{description}</div>
                    <Switch>
                        {Routes()}
                    </Switch>
                </div>
            </Router>
            <ToastContainer autoClose={5000} hideProgressBar={true} newestOnTop={true} draggable={false} closeOnClick={false} toastClassName={"wg-toast"} />
        </React.Fragment>
    );
}

export default AppWrapper;
