// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies


// Manage Dependencies

function Loader(props) {

    return (
        <div className={"wg-loader"}>
            <div className={"wg-loaderLogo wg-wobble"}>
                {/*<GGLogo />*/}
                <span />
                <span />
                <span />
            </div>
            <span style={{fontSize: '.9rem', marginTop: -15}}>{props.value ? props.value : "Caricamento..."}</span>
        </div>
    );
}

export default Loader;