// Importing React Dependencies
import React from 'react';
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Dropdown from "react-dropdown";

// Importing WG Dependencies


// Manage Dependencies

const EventPlayerList = (props) => {

    const [initialized, setInitialized] = React.useState(false);
    const [playerData, setPlayerData] = React.useState(props.data.playerData ? props.data.playerData : {
        player_id: props.data.playerData.player_id ? props.data.playerData.player_id : null,
        number: 0,
        missing: 0,
        first_string: 0,
        minutes: 0,
        goals: 0,
        injured: 0,
        red_card: 0,
        yellow_card: 0,
        grade: null,
        absence_reason: null,
        lateness_reason: null
    });
    const trainingMot = ["Scegli", "Motivi familiari", "Scuola", "Lavoro", "Non convocato", "Vacanza", "Malattia", "Motivi religiosi", "Ingiustificato", "Infortunato"];
    const matchMot = ["Scegli", "Motivi familiari", "Scuola", "Lavoro", "Non convocato", "Destinato ad altra squadra", "Vacanza", "Malattia", "Motivi religiosi", "Ingiustificato", "Infortunato"];

    const handleChange = event => {
        if(!event) {
            return;
        }

        let {name, value} = event.currentTarget;

        const newPlayerData = playerData;

        if(value !== "") {

            if(value.includes(",")) {
                value = value.replace(",", ".");
            }

            newPlayerData[name] = value;
        }else{
            newPlayerData[name] = null;
        }

        setPlayerData(newPlayerData);
        props.updatePlayerListData(newPlayerData);
    }

    const onDropdownChange = data => {
        if(!data) {
            return;
        }

        const newPlayerData = playerData;
        newPlayerData[data.name] = data.data.value;

        setPlayerData(newPlayerData);
        props.updatePlayerListData(newPlayerData);
    }

    const handleClick = event => {
        if(!event) {
            return;
        }

        const {name} = event.currentTarget;

        const newPlayerData = playerData;
        newPlayerData[name] = event.currentTarget.checked ? 1 : 0;


        setPlayerData(newPlayerData);
        props.updatePlayerListData(newPlayerData);
    }

    React.useEffect(() => {
        if(!initialized) {
            props.initializePlayerListData(playerData);
            setInitialized(true);
        }
        // eslint-disable-next-line
    }, [initialized]);

    if(props.eventType === "Partita") {

        return (
            <tr>
                <td>
                    {props.data.info ?
                        props.data.info.name + " " + props.data.info.surname
                    :
                        "Nome - Cognome"
                    }
                </td>
                <td>
                    <Input id={"number"+props.index} name={"number"} type={"number"} size={"standard"} placeholder={"0"}
                           defaultValue={playerData.number} onChange={(event) => handleChange(event)} />
                </td>
                <td>
                    <Checkbox id={"assente"+props.index} name={"missing"} size={"standard"} defaultChecked={playerData.missing} onClick={(event) => handleClick(event)}  />
                </td>
                <td>
                    <Checkbox id={"titolare"+props.index} name={"first_string"} size={"standard"} defaultChecked={playerData.first_string} onClick={(event) => handleClick(event)} />
                </td>
                <td>
                    <Input id={"minuti_giocati"+props.index} name={"minutes"} type={"number"} size={"standard"} placeholder={"0"}
                           defaultValue={playerData.minutes}  onChange={(event) => handleChange(event)} />
                </td>
                <td>
                    <Input id={"goal"+props.index} name={"goals"} type={"number"} size={"standard"} placeholder={"0"}
                           defaultValue={playerData.goals} onChange={(event) => handleChange(event)} />
                </td>
                <td>
                    <Checkbox id={"infortunato"+props.index} name={"injured"} size={"standard"} defaultChecked={playerData.injured} onClick={(event) => handleClick(event)} />
                </td>
                <td>
                    <Checkbox id={"espulso"+props.index} name={"red_card"} size={"standard"} defaultChecked={playerData.red_card} onClick={(event) => handleClick(event)} />
                </td>
                <td>
                    <Checkbox id={"ammonito"+props.index} name={"yellow_card"} size={"standard"} defaultChecked={playerData.yellow_card} onClick={(event) => handleClick(event)} />
                </td>
                <td>
                    <Input id={"voto"+props.index} name={"grade"} type={"number"} step={".01"} size={"standard"} placeholder={"6"}
                           defaultValue={playerData.grade} onChange={(event) => handleChange(event)} />
                </td>
                <td>
                    <Dropdown id={"causa_assenza"+props.index} options={matchMot} placeholder={"Scegli"}
                              onChange={event => onDropdownChange({data: event, name: "absence_reason" })} value={playerData.absence_reason} />
                </td>
                <td>
                    <Dropdown id={"causa_ritardo"+props.index} options={matchMot} placeholder={"Scegli"}
                              onChange={event => onDropdownChange({data: event, name: "lateness_reason" })} value={playerData.lateness_reason} />
                </td>
            </tr>
        );
    }else{
        return (
            <tr>
                <td>
                    {props.data.info ?
                        props.data.info.name + " " + props.data.info.surname
                        :
                        "Nome - Cognome"
                    }
                </td>
                <td>
                    <Checkbox id={"assente"+props.index} name={"missing"} size={"standard"} defaultChecked={playerData.missing} onClick={(event) => handleClick(event)}  />
                </td>
                <td>
                    <Checkbox id={"infortunato"+props.index} name={"injured"} size={"standard"} defaultChecked={playerData.injured} onClick={(event) => handleClick(event)} />
                </td>
                <td>
                    <Dropdown id={"causa_assenza"+props.index} options={trainingMot} placeholder={"Scegli"}
                              onChange={event => onDropdownChange({data: event, name: "absence_reason" })} value={playerData.absence_reason} />
                </td>
                <td>
                    <Dropdown id={"causa_ritardo"+props.index}options={trainingMot} placeholder={"Scegli"}
                              onChange={event => onDropdownChange({data: event, name: "lateness_reason" })} value={playerData.lateness_reason} />
                </td>
            </tr>
        );
    }
}

export default EventPlayerList;
