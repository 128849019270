class SortClass {


    constructor() {
        this.sortedData = [];
    }

    compare(a, b) {
        // transform string to lowercase
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        let compareValue = 0;

        if(a > b) {
            compareValue = 1;
        }

        if(a < b) {
            compareValue = -1;
        }

        return compareValue;
    }

    sortData(data, key, direction) {
        return this.sortedData = data.sort(function(a, b) {
            const compareValues = a[key]; const y = b[key];
            let compareResult;
            if(direction === "asc") {
                compareResult = ((compareValues < y) ? -1 : ((compareValues > y) ? 1 : 0));
            }

            if(direction === "desc") {
                compareResult = ((compareValues < y) ? -1 : ((compareValues > y) ? 1 : 0));
                compareResult = compareResult * -1;
            }

            return compareResult;
        });
    }
}

export default SortClass;