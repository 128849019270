// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import {toast} from "react-toastify";

// Manage Dependencies

function ListItem(itemProps) {

    if(itemProps && itemProps.data && itemProps.columns) {
        if(itemProps.columns.length > 0) {
            // eslint-disable-next-line
            return itemProps.columns.map(column => {
                if(column.unify) {
                    let isUnifyActive = true;
                    if(column.unify.visible !== false) {
                        let unifiedColsValue = "";
                        // eslint-disable-next-line
                        return column.unify.keys.map(colToUnifyKey => {
                            if(isUnifyActive) {
                                unifiedColsValue = unifiedColsValue + " " + itemProps.data[colToUnifyKey];
                            }
                            if(colToUnifyKey ===  column.unify.keys[column.unify.keys.length - 1]) {
                                return <td key={"unifiedCol-" + colToUnifyKey + itemProps.data.player_id}>{unifiedColsValue}</td>;
                            }
                        });
                    }
                }
                if(column.dynamic) {

                    if(column.dynamic) {
                        if(column.dynamic.key === "totals") {
                            if(column.dynamic.group && column.dynamic.group.length > 1) {
                                return <td key={"col-" + column.dynamic.key + itemProps.data.player_id + itemProps.data.team_fee_id}
                                           className={itemProps.data["subscription_fee_amount_paid"] === itemProps.data["subscription_fee_amount"] && itemProps.data["subscription_fee_amount"] > 0 ? "positive" : ""}
                                >
                                    {(itemProps.data["subscription_fee_amount_paid"] ? itemProps.data["subscription_fee_amount_paid"] : "0") + " / " + (itemProps.data["subscription_fee_amount"] ? itemProps.data["subscription_fee_amount"] : "0")}
                                </td>;
                            }else{
                                toast.error("Can't group for one columns (ListItem)")
                            }
                        }
                    }

                }
                if(!column.aggregate && !column.unify && !column.dynamic) {
                    if(column.visible !== false) {
                        if(itemProps.data[column.key]) {
                            return <td key={"col-" + column.key + itemProps.data.player_id}>{itemProps.data[column.key]}</td>;
                        }else{
                            return <td key={"col-" + column.key + itemProps.data.player_id}>-</td>;
                        }
                    }
                }
            });
        }
    }else{
        return '';
    }

}

export default ListItem;
