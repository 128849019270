// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies
import ListWrapper from "../../components/common/List/ListWrapper";
import storage from "../../components/storage/storage";
import Auth from "../../components/services/Auth/Auth";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// Manage Dependencies

function PaymentList (resources) {

    // const paymentsList = resources.paymentsListResource.read();
    const [paymentsList, setPaymentsList] = React.useState([]);

    React.useEffect(() => {

        new DirectFetcher().fetch("/subscription-fee/payments", "GET").then(json => {
            if(json) {
                setPaymentsList(json);
            }
        });

    }, []);

    const [feeChartData, setFeeChartData] = React.useState({
        labels: ["Fee payed"],
        datasets: [
            {
                label: "Payed",
                data: [5.123],
                backgroundColor: "#d5f3c3",
                maxBarThickness: 16,
                borderRadius: {
                    topLeft: 5,
                    bottomLeft: 5,
                },
                borderSkipped: false
            },
            {
                label: "Amount",
                data: [12.312],
                backgroundColor: "#58A71A",
                maxBarThickness: 16,
                borderRadius: {
                    topRight: 5,
                    bottomRight: 5
                },
                borderSkipped: false
            }
        ]
    });

    const [paymentTypeChartData, setPaymentTypeChartData] = React.useState({
        labels: ["Payment Type"],
        datasets: [
            {
                label: "Transfer",
                data: [5],
                backgroundColor: "#ff9900",
                maxBarThickness: 16,
                borderRadius: {
                    topLeft: 5,
                    bottomLeft: 5
                },
                borderSkipped: false
            },
            {
                label: "Cash",
                data: [40],
                backgroundColor: "#ffff00",
                maxBarThickness: 16,
                borderRadius: 5,
                borderSkipped: false
            },
            {
                label: "POS",
                data: [12],
                backgroundColor: "#ff3549",
                maxBarThickness: 16,
                borderRadius: 5,
                borderSkipped: false
            },
            {
                label: "Satispay",
                data: [3],
                backgroundColor: "#dd3265",
                maxBarThickness: 16,
                borderRadius: {
                    topRight: 5,
                    bottomRight: 5
                },
                borderSkipped: false
            }
        ]
    });

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            legend: false,
            tooltip: {
                enabled: false
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: false
            },
            y: {
                stacked: true,
                display: false
            },
        }
    }


    return(
        <React.Fragment>
            {/*<div className={"wg-chartContainer"}>*/}
            {/*    <Bar options={options} data={feeChartData} />*/}
            {/*</div>*/}
            {
                paymentsList && paymentsList.length > 0 ?
                    <ListWrapper items={paymentsList}
                                 columns={[
                                     {key: "player_id", value: "Player ID", visible: false},
                                     {unify: {
                                             keys: ["surname", "name"], value: "Nome"
                                         }},
                                     {key: "birth_date", value: "Data di nascita"},
                                     {key: "team_names", value: "Squadra/e"},
                                     {dynamic: {
                                             key: "totals", group: ["subscription_fee_amount", "subscription_fee_amount_paid"], description: "Quota d'iscrizione"
                                         }
                                     }
                                 ]}
                                 auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                                 keysToFilter={["name", "surname", "team_names"]}
                                 avaiableActions={{
                                     rowActions: ["click"],
                                     dialogActions: ["click"]
                                 }}
                                 argument={"player_payments"}
                    />
                    :
                        "Nessun giocatore trovato"

            }
        </React.Fragment>
    )
}

export default function Payments() {

    const [teamId, setTeamId] = React.useState(null);

    return (
        <React.Fragment>
            {/*<Suspense fallback={<Loader />}>
                <PaymentList
                    setTeamId={setTeamId}
                    paymentsListResource={
                    createResource(
                        "/teams/payments",
                        "GET",
                        null,
                        teamId
                    )
                }
                />
            </Suspense>*/}
            <PaymentList setTeamId={setTeamId} />
        </React.Fragment>
    )
}
