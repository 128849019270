// Importing React Dependencies
import React from 'react';
import Input from "../../components/common/Input/Input";
import Checkbox from "../../components/common/Checkbox/Checkbox";
import EventPlayerList from "../../components/common/List/EventPlayerList";
import Dropdown from "react-dropdown";
import Button from "../../components/common/Button/Button";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import Dialog from "../../components/common/Dialog/Dialog";
import ReactDOM from "react-dom";
import Charts from "../../components/common/Charts/Charts";

// Importing WG Dependencies
import {Calendar} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import FilterArea from "../../components/common/FilterArea/FilterArea"; // theme css file

// Manage Dependencies


// Get giocatori del team




const Events = (props) => {

    const data = props.location ? props.location : null;
    let minDate = data.props && data.props.date && data.props.date.date ? data.props.date.date.split("-")[0].replace(" ", "") : new Date();
    let maxDate = data.props && data.props.date && data.props.date.date ? data.props.date.date.substring(data.props.date.date.indexOf("-") + 2) : new Date();

    if(!data.props) {
        window.location.href = "/area_tecnica"
    }

    const minDataSplitted = minDate.split("/");
    const maxDataSplitted = maxDate.split("/");

    minDate = minDataSplitted[1] + "/" + minDataSplitted[0] + "/" + minDataSplitted[2];
    maxDate = maxDataSplitted[1] + "/" + maxDataSplitted[0] + "/" + maxDataSplitted[2];

    const [eventDataByID, setEventDataByID] = React.useState(null);

    let newEventPlayerList = [];
    if(!eventDataByID) {
        if(data.props && data.props.eventData) {
            const eventData = data.props.eventData;

            if(eventData.event_type === "match") {
                new DirectFetcher().fetch("/matches/"+data.props.eventId, "GET", null, data.props.team_id).then(json => {
                    setEventDataByID(json);
                    setEventData(json);
                    setEventDataHandler(new Date().getTime());
                    setEventType("Partita");
                    if(document.getElementsByClassName("wg-mainTitle") && document.getElementsByClassName("wg-mainTitle").length > 0) {
                        let homeTeam = localStorage.getItem('clubName') ? localStorage.getItem('clubName') : 'Nome Squadra';
                        if(Number(json.is_home_match)) {
                            document.getElementsByClassName("wg-mainTitle")[0].textContent = homeTeam + " vs " + json.opposite_team + " - " + json.date;
                        }else{
                            document.getElementsByClassName("wg-mainTitle")[0].textContent = json.opposite_team + " vs " + homeTeam + " - " + json.date;
                        }
                    }
                });
            }

            if(eventData.event_type === "training") {
                new DirectFetcher().fetch("/trainings/"+data.props.eventId, "GET", null, data.props.team_id).then(json => {
                    setEventDataByID(json);
                    setEventData(json);
                    setEventDataHandler(new Date().getTime());
                    setEventType("Allenamento");
                    if(document.getElementsByClassName("wg-mainTitle") && document.getElementsByClassName("wg-mainTitle").length > 0) {
                        document.getElementsByClassName("wg-mainTitle")[0].textContent = "Allenamento - " + json.date;
                    }
                });
            }
        }else{
            const players = data.props.playerList;

            if(players.length > 0) {
                players.map(player => {
                    newEventPlayerList.push({
                        player_id: player.player_id,
                        missing: 0,
                        first_string: 0,
                        minutes: 0,
                        goals: 0,
                        injured: 0,
                        red_card: 0,
                        yellow_card: 0,
                        grade: null,
                        absence_reason: null,
                        lateness_reason: null
                    })
                })
            }
        }
    }


    const [saveButtonStateHandler, setSaveButtonStateHandler] = React.useState(0);
    const [saveButtonState, setSaveButtonState] = React.useState("disabled");
    const [sendConvocationButtonState, setSendConvocationButtonState] = React.useState("disabled");
    const [sendConvocationHandler, setSendConvocationHandler] = React.useState(0);
    const [dialogVisible, setDialogVisible] = React.useState(true);
    const [deleteEventHandler, setDeleteEventHandler] = React.useState(false);
    const [eventType, setEventType] = React.useState(null);
    const [playerDataHandler, setPlayerDataHandler] = React.useState(0);
    const [selectedPlayerData, setSelectedPlayerData] = React.useState({});
    const [eventDataHandler, setEventDataHandler] = React.useState(0);
    const [eventData, setEventData] = React.useState(eventDataByID ? eventDataByID : {
        date: null,
        week: props.location.props.week,
        type: "Campionato",
        opposite_team: null,
        is_home_match: 1,
        home_team_score: 0,
        away_team_score: 0,
        comment: null,
        recommendations: null,
        convocation_place: null,
        convocation_hour: null,
        meeting_place: null,
        meeting_hour: null,
        players: eventDataByID ? eventDataByID.players : newEventPlayerList
    });

    const [saveDataHandler, setSaveDataHandler] = React.useState(0);
    const [exportLineUpDataHandler, setExportLineUpDataHandler] = React.useState(0);
    const [saveEventBeforeExportHandler, setSaveEventBeforeExportHandler] = React.useState(0);

    const [playerList, setPlayerList] = React.useState(eventData.players);
    const [playerListEventData, setPlayerListEventData] = React.useState([]);

    const handleChange = event => {
        if(!event) {
            return;
        }

        let {name, value} = event.currentTarget;

        if(name) {
            const newEventData = eventData;

            if(value !== "") {
                newEventData[name] = value;
            }else{

                newEventData[name] = null;
            }

            setEventData(newEventData);
            setEventDataHandler(new Date().getTime());
            setSaveButtonStateHandler(new Date().getTime());
        }
    }

    const toggleCalendar = () => {
        document.getElementById("wg-calendar").classList.toggle("visible");
    }

    const selectDate = date => {
        if(!date) {
            return;
        }

        const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let month = date.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        const year = date.getFullYear();

        const newDate = `${day}/${month}/${year}`;
        const newEventData = eventData;
        newEventData["date"] = newDate;
        setEventData(newEventData);
        setEventDataHandler(new Date().getTime());
        setSaveButtonStateHandler(new Date().getTime());

        document.getElementById("date").value = newDate;
        document.getElementById("wg-calendar").classList.toggle("visible");
    }

    const handleDropdownChange = data => {

        if(!data) {
            return;
        }

        const newEventData = eventData;
        newEventData[data.name] = data.data.value;

        setEventData(newEventData);
        setEventDataHandler(new Date().getTime());
        setSaveButtonStateHandler(new Date().getTime());
    }

    const checkHandler = event => {
        if(!event) {
            return;
        }

        const {name} = event.currentTarget;

        if(name) {
            const newEventData = eventData;
            newEventData[name] = event.currentTarget.checked ? 1 : 0;
            setEventData(newEventData);
            setEventDataHandler(new Date().getTime());
        }
    }

    const saveEventData = () => {
        setSaveDataHandler(new Date().getTime());
    }

    const exportLineUp = () => {
        setExportLineUpDataHandler(new Date().getTime());
    }

    const deleteEvent = () => {
        setDeleteEventHandler(new Date().getTime());
    }

    const initializePlayerList = playerData => {
        if(!playerData) {
            return;
        }

        setPlayerListEventData(playerListEventData => [...playerListEventData, playerData]);
    }

    const updatePlayerList = playerData => {
        if(!playerData) {
            return;
        }

        setSelectedPlayerData(playerData);
        setPlayerDataHandler(new Date().getTime());
    }

    const handleEventType = data => {
        if(!data) {
            return;
        }

        let newEventType = eventType;
        newEventType = data.value;

        setEventType(newEventType);
    }

    const exportToFile = (url, type = null) => {

        url = url + `${type}`;

        if(type) {
            if(type === "pdf" || type === "excel") {

                new DirectFetcher().fetch(url, "GET", null, data.props.team_id, null, true, "file");
            }else{
                toast.error("Invalid file format");
            }
        }
    }

    React.useEffect(() => {
        if(data.props.eventId) {
             setSendConvocationButtonState("enabled");
        }

        if(eventType === "Partita") {
            if(eventData) {
                if(eventData.type && eventData.date && eventData.opposite_team) {
                    setSaveButtonState("enabled");
                }else{
                    setSaveButtonState("disabled");
                }
            }
        }

        if(eventType === "Allenamento") {
            if(eventData) {
                if(eventData.date) {
                    setSaveButtonState("enabled");
                }else{
                    setSaveButtonState("disabled");
                }
            }
        }

    }, [eventData]);

    React.useEffect(() => {
        if(eventData) {
            if(eventType === "Partita") {
                if(eventData) {
                    if(eventData.type && eventData.date && eventData.opposite_team) {
                        setSaveButtonState("enabled");
                    }else{
                        setSaveButtonState("disabled");
                    }
                }
            }

            if(eventType === "Allenamento") {
                if(eventData) {
                    if(eventData.date) {
                        setSaveButtonState("enabled");
                    }else{
                        setSaveButtonState("disabled");
                    }
                }
            }
        }

    }, [saveButtonStateHandler]);

    React.useEffect(() => {
        if(saveDataHandler > 0) {
            const newEventData = eventData;
            newEventData.players = playerListEventData;

            if(data.props.eventId) {
                if(eventType === "Partita") {
                    new DirectFetcher().fetch("/matches/update/"+data.props.eventId, "PUT", newEventData, props.location.props.team_id).then(res => {
                        if(res && res.message) {
                            if(res.message === "ok") {
                                if(saveEventBeforeExportHandler) {
                                    exportLineUp();
                                    setSaveEventBeforeExportHandler(false);
                                }else{
                                    toast.info("Partita aggiornata correttamente");
                                }
                            }
                        }
                    });
                }

                if(eventType === "Allenamento") {
                    new DirectFetcher().fetch("/trainings/update/"+data.props.eventId, "PUT", newEventData, props.location.props.team_id).then(res => {
                        if(res && res.message) {
                            if(res.message === "ok") {
                                if(saveEventBeforeExportHandler) {
                                    exportLineUp();
                                    setSaveEventBeforeExportHandler(false);
                                }else{
                                    toast.info("Allenamento aggiornato correttamente");
                                }
                            }
                        }
                    });
                }
            }else{
                if(eventType === "Partita") {
                    new DirectFetcher().fetch("/matches/add", "POST", newEventData, props.location.props.team_id).then(res => {
                        if(res) {
                            if(!res.message) {
                                toast.info("Partita aggiunta correttamente");
                                if(res.match_id) {
                                    data.props.eventId = res.match_id;
                                    setSendConvocationButtonState("enabled");
                                }
                            }
                        }
                    });
                }

                if(eventType === "Allenamento") {
                    new DirectFetcher().fetch("/trainings/add", "POST", newEventData, props.location.props.team_id).then(res => {
                        if(res) {
                            if(!res.message) {
                                toast.info("Allenamento aggiunto correttamente");
                                if(res.training_id) {
                                    data.props.eventId = res.training_id;
                                }
                            }
                        }
                    });
                }
            }

        }
    }, [saveDataHandler])

    React.useEffect(() => {
        if(saveEventBeforeExportHandler) {
            if(data.props.eventId) {
                saveEventData();
            }else{
                exportLineUp();
            }
        }
    }, [saveEventBeforeExportHandler])

    React.useEffect(() => {
        if(saveEventBeforeExportHandler) {
            if(exportLineUpDataHandler > 0 && data.props.eventId) {
                new DirectFetcher().fetch("/matches/export/"+props.location.props.eventId+"/pdf", "GET", null, props.location.props.team_id, null, true, "file").then(() => {
                    toast.info("Distinta esportata");
                });
            }else{
                toast.warning("Prima di esportare la distinta è necessario salvare l'evento");
                setSaveEventBeforeExportHandler(false);
            }
        }
    }, [exportLineUpDataHandler])

    React.useEffect(() => {
        if(eventData.players && eventData.players.length > 0) {
            setPlayerList(eventData.players);
        }
    }, [eventDataHandler])

    React.useEffect(() => {
        if(deleteEventHandler > 0) {
            if(eventType) {
                if(eventType === "Allenamento") {
                    new DirectFetcher().fetch("/trainings/delete/"+props.location.props.eventId, "DELETE", null, props.location.props.team_id, null).then(() => {
                        window.location.href = "/area_tecnica";
                    });
                }
                if(eventType === "Partita") {
                    new DirectFetcher().fetch("/matches/delete/"+props.location.props.eventId, "DELETE", null, props.location.props.team_id, null).then(() => {
                        window.location.href = "/area_tecnica";
                    });
                }
            }
        }
    }, [deleteEventHandler])

    React.useEffect(() => {

        if(selectedPlayerData) {

            const newPlayerList = playerListEventData;

            if(newPlayerList.length > 0) {
                newPlayerList.map((player, index) => {
                    if(player.player_id === selectedPlayerData.player_id) {
                        newPlayerList[index] = selectedPlayerData;
                    }
                })
                setPlayerListEventData(newPlayerList);
            }
        }
    }, [selectedPlayerData]);

    React.useEffect(() => {
        if(sendConvocationHandler > 0) {
            if(eventData.convocation_place && eventData.convocation_hour) {
                new DirectFetcher().fetch("/matches/convocations/sendmail/"+data.props.eventId, "GET", null, props.location.props.team_id).then(res => {
                    if(res) {
                        if(res.message && res.message == "ok") {
                            toast.info("Cnvocazione inviata");
                        }else{
                            toast.error("Errore nell'invio della convocazione");
                        }
                    }else{
                        toast.error("Errore nell'invio della convocazione");
                    }
                })
            }
        }
    }, [sendConvocationHandler]);

    if(!props.location.props.eventId && dialogVisible) {
        return(
            <Dialog visible={dialogVisible} position={{centered: true}}
                    size={{width: 30, unit: "%"}} title={"Scegli tipologia evento"}
                    primarybtn={{
                        enabled: true,
                        value: "Salva",
                        action: () => setDialogVisible(false)
                    }}
                    secondarybtn={{
                        enabled: true,
                        value: "Annulla",
                        action: () => window.location.href = "/area_tecnica"
                    }}
            >
                <Dropdown id={"eventType"} options={["Allenamento", "Partita"]} placeholder={"Scegli tipologia evento"}
                          onChange={event => handleEventType(event)}  value={eventType}/>
            </Dialog>
        )
    }else{

        return (
            <React.Fragment>
                {eventType === "Allenamento" && eventData.training_id ?
                    <React.Fragment>

                        <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Esporta in PDF"} onClick={() => exportToFile("/trainings/export/"+eventData.training_id+"/", "pdf")} />
                        <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Esporta in Excel"} onClick={() => exportToFile("/trainings/export/"+eventData.training_id+"/", "excel")} />

                    </React.Fragment>
                :

                null
                }
                <div className={"wg-mainEventSection"}>
                    <div id={"wg-deleteEventPopup"} />
                    <div className={"wg-eventDetailSection"}>
                        <div className={"wg-title"}>Dettagli evento</div>
                        <div className={"wg-eventDetailForm"}>
                            {eventType === "Partita" ?
                                <React.Fragment>
                                    <div className={"wg-eventDetailFormSection"}>
                                        <div>
                                            <label className={"wg-inputLabel margin"}>
                                                Tipologia
                                                <Dropdown id={"tipologia"} options={["Campionato", "Amichevole", "Torneo"]} placeholder={"Scegli"} value={eventData.type}
                                                          onChange={event => handleDropdownChange({
                                                              data: event,
                                                              name: "type"
                                                          })} />
                                            </label>
                                            <Input id={"date"} name={"date"} type={"text"} size={"standard"} placeholder={"Data evento"} label={"Data"} readonly={"readonly"}
                                                   defaultValue={eventData.date} onChange={(event) => handleChange(event)} onClick={() => toggleCalendar()  } />
                                            <div className={"wg-calendar"} id={"wg-calendar"}>
                                                <Calendar minDate={new Date(minDate)}
                                                          maxDate={new Date(maxDate)}
                                                          onChange={event => selectDate(event)}
                                                          showPreviewRange={false}
                                                />
                                            </div>
                                            <Input id={"squadra_avversaria"} name={"opposite_team"} type={"text"} size={"standard"} placeholder={"Nome avversario"} label={"Squadra avversaria"}
                                                   defaultValue={eventData.opposite_team} onChange={(event) => handleChange(event)} />
                                            <Input id={"goal_casa"} name={"home_team_score"} type={"number"} size={"standard"} placeholder={"Goal squadra di casa"} label={"Goal in casa"}
                                                   defaultValue={eventData.home_team_score} onChange={(event) => handleChange(event)} />

                                            <Input id={"goal_fuori_casa"} name={"away_team_score"} type={"number"} size={"standard"} placeholder={"Goal squadra fuori casa"} label={"Goal fuori casa"}
                                                   defaultValue={eventData.away_team_score} onChange={(event) => handleChange(event)} />

                                            <Checkbox id={"is_home_team"} name={"is_home_match"} size={"standard"} label={"In casa"} defaultChecked={eventData.is_home_match}
                                                      onClick={event => checkHandler(event) } />
                                            <div style={{marginBottom: 12}} />
                                        </div>
                                        <div className={"wg-eventDetailFormSection wg-eventDetailFormRec"}>
                                            <Input id={"comment"} name={"comment"} type={"textarea"} size={"standard"} placeholder={"Relazione sulla partita"} label={"Relazione sulla partita"}
                                                   defaultValue={eventData.comment} onChange={(event) => handleChange(event)} />
                                            <Input id={"comment"} name={"recommendations"} type={"textarea"} size={"standard"} placeholder={"Giocatori segnalati"} label={"Giocatori segnalati"}
                                                   defaultValue={eventData.recommendations} onChange={(event) => handleChange(event)} />
                                        </div>
                                    </div>
                                    <div className={"wg-eventDetailSection chart"}>
                                        <div className={"wg-title"}>Convocazione</div>
                                        <div className={"wg-chartContainer"}>
                                            <div className={"wg-chartCOntainerDiv"}>
                                                <Input id={"convocation_place"} name={"convocation_place"} type={"text"} size={"standard"} placeholder={"Luogo convocazione"} label={"Luogo convocazione"}
                                                       defaultValue={eventData.convocation_place} onChange={(event) => handleChange(event)} />
                                                <Input id={"convocation_hour"} name={"convocation_hour"} type={"text"} size={"standard"} placeholder={"Orario convocazione"} label={"Orario convocazione"}
                                                       defaultValue={eventData.convocation_hour} onChange={(event) => handleChange(event)} />
                                            </div>
                                            <div className={"wg-chartCOntainerDiv"}>
                                                <Input id={"MeetingPlace"} name={"meeting_place"} type={"text"} size={"standard"} placeholder={"Luogo ritrovo"} label={"Luogo ritrovo"}
                                                       defaultValue={eventData.meeting_place} onChange={(event) => handleChange(event)} />

                                                <Input id={"meeting_hour"} name={"meeting_hour"} type={"text"} size={"standard"} placeholder={"Orario ritrovo"} label={"Orario ritrovo"}
                                                       defaultValue={eventData.meeting_hour} onChange={(event) => handleChange(event)} />
                                            </div>
                                            <div>
                                                <Button vtype={"solid"} size={"standard"} value={"Invia convcazione"}
                                                        state={sendConvocationButtonState}
                                                        onClick={() => setSendConvocationHandler(new Date().getTime())}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                : eventType === "Allenamento" ?
                                    <div className={"wg-eventDetailFormSection allenamento"}>
                                        <div>
                                            <label className={"wg-inputLabel margin"}>
                                                Tipologia
                                                <Dropdown id={"tipologia"} options={["Campionato", "Allenamento"]} placeholder={"Scegli"} value={"Allenamento"}
                                                          onChange={event => handleDropdownChange({
                                                              data: event,
                                                              name: "type"
                                                          })} />
                                            </label>

                                            <Input id={"date"} name={"date"} type={"text"} size={"standard"} placeholder={"Data evento"} label={"Data"}
                                                   defaultValue={eventData.date} onChange={(event) => handleChange(event)} onClick={() => toggleCalendar()} readonly={"readonly"} />
                                            <div className={"wg-calendar"} id={"wg-calendar"}>
                                                <Calendar minDate={new Date(minDate)}
                                                          maxDate={new Date(maxDate)}
                                                          onChange={event => selectDate(event)}
                                                          showPreviewRange={false}
                                                />
                                            </div>
                                        </div>
                                        <div className={"wg-eventDetailFormSection trainingComment"}>
                                            <Input id={"comment"} name={"comment"} type={"textarea"} size={"standard"} placeholder={"Descrizione della seduta"} label={"Descrizione della seduta"}
                                                   defaultValue={eventData.comment} onChange={(event) => handleChange(event)} />
                                        </div>
                                        <div style={{marginBottom: 12}} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>

                <div className={"wg-mainEventSection"}>
                    <div className={"wg-eventDetailSection players"}>
                        <div className={"wg-title"}>Dettaglio giocatori</div>

                        {eventType === "Partita" ?

                            <React.Fragment>
                                <div style={{display: "block", marginTop: 16}} />
                                <Button vtype={"solid"} size={"standard"} value={"Esporta distinta"}
                                        state={"enabled"}
                                        onClick={() => setSaveEventBeforeExportHandler(true)}
                                />
                            </React.Fragment>
                        :
                            null
                        }

                        <div className={"wg-playerList"}>
                            {playerList && playerList.length > 0 ?
                                <table>
                                    <thead>
                                    {
                                        eventType === "Partita" ?
                                            <tr>
                                                <th>Nome</th>
                                                <th>N. maglia</th>
                                                <th>Non convocato</th>
                                                <th>Titolare</th>
                                                <th>Minuti giocati</th>
                                                <th>Goal</th>
                                                <th>Infortunato</th>
                                                <th>Espulso</th>
                                                <th>Ammonito</th>
                                                <th>Voto</th>
                                                <th>Causa assenza</th>
                                                <th>Causa ritardo</th>
                                            </tr>
                                            :
                                            <tr>
                                                <th>Nome</th>
                                                <th>Assente</th>
                                                <th>Infortunato</th>
                                                <th>Causa assenza</th>
                                                <th>Causa ritardo</th>
                                            </tr>
                                    }
                                    </thead>

                                    <tbody>
                                    {
                                        playerList.map((player, index) => {
                                            let playerData = {};

                                            if(newEventPlayerList && newEventPlayerList.length > 0) {
                                                playerData = newEventPlayerList[index]
                                            }else{
                                                playerData = player;
                                            }

                                            return <EventPlayerList data={{playerData, info: data.props.playerList[index] }} index={index} key={index}
                                                                    updatePlayerListData={updatePlayerList}
                                                                    initializePlayerListData={initializePlayerList}
                                                                    eventType={eventType}
                                            />
                                        })
                                    }
                                    </tbody>
                                </table>
                                :
                                "Nessun giocatore trovato"
                            }
                        </div>
                        <div className={"wg-CTAction"}>
                            <div className={"left"}>
                                <div>
                                    {data.props.eventId ?
                                        <Button vtype={"solid"} size={"standard"} value={"Elimina"}
                                                state={"red"}
                                                onClick={() => ReactDOM.render(
                                                    <Dialog visible={true} position={{centered: true}}
                                                            size={{width: 30, unit: "%"}} title={"Sei sicuro di voler eliminare l'evento"}
                                                            primarybtn={{
                                                                enabled: true,
                                                                value: "Elimina",
                                                                action: deleteEvent
                                                            }}
                                                            secondarybtn={{
                                                                enabled: true,
                                                                value: "Annulla",
                                                                action: () => ReactDOM.unmountComponentAtNode(document.getElementById("wg-deleteEventPopup"))
                                                            }}
                                                    />, document.getElementById("wg-deleteEventPopup")
                                                )}
                                        />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className={"right"}>
                                <Button vtype={"outline"} size={"standard"} value={"Annulla"}
                                        state={"enabled"}
                                        onClick={() => window.location.href = "/area_tecnica"} />
                                <Button vtype={"solid"} size={"standard"} value={"Salva"}
                                        state={saveButtonState}
                                        onClick={saveEventData} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Events;
