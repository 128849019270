/* eslint-disable */

// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies

import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify';
import NoResultFound from "../../components/common/Filter/NoResultFound";
import RatingsTemplate from "../../components/common/RatingsTemplate/RatingsTemplate";

// Manage Dependencies


export default function PlayerRatingsTemplate() {


    const [initialized, setInitialized] = React.useState(0);
    const [evaluationsTemplate, setEvaluationsTemplate] = React.useState([]);
    const [editedCategories, setEditedCategories] = React.useState([]);

    React.useEffect(() => {
        if(initialized > 0) {
            new DirectFetcher().fetch("/players/evaluations/items", "GET" ).then(json => {
                if(json) {
                    if(json.length > 0) {
                        setEvaluationsTemplate(evaluationsTemplate => [...evaluationsTemplate, json] );
                    }
                }else{
                    toast.error("Nessuna voce di valutazione trovata");
                }
            });
        }else{
            setInitialized(new Date().getTime());
        }
    }, [initialized])

    return (
        <React.Fragment>
            <div style={{marginTop: 48}} />

            {evaluationsTemplate && evaluationsTemplate.length > 0 ? <RatingsTemplate categories={evaluationsTemplate[0]} setEditedCategories={setEditedCategories} /> : <NoResultFound /> }

        </React.Fragment>
    )
}
