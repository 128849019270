// Importing React Dependencies
import React, {useState, useEffect} from 'react';

// Importing WG Dependencies

// Manage Dependencies

const Switch = (props) => {

    const handleClick = event => {
        event.stopPropagation();
    }

    return(
        <React.Fragment>
            <label className="wg-switch" onClick={() => handleClick}>
                <input className={"wg-inputSwitch"} defaultChecked={props.checked ? props.checked : false} type="checkbox" onClick={event => handleClick(event)} />
                <span className="wg-slider round" />
            </label>
        </React.Fragment>
    );

};

export default Switch;
