// Importing React Dependencies
import React, {useState} from 'react';
import Input from "../../components/common/Input/Input";
import Auth from "../../components/services/Auth/Auth";
import storage from "../../components/storage/storage";
import ListWrapper from "../../components/common/List/ListWrapper";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import Dialog from "../../components/common/Dialog/Dialog";
import Dropdown from "react-dropdown";
import Button from "../../components/common/Button/Button";
import {useHistory} from "react-router-dom";

// Importing WG Dependencies


// Manage Dependencies

const PlayerPayments = (props) => {


    const history = useHistory();

    const [data, setData] = React.useState(props.location.state ? props.location.state : []);
    const [feeData, setFeeData] = React.useState({});
    const [oldFeeData, setOldFeeData] = React.useState({});
    const [newFeeData, setNewFeeData] = React.useState({
        payment_id: null,
        amount: null,
        date: null,
        description: null,
        type: "Bonifico"
    });

    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [saveEnabled, setSaveEnabled] = React.useState(false);
    const [saveAllFeesEnabled, setaveAllFeesEnabled] = React.useState("enabled");
    const [saveHandler, setSaveHandler] = React.useState(false);
    const [savePlayerFeeHandler, setSavePlayerFeeHandler] = React.useState(false);
    const [deleteFeeHandler, setDeleteFeeHandler] = React.useState(false);

    const editFeeAmount = input => {
        if(!input) {
            return;
        }
        data.subscription_fee_amount = input.currentTarget.value;
        feeData.subscription_fee_amount = input.currentTarget.value;
    }

    const editNewFee = (input, required = false) => {
        if(!input) {
            return;
        }

        if(input.paymentType) {
            newFeeData.type = input.paymentType.value;
        }else{
            const {id, value} = input.currentTarget;
            setNewFeeData(newFeeData => ({
                ...newFeeData,
                [id]: value
            }));

            if(required) {
                if(id === "date") {
                    if(input.currentTarget.value.length === 10) {
                        input.currentTarget.classList.remove("invalidField");
                    }else{
                        input.currentTarget.classList.add("invalidField");
                    }
                }else{
                    if(input.currentTarget.value.length > 0) {
                        input.currentTarget.classList.remove("invalidField");
                    }else{
                        input.currentTarget.classList.add("invalidField");
                    }
                }
            }

            const allInput = document.getElementById("wg-newFeeInput").querySelectorAll("input");
            let validatedPayment = false;
            let validatedDate = false;

            allInput.forEach((input, index) => {
                if(index === 0) {
                    if(input.value.length >= 1) {
                        validatedPayment = true;
                    }else{
                        validatedPayment = false;
                    }
                }
                if(index === 1) {
                    if(input.value.length === 10) {
                        validatedDate = true;
                    }else{
                        validatedDate = false;
                    }
                }
            })

            if(validatedPayment && validatedDate) {
                setSaveEnabled("enabled");
            }else{
                setSaveEnabled(false);
            }

        }

    }

    const addNewFee = () => {
        setSaveHandler(new Date().getTime());
    }

    const addFee = fee => {

        setDialogVisible(true);

        if(Object.keys(fee).length > 0) {
            setNewFeeData(fee);
            setOldFeeData(fee);
        }else{
            setOldFeeData({});
        }
    }

    const savePlayerFees = () => {
        setSavePlayerFeeHandler(new Date().getTime());
    }

    React.useEffect(() => {
        if(data && data.name && data.surname) {
            document.getElementById("wg-mainTitle").innerText = `Pagamenti ${data.name} ${data.surname}`;
        }

        document.getElementById("wg-mainDescription").innerText = "Gestisci le rate di pagamento";

    }, [])

    React.useEffect(() => {
        if(savePlayerFeeHandler > 0) {
            if(data.player_id) {
                feeData.subscription_fee_amount = data.subscription_fee_amount;
                new DirectFetcher().fetch("/subscription-fee/payments/players/"+data.player_id, "PUT", feeData).then(json => {
                    if(json) {
                        toast.info("Pagamenti salvati");
                    }
                    if(feeData.payments) {
                        new DirectFetcher().fetch("/subscription-fee/payments/players/"+data.player_id, "GET").then(json => {
                            if(json) {
                                setFeeData(json);
                                history.push({
                                    pathname: "/pagamenti_giocatore",
                                    state: data
                                })
                            }
                        })
                    }
                });
            }else{
                toast.error("Si è verificato un errore...");
            }
        }
    }, [savePlayerFeeHandler])

    React.useEffect(() => {
        if(saveHandler > 0) {
            if(Object.keys(oldFeeData).length <= 0) {
                feeData.payments.unshift(newFeeData);
            }else{
                if(feeData.payments && feeData.payments.length > 0) {
                    feeData.payments.map((fee, feeIndex) => {
                        if(fee === oldFeeData) {
                            feeData.payments[feeIndex] = newFeeData;
                        }
                    })
                }
            }
            setNewFeeData({
                payment_id: null,
                amount: null,
                date: null,
                description: null,
                type: "Bonifico"
            });

            if(feeData.payments && feeData.payments.length > 0) {

                let totalPaid = 0;

                feeData.payments.map(fee => {
                    if(fee) {
                        totalPaid = Number(totalPaid + Number(fee.amount));
                    }
                })
                data.subscription_fee_amount_paid = totalPaid;
            }
            setDialogVisible(false);
        }

    }, [saveHandler])

    React.useEffect(() => {
        if(feeData) {
            if(data.player_id) {
                new DirectFetcher().fetch("/subscription-fee/payments/players/"+data.player_id, "GET").then(json => {
                    if(json) {
                        setFeeData(json);
                    }
                })
            }else{
                toast.error("Nessun giocatore selezionato");
            }
        }
    }, []);

    React.useEffect(() => {
        const newFeeInputContainer = document.getElementById("wg-newFeeInput");

        if(newFeeInputContainer) {
            const allInput = document.getElementById("wg-newFeeInput").querySelectorAll("input");
            let validatedPayment = false;
            let validatedDate = false;

            allInput.forEach((input, index) => {
                if(index === 0) {
                    if(input.value.length >= 1) {
                        validatedPayment = true;
                        input.classList.remove("invalidField");
                    }else{
                        validatedPayment = false;
                    }
                }
                if(index === 1) {
                    if(input.value.length === 10) {
                        validatedDate = true;
                        input.classList.remove("invalidField");
                    }else{
                        validatedDate = false;
                    }
                }
            })

            if(validatedPayment && validatedDate) {
                setSaveEnabled("enabled");
            }else{
                setSaveEnabled(false);
            }
        }
    }, [dialogVisible])

    React.useEffect(() => {
        if(deleteFeeHandler > 0) {
            if (feeData.payments && feeData.payments.length > 0) {
                let feeToDelete;
                feeData.payments.map((fee, feeIndex) => {
                    if(fee === oldFeeData) {
                        feeToDelete = feeIndex;
                        toast.info("Eliminato pagamento da: " + fee.amount + "€");
                    }
                })
                feeData.payments.splice(feeToDelete, 1);
                setNewFeeData({
                    payment_id: null,
                    amount: null,
                    date: null,
                    description: null,
                    type: "Bonifico"
                });
                setDialogVisible(false);
            }
        }
    }, [deleteFeeHandler])

    return(
        <React.Fragment>
            <div className={"wg-playerFeeInfo"}>
                Totale pagato:
                {data.subscription_fee_amount_paid ? data.subscription_fee_amount_paid : 0}
                /
                <Input id={"player_fee"} type={"number"} defaultValue={data.subscription_fee_amount ? data.subscription_fee_amount : "0"} onChange={event => editFeeAmount(event)}/>
            </div>

            <ListWrapper items={feeData.payments}
                         showFilterArea={false}
                         ignoreLength={true}
                         columns={["payment_id", "amount", "date"]}
                         auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                         listType={"card"}
                         avaiableActions={{
                             rowActions: ["click"],
                             dialogActions: ["click"]
                         }}
                         addCard={{
                             title: "Nuova rata",
                             description: "Aggiungi una nuova rata di pagamento"
                         }}
                         argument={"addFee"}
                         handleClick={addFee}
            />

            {dialogVisible ?

                <Dialog visible={true} position={{centered: true}}
                        size={{width: 700, unit: "px"}} title={"Aggiungi rata"}
                        primarybtn={{
                            enabled: saveEnabled,
                            value: "Salva",
                            action: () => addNewFee()
                        }}
                        secondarybtn={{
                            enabled: true,
                            value: "Elimina",
                            action: () => setDeleteFeeHandler(new Date().getTime())
                        }}
                        thirdbtn={{
                            enabled: true,
                            value: "Chiudi",
                            action: () => setDialogVisible(false)
                        }}
                >
                    <div className={"wg-newFeeInput"} id={"wg-newFeeInput"}>
                        <Input id={"amount"} required={true} onChange={event => editNewFee(event, true)} defaultValue={newFeeData.amount} label={"Valore rata"} placeholder={"Es: 50"} />
                        <Input id={"date"} required={true} onChange={event => editNewFee(event, true)} defaultValue={newFeeData.date} label={"Data pagamento"} placeholder={"dd/mm/yyyy"} />

                        <label className={"wg-inputLabel"} >
                            <span style={{display: "block", marginBottom: 7}}>Tipo di pagamento</span>
                            <Dropdown options={["Bonifico", "Contanti", "Bancomat / Carta di credito", "Satispay"]} value={newFeeData.type} onChange={event => editNewFee({paymentType: event})} />
                        </label>

                        <Input id={"description"} onChange={event => editNewFee(event)} defaultValue={newFeeData.description} label={"Descrizione pagamento"} placeholder={"Opzionale"}  />
                    </div>
                </Dialog>
                :
                null
            }

            <div className={"wg-CTAction"}>
                <div className={"left"} />
                <div className={"right"}>
                    <Button id={"undo"} vtype={"simple"} size={"standard"} value={"Annulla"} onClick={() => window.location.href = "/pagamenti"} />
                    <Button id={"save"} vtype={"solid"} size={"standard"} value={"Salva"} state={saveAllFeesEnabled} onClick={savePlayerFees}  />
                </div>
            </div>
        </React.Fragment>
    )

}

export default PlayerPayments;
