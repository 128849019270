/* eslint-disable */

// Importing React Dependencies
import React from 'react';
import { useHistory } from 'react-router-dom';

// Importing WG Dependencies
import MatTab from "../../components/common/Tab/MatTab";
import Input from "../../components/common/Input/Input";
import {Calendar} from 'react-date-range';

// Manage Dependencies
import DefaultAvatar from '../../assets/img/avatar.jpg';
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import Button from "../../components/common/Button/Button";
import {toast} from "react-toastify";
import Dialog from "../../components/common/Dialog/Dialog";
import Loader from "../../components/common/Loader/Loader";

const Player = (props) => {

    const UploadedAvatar = false;

    const [playerPhoto, setPlayerPhoto] = React.useState(null);
    const [playerData, setPlayerData] = React.useState(
        props && props.location && props.location.state ? props.location.state : {
            player_id: null,
            name: null,
            surname: null,
            birth_date: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            certificate_expiration: null,
            email: null,
            phone_number: null,
            nationality: null,
            fiscal_code: null,
            birth_place: null,
            father_name: null,
            father_phone_number: null,
            mother_name: null,
            mother_phone_number: null,
        });
    const [updatePageInfoHandler, setUpdatePageInfoHandler] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState({});
    const [saveDataHandler, setSaveDataHandler] = React.useState(0);
    const [deletePlayerHandler, setDeletePlayerHandler] = React.useState(0);
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [saveState, setSaveState] = React.useState("disabled");
    const [visibleDeleteButton, setVisibleDeleteButton] = React.useState(false);
    const [visibleDeleteButtonHandler, setVisibleDeleteButtonHandler] = React.useState(false);

    const history = useHistory();

    const handleChange = (input, required = false, calendarId = null) => {
        if(!input) {
            return;
        }

        const {id, value} = input.currentTarget ? input.currentTarget : calendarId ? {id: calendarId, value: input} : null;

        if(id === "name" || id === "surname" || id === "father_name" || id === "mother_name") {
            setPlayerData(playerData => ({
                ...playerData,
                [id]: value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            }));
        }else if(calendarId) {

            if(!value) {
                return;
            }

            const day = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
            let month = value.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            const year = value.getFullYear();

            const newDate = `${day}/${month}/${year}`;

            setPlayerData(playerData => ({
                ...playerData,
                [id]: newDate
            }));

            document.getElementById(calendarId).value = newDate;

            if(calendarId === "birth_date") {
                document.getElementById("wg-calendarBirth_date").classList.remove("visible");
            }

            if(calendarId === "certificate_expiration") {
                document.getElementById("wg-calendarCertificate").classList.remove("visible");
            }

        }else{
            setPlayerData(playerData => ({
                ...playerData,
                [id]: value
            }));
        }

        if(required) {
            if(value) {
                document.getElementById(input.currentTarget.id).classList.remove("invalidField");
            }else{
                document.getElementById(input.currentTarget.id).classList.add("invalidField");
            }
        }

        checkInput();
        setUpdatePageInfoHandler(new Date().getTime());

    }

    const saveData = () => {
        setSaveDataHandler(new Date().getTime());
    }

    const undo = () => {
        window.location.href = "/"
    }

    const deletePlayerDialog = () => {
        setDialogVisible(true);
    }

    const deletePlayer = () => {
        setDeletePlayerHandler(new Date().getTime());
    }

    const checkInput = () => {
        const requiredField = ["name", "surname", "birth_date"];
        let invalidField = [];

        requiredField.map(field => {
            if(document.getElementById(field).value) {
                document.getElementById(field).classList.remove("invalidField");
            }else{
                invalidField.push(field);
            }
        })

        if(invalidField.length > 0) {
            setSaveState("disabled");
            invalidField = [];
        }else{
            setSaveState("");
        }
    }

    const toggleCalendar = (calendarId) => {

        if(calendarId) {
            if(calendarId === "wg-calendarCertificate") {
                document.getElementById("wg-calendarCertificate").classList.toggle("visible");
                document.getElementById("wg-calendarBirth_date").classList.remove("visible");
            }else{
                document.getElementById("wg-calendarCertificate").classList.remove("visible");
                document.getElementById("wg-calendarBirth_date").classList.toggle("visible");
            }
        }


    }

    React.useEffect(() => {
        if(updatePageInfoHandler > 0) {

            const name = playerData.name ? playerData.name : "";
            const surname = playerData.surname ? playerData.surname : "";
            const birth_date = playerData.birth_date ? playerData.birth_date + " - " : "";
            const address = playerData.address? playerData.address + " - "  : "";
            const city = playerData.city ? playerData.city : "";
            const province = playerData.province ? `(${playerData.province}) -` : "";
            const zip = playerData.postal_code ? playerData.postal_code : "";


            const title = (!playerData.name && !playerData.surname ? "Nuovo giocatore" : `${name} ${surname}`);
            const description = `${birth_date} ${address} ${city} ${province} ${zip}`

            const checkDescription = description.replace(/\s/g, '');

            document.getElementById("wg-mainTitle").innerText = title;
            document.getElementById("wg-mainDescription").innerText = checkDescription ? description : "Inserisci i dati del giocatore";
        }
    }, [updatePageInfoHandler])

    React.useEffect(() => {
        checkInput();
        setUpdatePageInfoHandler(new Date().getTime());
    }, [])

    React.useEffect(() => {
        if(saveDataHandler > 0) {

            const formData = new FormData();

            if(playerPhoto) {
                formData.append("photo", playerPhoto);
            }

            if(playerData.player_id) {
                const playerDataNew = playerData;
                delete playerDataNew["team_names"];
                delete playerDataNew["photo"];

                formData.append("player", JSON.stringify(playerDataNew));

                new DirectFetcher().fetchFormData("/players/update/"+playerData.player_id, "POST", formData).then(json => {
                    if(json) {
                        if(json.photo) {

                            const playerDataToHistory = formData.get("player");
                            playerDataNew.photo = json.photo;

                            history.push({
                                state: playerDataToHistory
                            })
                            toast.info(`Dati aggiornati`);
                        }
                    }
                });
            }else{

                formData.append("player", JSON.stringify(playerData));

                new DirectFetcher().fetchFormData("/players/add", "POST", formData).then(json => {
                    if(json && json.player_id) {
                        const playerDataNew = playerData;
                        playerDataNew.player_id = json.player_id;
                        setPlayerData(playerDataNew);
                        toast.info(`Il giocatore ${playerData.name} ${playerData.surname} è stato creato`);
                        history.push({
                            state: playerDataNew
                        })
                    }
                    setVisibleDeleteButtonHandler(new Date().getTime());
                });
            }
        }
    }, [saveDataHandler]);

    React.useEffect(() => {
        if(playerData.player_id) {
            setVisibleDeleteButton(true);
        }
    }, [deletePlayerHandler]);

    React.useEffect(() => {
        if(deletePlayerHandler > 0) {

            document.getElementById("wg-loaderContainer").classList.add("visible");
            new DirectFetcher().fetch("/players/delete/"+playerData.player_id, "DELETE").then(json => {
                if(json && json.message) {
                    if(json.message === "ok") {
                        window.location.href = "/";
                    }else{
                        toast.info(json.message);
                    }
                }
            });
        }
    }, [deletePlayerHandler]);

    const openUploader = () => {

        const photoUploader = document.getElementById("wg-playerPhotoUploader");

        if(photoUploader) {
            photoUploader.click();
        }

    }

    const editPhoto = (event) => {

        if(event) {
            if(event && event.target) {

                let srcEncoded;
                const item = event.target.files[0];
                const itemTest = window.URL.createObjectURL(item);
                const resize_width = 120;

                const reader = new FileReader();
                reader.readAsDataURL(item);
                reader.name = item.name;
                reader.size = item.size;
                reader.onload = function(e) {
                    const img = new Image();

                    img.src = itemTest;
                    img.name = item.name;
                    img.size = item.size;
                    img.onload = function(el) {

                        const elem = document.createElement('canvas');
                        const scaleFactor = resize_width / el.target.width;
                        elem.width = resize_width;
                        elem.height = el.target.height * scaleFactor;

                        const ctx = elem.getContext('2d');
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                         srcEncoded = ctx.canvas.toDataURL(el.target, 'image/jpeg', 0);

                        document.querySelector("#playerPhoto").src = srcEncoded;

                        const dataURLToBlob = function(dataURL) {
                            const BASE64_MARKER = ';base64,';
                            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                                const parts = dataURL.split(',');
                                const contentType = parts[0].split(':')[1];
                                const raw = parts[1];

                                return new Blob([raw], {type: contentType});
                            }

                            const parts = dataURL.split(BASE64_MARKER);
                            const contentType = parts[0].split(':')[1];
                            const raw = window.atob(parts[1]);
                            const rawLength = raw.length;

                            const uInt8Array = new Uint8Array(rawLength);

                            for (let i = 0; i < rawLength; ++i) {
                                uInt8Array[i] = raw.charCodeAt(i);
                            }

                            return new Blob([uInt8Array], {type: contentType});
                        }

                        srcEncoded = dataURLToBlob(srcEncoded);
                        setPlayerPhoto(srcEncoded);
                    }
                }
            }
        }
    }

    return(
        <React.Fragment>

            <div id={"wg-loaderContainer"} className={"wg-loaderContainer"}>
                <Loader value={playerData["name"] + " " + playerData["surname"] + " in cancellazione..."} />
            </div>
            <Dialog visible={dialogVisible} position={{centered: true}}
                    size={{width: 30, unit: "%"}} title={"Sei sicuro di voler eliminare " + playerData.name + " " + playerData.surname + "?"}
                    primarybtn={{
                        enabled: true,
                        value: "Conferma",
                        action: deletePlayer
                    }}
                    secondarybtn={{
                        enabled: true,
                        value: "Annulla",
                        action: () => setDialogVisible(false)
                    }}
            />

            <div style={{marginTop: 48}} />
            <MatTab items={["Dati personali"]} setActiveTab={setActiveTab} />
            <div className={"wg-playerDataContainer"}>
                <div className={"wg-playerDataSection"}>
                    <span className={"wg-playerDataSectionTitle space_bottom"}><i className={"wi-camera-solid"} aria-hidden={"true"} /> Seleziona Foto</span>
                    <div className={"wg-playerPhoto"} onClick={() => openUploader()}>
                        <div className={"wg-playerPhotoSpace"}><img src={playerData.photo ? playerData.photo : DefaultAvatar} id={"playerPhoto"} alt={"Player Avatar"} /></div>
                        <span className={"wg-playerPhotoEdit"}><i className={"wi-pencil-alt-solid"} /></span>
                        <input type={"file"} id={"wg-playerPhotoUploader"} accept={".jpg, .jpeg, .png"} hidden={"true"} onChange={(event) => editPhoto(event)} />
                    </div>
                    <span className={"wg-playerDataSectionTitle space_top space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} /> Nome e cognome</span>
                    <Input id={"name"} type={"text"} size={"standard"} placeholder={"Nome"}
                           defaultValue={playerData["name"]}
                           maxLength={64}
                           required={true}
                           onChange={(event) => handleChange(event, true)} />
                    <Input id={"surname"} type={"text"} size={"standard"} placeholder={"Cognome"}
                           required={true}
                           maxLength={64}
                           defaultValue={playerData["surname"]} onChange={(event) => handleChange(event, true)} />
                    <span className={"wg-playerDataSectionTitle space_top space_bottom"}><i className={"wi-birthday-cake-solid"} aria-hidden={"true"} /> Data e città di nascita, Nazionalità </span>
                    <div className={"wg-playerDataInline birth_nat"}>

                        <Input id={"birth_date"} name={"birth_date"} type={"text"} size={"standard"} placeholder={"Data di nascita"} readonly={"readonly"}
                               defaultValue={playerData["birth_date"]} onChange={(event) => handleChange(event, true)} onClick={() => toggleCalendar("wg-calendarBirth_date")  } />
                        <div className={"wg-calendar"} id={"wg-calendarBirth_date"}>
                            <Calendar onChange={event => handleChange(event, false, "birth_date")}
                                      showPreviewRange={false}
                            />
                        </div>
                        <Input id={"birth_place"} type={"text"} size={"standard"} placeholder={"Negrar"}
                               maxLength={32}
                               defaultValue={playerData["birth_place"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"nationality"} type={"text"} size={"standard"} placeholder={"Italiana"}
                               maxLength={32}
                               defaultValue={playerData["nationality"]} onChange={(event) => handleChange(event, false)} />
                    </div>
                </div>
                <div className={"wg-playerDataSection"}>
                    <span className={"wg-playerDataSectionTitle space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />Codice fiscale</span>
                    <Input id={"fiscal_code"} type={"text"} size={"standard"} placeholder={"AAAAAA12A12A123A"}
                           maxLength={16}
                           defaultValue={playerData["fiscal_code"]} onChange={(event) => handleChange(event, false)} />
                    <span className={"wg-playerDataSectionTitle space_top space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />Residenza</span>
                    <Input id={"address"} type={"text"} size={"standard"} placeholder={"Via Mario Rossi, 8/D"}
                           maxLength={128}
                           defaultValue={playerData["address"]} onChange={(event) => handleChange(event, false)} />
                    <div className={"wg-playerDataInline birth_nat"}>
                        <Input id={"city"} type={"text"} size={"standard"} placeholder={"Bussolengo"}
                               maxLength={64}
                               defaultValue={playerData["city"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"province"} type={"text"} size={"standard"} placeholder={"VR"}
                               maxLength={2}
                               defaultValue={playerData["province"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"postal_code"} type={"text"} size={"standard"} placeholder={"12345"}
                               maxLength={5}
                               defaultValue={playerData["postal_code"]} onChange={(event) => handleChange(event, false)} />
                    </div>
                    <span className={"wg-playerDataSectionTitle space_top space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />Scadenza certificato medico</span>
                    <Input id={"certificate_expiration"} type={"text"} size={"standard"} placeholder={"dd/mm/aaaa"}
                           defaultValue={playerData["certificate_expiration"]} onChange={(event) => handleChange(event, false)} onClick={() => toggleCalendar("wg-calendarCertificate")  } />
                    <div className={"wg-calendar"} id={"wg-calendarCertificate"}>
                        <Calendar onChange={event => handleChange(event, false, "certificate_expiration")}
                                  showPreviewRange={false}
                        />
                    </div>

                    <span className={"wg-playerDataSectionTitle space_top space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />E-mail e N. di telefono</span>
                    <div className={"wg-playerDataInline others_info"}>
                        <Input id={"email"} type={"text"} size={"standard"} placeholder={"esempio@email.com"}
                               maxLength={64}
                               defaultValue={playerData["email"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"phone_number"} type={"text"} size={"standard"} placeholder={"340 123 4567"}
                               maxLength={10}
                               defaultValue={playerData["phone_number"]} onChange={(event) => handleChange(event, false)} />
                    </div>
                </div>
                <div className={"wg-playerDataSection noStyle half"}>
                    <div className={"wg-section"}>
                        <span className={"wg-playerDataSectionTitle space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />Papà - Nome e N.di telefono</span>
                        <Input id={"father_name"} type={"text"} size={"standard"} placeholder={"Nome e Cognome"}
                               maxLength={128}
                               defaultValue={playerData["father_name"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"father_phone_number"} type={"text"} size={"standard"} placeholder={"340 123 4567"}
                               maxLength={10}
                               defaultValue={playerData["father_phone_number"]} onChange={(event) => handleChange(event, false)} />
                    </div>
                    <div className={"wg-section"}>
                        <span className={"wg-playerDataSectionTitle space_bottom"}><i className={"wi-id-card-regular"} aria-hidden={"true"} />Mamma - Nome e N.di telefono</span>
                        <Input id={"mother_name"} type={"text"} size={"standard"} placeholder={"Nome e Cognome"}
                               maxLength={128}
                               defaultValue={playerData["mother_name"]} onChange={(event) => handleChange(event, false)} />
                        <Input id={"mother_phone_number"} type={"text"} size={"standard"} placeholder={"340 123 4567"}
                               maxLength={10}
                               defaultValue={playerData["mother_phone_number"]} onChange={(event) => handleChange(event, false)} />
                    </div>
                </div>
            </div>
            <div className={"wg-CTAction"}>
                <div className={"left"}>
                    {visibleDeleteButton ?
                        <Button id={"delete"} vtype={"solid"} customclasses={"delete"} size={"standard"} value={"Elimina"} onClick={() => deletePlayerDialog()} />
                    :
                        null
                    }
                </div>
                <div className={"right"}>
                    <Button id={"undo"} vtype={"simple"} size={"standard"} value={"Annulla"} onClick={() => undo()} />
                    <Button id={"save"} vtype={"solid"} size={"standard"} value={"Salva"} state={saveState} onClick={() => saveData()} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Player;
