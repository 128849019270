/* eslint-disable */

// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies
import Button from "../../components/common/Button/Button";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import {toast} from "react-toastify";
import Loader from "../../components/common/Loader/Loader";
import createResource from "../../components/services/Fetcher/Fetcher";
import FilterArea from "../../components/common/FilterArea/FilterArea";
import ListWrapper from "../../components/common/List/ListWrapper";
import Auth from "../../components/services/Auth/Auth";
import storage from "../../components/storage/storage";

// Manage Dependencies


function TeamList (resources) {

    const teamList = resources.teamListRecource ? resources.teamListRecource.read() : null;
    const playerList = resources.playerList ? resources.playerList.read() : null;
    const [selectedTeam, setSelectedTeam] = React.useState(null);
    const role = localStorage.getItem("role")

    const onChangeTeam = team => {
        if(!team) {
            return;
        }

        setSelectedTeam(team);

    }

    const extraOptions = (evt) => {

        if(!evt) {
            return;
        }

        let target = evt.currentTarget;
        let activeExtraOptionsMenu = document.getElementsByClassName("wg-extraOptions")[0];

        if(!target.parentElement.classList.contains("visible")) {
            setTimeout(() => {
                target.parentElement.classList.add("visible");
            }, 50);
        }else{
            target.parentElement.classList.remove("visible");
        }

        document.addEventListener("click", evt => {

            const clickTarget = evt.target;

            if(activeExtraOptionsMenu.classList.contains("visible")) {
                if(clickTarget !== activeExtraOptionsMenu) {
                    activeExtraOptionsMenu.classList.remove("visible");
                }
            }
        });

    }

    React.useEffect(() => {
        if(selectedTeam) {
            if(teamList && teamList.length > 0) {
                teamList.map(team => {
                    if(team.name === selectedTeam) {
                        resources.setTeamId(team.team_id);
                    }
                })
            }
        }
    }, [selectedTeam]);

    if(teamList && teamList.length > 0) {
        if(!resources.teamId) {
            resources.setTeamId(teamList[0].team_id);
        }
    }

    if(selectedTeam) {
        return (
            <React.Fragment>

                <div style={{marginTop: 24}} />

                {role === "admin" ? <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Esporta in Excel"} onClick={() => exportToFile("/players/evaluations/export/", "excel")} /> : null}
                {role === "admin" ? <Button vtype={"solid"} state={"enabled"} size={"standard"} value={"Configura Template"} onClick={() => window.location.href = "template_scheda_valutazione"} /> : null}

                <FilterArea argument={"player_ratings"} onChangeTeam={onChangeTeam} defaultSelection={true} showSearch={false} showSort={false} extraOptions={event => extraOptions(event)} />
                {playerList && playerList.length > 0 ?
                   <ListWrapper items={playerList}
                                showFilterArea={false}
                                columns={[
                                    {key: "player_id", value: "Player ID", visible: false},
                                    {unify: {
                                            keys: ["surname", "name"], value: "Nome"
                                        }},
                                    {key: "birth_date", value: "Data di nascita"},
                                    {key: "email", value: "E-mail"},
                                ]}
                                 auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                                 listType={"grid"}
                                 avaiableActions={{
                                     rowActions: ["click"]
                                 }}
                                 addCard={false}
                                 argument={"player_ratings"}
                                 //handleClick={fee => changeFee(fee)}
                    />
                    :
                    null
                }
            </React.Fragment>
        );
    }else{
        return <FilterArea argument={"technical_area"} onChangeTeam={onChangeTeam} defaultSelection={true} showSearch={false} showSort={false} />
    }
}

const exportToFile = (url, type = null) => {

    url = url + `${type}`;

    if(type) {
        if(type === "pdf" || type === "excel") {

            new DirectFetcher().fetch(url, "GET", null, null, null, true, "file");
        }else{
            toast.error("Invalid file format");
        }
    }
}

export default function PlayersRatingsList() {

    const [teamId, setTeamId] = React.useState(null);

    return (
        <React.Fragment>
            <Suspense fallback={<Loader />}>
                <TeamList teamListRecource={
                    createResource(
                        "/teams",
                        "GET",
                        null,
                        null,
                        null
                    )
                }
                          playerList={
                              teamId ?
                                  createResource(
                                      "/teams/players",
                                      "POST",
                                      ["player_id", "name", "surname", "birth_date", "first_role"],
                                      teamId,
                                      null
                                  )
                                  :
                                  null
                          }
                          setTeamId={setTeamId}
                          teamId={teamId}
                />
            </Suspense>
        </React.Fragment>
    )
}