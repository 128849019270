// Importing React Dependencies
import React from 'react';
import Chip from "../../Chip/Chip";

// Importing WG Dependencies


// Manage Dependencies
let firstLoading = true;

export default function AddItemVariant(props) {

    const [itemList, setItemList] = React.useState(props.itemList.items);
    const [tempItemList, setTempItemList] = React.useState([]);

    // Chip State
    const [canAddItem, setCanAddItem] = React.useState(true);
    const [deleteItemHandler, setDeleteItemHandler] = React.useState(0);
    const [chipId, setChipId] = React.useState(null);
    const [currentChipId, setCurrentChipId] = React.useState(null);
    // eslint-disable-next-line
    const [removedChip, setRemovedChip] = React.useState(false);


    const setFocusOnChip = () => {
        if(!canAddItem) {
            // set focus on last Chip input
            if(tempItemList) {
                document.getElementById("wg-chipValue" + tempItemList[tempItemList.length - 1].chip.props.index).focus();
            }
        }
    };

    const removeItem = (currentChipId, chipId) => {
        if(!String(chipId)) {
            return;
        }


        setCurrentChipId(currentChipId);
        setChipId(chipId);
        setDeleteItemHandler(new Date().getTime());
    };

    const addItem = () => {
        const index = new Date().getTime();
        setTempItemList(tempItemList => [...tempItemList,
            {
                chip_id: index,
                chip: <Chip key={new Date().getTime()}
                            json={props.avaiableItemList}
                            addItemToList={saveItems}
                            updateChipId={setCurrentChipId}
                            index={index}
                            deleteItemFromList={removeItem}
                            canAdd={setCanAddItem}
                />
            }
        ]);
        setCanAddItem(false);
    };

    const saveItems = (items) => {
        setItemList(itemList => [...itemList, items]);
    };

    React.useEffect(() => {
        setRemovedChip(false);
    }, [currentChipId]);

    React.useEffect(() => {
        if(!firstLoading) {
            setTempItemList([]);
            firstLoading = true;
            setCanAddItem(true);
        }
    }, [props.eraseList]);

    React.useEffect(() => {
        if(canAddItem) {
            if(firstLoading) {
                addItem();
            }
            if(tempItemList && tempItemList.length > 0) {
                if(document.getElementById("wg-chipPlayerId_" + tempItemList[tempItemList.length - 1].chip.props.index).classList.contains("wg-chipDefined")) {
                    addItem();
                }else{
                    setCanAddItem(false);
                    setFocusOnChip();
                }
            }
        }
        // eslint-disable-next-line
    }, [canAddItem]);

    React.useEffect(() => {
        if(firstLoading) {
            firstLoading = false;
        }else{
            props.itemList.update(itemList);
            if(!canAddItem) {
                setCanAddItem(true);
            }
        }
        // eslint-disable-next-line
    }, [itemList]);

    React.useEffect(() => {
        if(currentChipId) {
            const newItemList = [...itemList].filter(item => Number(item.player_id) !== Number(currentChipId));
            const newTempItemList = [...tempItemList].filter(tempItem => Number(tempItem.chip_id) !== chipId);

            setRemovedChip(true);
            setItemList(newItemList);
            setTempItemList(newTempItemList);
        }
        // eslint-disable-next-line
    }, [deleteItemHandler]);

    return (
        <div className={"wg-addItemContainer"}>
            <div className={"wg-chipContainer"} id={"wg-chipContainer"}
                 onClick={() => setFocusOnChip()}>
                {(tempItemList && tempItemList.length > 0) ?
                    tempItemList.map(item => {
                        return item.chip;
                    })
                    :
                        null
                }
            </div>
        </div>
    );
}