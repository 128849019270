/* eslint-disable */

// Importing React Dependencies
import React, {Suspense} from 'react';


// Importing WG Dependencies
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import DefaultAvatar from "../../assets/img/avata_default.png";
import Field from "../../components/common/Fields/Field";
import Button from "../../components/common/Button/Button";
import NoResultFound from "../../components/common/Filter/NoResultFound";
import Input from "../../components/common/Input/Input";
import {toast} from 'react-toastify';
import Tab from "../../components/common/Tab/Tab";
import MatTab from "../../components/common/Tab/MatTab";

// Manage Dependencies

function PlayersRatingsList(props) {

    const [playerData, setPlayerData] = React.useState(props.location && props.location.state ? props.location.state : []);
    const [playerEvaluations, setPlayerEvaluations] = React.useState([]);

    const [evaluationsGroupHandler, setEvaluationsGroupHandler] = React.useState(0);
    const [evaluationsGroupInitialized, setEvaluationsGroupInitialized] = React.useState(false);
    const [overallOnInitHandler, setOverallOnInitHandler] = React.useState(0);

    const [evaluationsGroup, setEvaluationsGroup] = React.useState({
        notes: {
            description: "Dati scheda valutazione",
            fields: []
        },
        player_info: {
            description: "Dati anagrafici",
            fields: []
        },
        technics: {
            description: "Abilità tecniche",
            fields: []
        },
        tactics: {
            description: "Abilità tattiche",
            fields: []
        },
        athletics: {
            description: "Abilità atletiche",
            fields: []
        },
        behaviors: {
            description: "Caratteristiche comportamentali",
            fields: []
        },
        goalkeeper: {
            description: "Abilità portiere",
            fields: []
        }
    });

    const [evaluationsTestPlaceholderState, setEvaluationsTestPlaceholderState] = React.useState([]);
    const [avgPoints, setAvgPoints] = React.useState({
        technics: 0,
        athletics: 0,
        behaviors: 0,
        tactics: 0
    });

    const [selectedField, setSelectedField] = React.useState(null);
    const [selectedFieldVaue, setSelectedFieldValue] = React.useState(null);

    const evaluationsTestPlaceholder = [];

    const [saveDataHandler, setSaveDataHandler] = React.useState(false);
    const [saveButtonState, setSaveButtonState] = React.useState("disabled");

    const [activeTab, setActiveTab] = React.useState(0);
    const [tabHandler, setTabHandler] = React.useState(null);

    const role = localStorage.getItem("role");

    const saveData = () => {
        setSaveDataHandler(new Date().getTime());
    }

    React.useEffect(() => {
        if(tabHandler > 0) {
            if(activeTab) {

                const playerStatsTab = document.getElementById("player_stats");
                const playerTestTab = document.getElementById("player_test");

                if(activeTab.tabIndex === 0) {
                    if(!playerStatsTab.classList.contains("visible")) {
                        playerStatsTab.classList.add("visible")
                    }
                    playerTestTab.classList.remove("visible");
                }

                if(activeTab.tabIndex === 1) {
                    if(!playerTestTab.classList.contains("visible")) {
                        playerTestTab.classList.add("visible")
                    }
                    playerStatsTab.classList.remove("visible");
                }
            }
        }
    }, [tabHandler])

    React.useEffect(() => {
        new DirectFetcher().fetch("/players/evaluations/"+playerData.player_id, "GET").then(json => {
            if(json) {
                if(json.evaluations) {
                    setPlayerEvaluations(json.evaluations);
                    setEvaluationsGroupHandler(new Date().getTime());

                    if(json.evaluations.length > 0) {

                        json.evaluations.map(evaluation => {
                            if(evaluation.category === "test") {
                                evaluationsTestPlaceholder.push(evaluation);
                            }
                        })

                        if(evaluationsTestPlaceholder.length > 0) {
                            const testSections = groupArrayOfObjects(evaluationsTestPlaceholder, "group_name");
                            setEvaluationsTestPlaceholderState(testSections);
                            calculateOverallOnInit();
                        }
                    }
                }
            }
        })
    }, []);

    React.useEffect(() => {
        if(evaluationsGroupHandler > 0) {
            if(playerEvaluations.length > 0) {

                const newEvaluationGroup = evaluationsGroup;

                playerEvaluations.map(field => {
                    switch (field.category) {
                        case "player_info":
                            newEvaluationGroup.player_info.fields.push(field);
                            break;
                        case "technics":
                            newEvaluationGroup.technics.fields.push(field);
                            break;
                        case "tactics":
                            newEvaluationGroup.tactics.fields.push(field);
                            break;
                        case "athletics":
                            newEvaluationGroup.athletics.fields.push(field);
                            break;
                        case "behaviors":
                            newEvaluationGroup.behaviors.fields.push(field);
                            break;
                        case "goalkeeper":
                            newEvaluationGroup.goalkeeper.fields.push(field);
                            break;
                        case "notes":
                            newEvaluationGroup.notes.fields.push(field);
                        default:
                    }
                })

                setEvaluationsGroup(newEvaluationGroup);
                setEvaluationsGroupInitialized(true);

            }
        }
    }, [evaluationsGroupHandler])

    React.useEffect(() => {
        if(saveDataHandler > 0) {

            new DirectFetcher().fetch("/players/evaluations/save/"+playerData.player_id, "POST", playerEvaluations).then(json => {
                if(json && json.message === "ok") {
                    toast.info("Valutazioni aggiornate correttamente");
                }
            })
        }
    }, [saveDataHandler])

    React.useEffect(() => {

        if(selectedField) {
            const newAvgPoints = avgPoints;

            if(evaluationsGroup[selectedField.category] && evaluationsGroup[selectedField.category].fields.length > 0) {

                newAvgPoints[selectedField.category] = 0;

                evaluationsGroup[selectedField.category].fields.map(evaluation => {
                    if(evaluation.evaluation_value) {
                        newAvgPoints[selectedField.category] = newAvgPoints[selectedField.category] + Number(evaluation.evaluation_value);
                    }
                })
                newAvgPoints[selectedField.category] = (newAvgPoints[selectedField.category] / evaluationsGroup[selectedField.category].fields.length).toFixed(2);
            }

            const avgCategory = document.getElementById("wg-average_"+selectedField.category)

            if(avgCategory) {
                avgCategory.innerText = newAvgPoints[selectedField.category];
            }

            const athleticsPts = Number(document.getElementById("wg-average_athletics").innerText);
            const tacticsPts = Number(document.getElementById("wg-average_tactics").innerText);
            const technicsPts = Number(document.getElementById("wg-average_technics").innerText);
            const behaviorsPts = Number(document.getElementById("wg-average_behaviors").innerText);

            const OverallPts = ((athleticsPts + tacticsPts + technicsPts + behaviorsPts) / 4).toFixed(2);

            document.getElementById("wg-averageOverall").innerText = String(OverallPts);

        }

    }, [selectedFieldVaue])

    const handleChange = (event, field) => {
        if(field && event) {

            const newPlayerEvaluations = playerEvaluations;

            setSelectedField(field)
            setSelectedFieldValue(event)

            if(newPlayerEvaluations && newPlayerEvaluations.length > 0) {
                newPlayerEvaluations.map((evaluation, index) => {
                    if(event.currentTarget.ariaLabel !== "test_date") {
                        if(evaluation.player_evaluation_item_id === field.player_evaluation_item_id) {
                            newPlayerEvaluations[index].evaluation_value = event.currentTarget.value;
                        }
                    }else{
                        if(evaluation.player_evaluation_item_id === field.player_evaluation_item_id) {
                            newPlayerEvaluations[index].date = event.currentTarget.value;
                        }
                    }
                })
            }else{
                console.log("Elemento non trovato");
            }

            setPlayerEvaluations(newPlayerEvaluations);
            setSaveButtonState("enabled");

        }
    }

    function groupArrayOfObjects(list, key) {
        return list.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    function calculateOverallOnInit() {
        setOverallOnInitHandler(8);
    }

    React.useEffect(() => {
        if(overallOnInitHandler > 0) {
            if(evaluationsGroupInitialized) {

                const athleticsContainer = document.getElementById("wg-avgCat_athletics");
                const tacticsContainer = document.getElementById("wg-avgCat_tactics");
                const technicsContainer = document.getElementById("wg-avgCat_technics");
                const behaviorsContainer = document.getElementById("wg-avgCat_behaviors");

                let athleticsPoints = 0;
                let tacticsPoints = 0;
                let technicsPoints = 0;
                let behaviorsPoints = 0;

                const newAvgPoints = avgPoints;


                if(athleticsContainer) {

                    const athleticsPts = Array.from(athleticsContainer.querySelectorAll("input"));
                    athleticsPts.map(input => {
                        athleticsPoints = athleticsPoints + Number(input.value)
                    })

                    athleticsPoints = (athleticsPoints / athleticsPts.length).toFixed(2)
                    if(document.getElementById("wg-average_athletics")) {
                        document.getElementById("wg-average_athletics").innerText = athleticsPoints;
                    }
                }

                if(tacticsContainer) {

                    const tacticsPts = Array.from(tacticsContainer.querySelectorAll("input"));
                    tacticsPts.map(input => {
                        tacticsPoints = tacticsPoints + Number(input.value)
                    })

                    tacticsPoints = (tacticsPoints / tacticsPts.length).toFixed(2)

                    if(document.getElementById("wg-average_tactics")) {
                        document.getElementById("wg-average_tactics").innerText = tacticsPoints;
                    }
                }

                if(technicsContainer) {

                    const technicsPts = Array.from(technicsContainer.querySelectorAll("input"));
                    technicsPts.map(input => {
                        technicsPoints = technicsPoints + Number(input.value)
                    })

                    technicsPoints = (technicsPoints / technicsPts.length).toFixed(2);

                    if(document.getElementById("wg-average_technics")) {
                        document.getElementById("wg-average_technics").innerText = technicsPoints;
                    }
                }

                if(behaviorsContainer) {

                    const behaviorsPts = Array.from(behaviorsContainer.querySelectorAll("input"));
                    behaviorsPts.map(input => {
                        behaviorsPoints = behaviorsPoints + Number(input.value)
                    })

                    behaviorsPoints = (behaviorsPoints / behaviorsPts.length).toFixed(2);

                    if(document.getElementById("wg-average_behaviors")) {
                        document.getElementById("wg-average_behaviors").innerText = behaviorsPoints;
                    }
                }


                const OverallPts = ((Number(athleticsPoints) + Number(tacticsPoints) + Number(technicsPoints) + Number(behaviorsPoints)) / 4).toFixed(2);

                if(document.getElementById("wg-averageOverall")) {
                    document.getElementById("wg-averageOverall").innerText = String(OverallPts);
                }

            }
        }
    }, [overallOnInitHandler])

    const exportEvaluation = (fileType) => {
        if(fileType) {
            new DirectFetcher().fetch("/players/evaluations/export/"+playerData.player_id+"/"+fileType, "GET", null, null, null, true, "file");
        }
    }


    if(evaluationsGroupInitialized) {
        return (
            <React.Fragment>
                <div style={{padding: 16}}></div>
                <Button id={"exportEvaluations"} vtype={"solid"} size={"standard"} state={"enabled"} value={"Esporta in PDF"} onClick={() => exportEvaluation("pdf")} />
                <Button id={"exportEvaluations"} vtype={"solid"} size={"standard"} state={"enabled"} value={"Esporta in Excel"} onClick={() => exportEvaluation("excel")} />
                <div className={"wg-playerRatingsTab"}>
                    <MatTab items={["Statistiche", "Test"]} setActiveTab={setActiveTab} setTabHandler={setTabHandler} />
                </div>
                <div className={"wg-mainRaitingContainer visible"} id={"player_stats"}>
                    <div className={"wg-raitingMainColumn"}>
                        <div className={"wg-ratingAverageTitle"}>Media Valutazioni</div>
                        <div className={"wg-ratingPlayerDescription"}>{playerData.name + " " + playerData.surname} - {playerData.birth_date}</div>

                        <div className={"wg-playerPhoto"}>
                            <div className={"wg-playerPhotoSpace"}><img src={DefaultAvatar} alt={"Player Avatar"} /></div>
                        </div>

                        <div className={"wg-raitingAverageSection"}>
                            <div className={"wg-averagePointsContainer"}>
                                <div className={"wg-averagePoints"} id={"wg-average_technics"}>0</div>
                                <div className={"wg-averageDescription"}>Tecnica</div>
                            </div>
                            <div className={"wg-averagePointsContainer"}>
                                <div className={"wg-averagePoints"} id={"wg-average_tactics"}>0</div>
                                <div className={"wg-averageDescription"}>Tattica</div>
                            </div>
                            <div className={"wg-averagePointsContainer"}>
                                <div className={"wg-averagePoints"} id={"wg-average_athletics"}>0</div>
                                <div className={"wg-averageDescription"}>Atletica</div>
                            </div>
                            <div className={"wg-averagePointsContainer"}>
                                <div className={"wg-averagePoints"} id={"wg-average_behaviors"}>0</div>
                                <div className={"wg-averageDescription"}>Comportamento</div>
                            </div>
                            <div className={"wg-averagePointsContainer"}>
                                <div className={"wg-averagePoints"} id={"wg-averageOverall"}>0</div>
                                <div className={"wg-averageDescription"}>Media totale</div>
                            </div>
                        </div>

                    </div>
                    <div className={"wg-raitingMainColumn"}>

                        {Object.keys(evaluationsGroup) && Object.keys(evaluationsGroup).length > 0 ?

                            Object.keys(evaluationsGroup).map((key, index) => {
                                return(
                                    <React.Fragment>
                                        <div className={"wg-PlayerRatingTemplateSection"}>
                                            <div className={"wg-PlayerRatingTemplateSectionTitle"}>
                                                {evaluationsGroup[key].description}
                                            </div>
                                            <div className={"wg-PlayerRatingTemplateFields"} id={"wg-avgCat_"+key}>
                                                {evaluationsGroup[key].fields && evaluationsGroup[key].fields.length > 0 ?
                                                    evaluationsGroup[key].fields.map(field => {
                                                        if(field.player_evaluation_item_id === "NOTES") {
                                                            return (
                                                                <div className={"wg-inputNotes"}>
                                                                    <Input key={field.player_evaluation_item_id}
                                                                           id={field.player_evaluation_item_id}
                                                                           type={"textarea"}
                                                                           defaultValue={field.evaluation_value}
                                                                           placeholder={!field.evaluation_value ? field.description : null}
                                                                           label={field.description}
                                                                           onChange={(event) => handleChange(event, field)}
                                                                    />
                                                                </div>
                                                            )
                                                        }else{
                                                            return <Input key={field.player_evaluation_item_id}
                                                                          id={field.player_evaluation_item_id}
                                                                          defaultValue={field.evaluation_value}
                                                                          placeholder={!field.evaluation_value ? field.description : null}
                                                                          label={field.description}
                                                                          onChange={(event) => handleChange(event, field)}
                                                            />
                                                        }
                                                    })
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })

                            :
                            null
                        }

                    </div>

                </div>

                <div className={"wg-mainRaitingsTestContainer"} id={"player_test"}>
                    {Object.keys(evaluationsTestPlaceholderState).map((elem, key) => {

                        return(
                            <div className={"wg-PlayerRatingTemplateSection"}>
                                <div className={"wg-PlayerRatingTemplateSectionTitle"}>
                                    {evaluationsTestPlaceholderState[elem][0].group_description}
                                </div>
                                <div className={"wg-PlayerRatingTemplateFields"}>
                                    {evaluationsTestPlaceholderState[elem] && evaluationsTestPlaceholderState[elem].length > 0 ?
                                        evaluationsTestPlaceholderState[elem].map((field, fieldKey) => {

                                            return <React.Fragment>
                                                <Input key={field.player_evaluation_item_id + "_date"}
                                                       id={field.player_evaluation_item_id + "_date"}
                                                       defaultValue={field.date}
                                                       placeholder={!field.date ? field.description : null}
                                                       label={"Data " + field.description}
                                                       aria-label={"test_date"}
                                                       onChange={(event) => handleChange(event, field)} />
                                                <Input key={field.player_evaluation_item_id}
                                                       id={field.player_evaluation_item_id}
                                                       defaultValue={field.evaluation_value}
                                                       placeholder={!field.evaluation_value ? field.description : null}
                                                       label={field.description}
                                                       onChange={(event) => handleChange(event, field)}
                                                />
                                            </React.Fragment>
                                        })
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>

                {role === "admin" || role === "trainer" ?
                    <div className={"wg-CTAction"}>
                        <div className={"left"} />
                        <div className={"right"}>
                            <Button id={"undo"} vtype={"simple"} size={"standard"} value={"Annulla"} onClick={() => window.location.reload()} />
                            <Button id={"save"} vtype={"solid"} size={"standard"} state={saveButtonState} value={"Salva"} onClick={() => saveData()} />
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }else{
        return <NoResultFound message={"Nessun voce di valutazione trovata"} />
    }
}

export default PlayersRatingsList;