/* eslint-disable */

// Importing React Dependencies
import React from 'react';
import { useHistory } from 'react-router-dom';

// Importing WG Dependencies
import Input from "../Input/Input";
import Button from "../Button/Button";
import DirectFetcher from "../../services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify';
import Dropdown from 'react-dropdown';
import storage from '../../storage/storage';

// Manage Dependencies
import 'react-dropdown/style.css';

const FilterArea = (props) => {

    // eslint-disable-next-line
    const [buttonActiveState, setButtonActiveState] = React.useState(props.extraOptionsButtonState);
    const [initialized, setInitialized] = React.useState(0);
    const [teams, setTeams] = React.useState(["Tutti"]);
    const [allTeamsData, setAllTeamsData] = React.useState(null);
    const [selectedTeam, setSelectedTeam] = React.useState(null);
    const [selectedSortBy, setSelectedSortBy] = React.useState(null);
    const role = localStorage.getItem("role");

    const history = useHistory();

    React.useEffect(() => {
        if(initialized === 0) {
            let teamNames = [];

            if(props.argument) {
                if(props.argument === "technical_area") {
                    teamNames = [];
                }else{
                    teamNames.push("Scegli squadra");
                }
            }else{
                teamNames.push("Scegli squadra");
            }

            new DirectFetcher().fetch("/teams", "GET").then(json => {
                if(json && json.length > 0) {
                    json.map(team => {
                        teamNames.push(team.name);
                        setAllTeamsData(json);
                    })
                    setTeams(teamNames);
                    if(props.defaultSelection) {
                        if(props.onChangeTeam && teamNames[0]) {

                            if(storage.manageStorage("session", "get", "selectedTecTeam")) {
                                if(props.onChangeTeam) {
                                    const a = teamNames;
                                    if(teamNames.length > 0) {
                                        teamNames.map((teamName, index) => {
                                            if(teamName === storage.manageStorage("session", "get", "selectedTecTeam")) {
                                                props.onChangeTeam(teamNames[index]);
                                                setSelectedTeam(teamNames[index])
                                            }
                                        });
                                    }
                                }
                            }else{
                                props.onChangeTeam(teamNames[0]);
                                setSelectedTeam(teamNames[0])
                            }

                        }
                    }
                    setInitialized(new Date().getTime());
                }
            });
        }
    }, [initialized]);

    React.useEffect(() => {
        setButtonActiveState(props.extraOptionsButtonState);
    }, [props.extraOptionsButtonState]);

    const changeTeam = team => {
        if(!team) {
            return;
        }

        setSelectedTeam(team.value);
        if(props.onChangeTeam) {
            props.onChangeTeam(team.value);
            props.getSelectedTeam ? props.getSelectedTeam(team.value): null;
            storage.manageStorage("session", "set", "selectedTecTeam", team.value);
        }
    }

    const changeSortBy = value => {
        if(!value) {
            return;
        }

        setSelectedSortBy(value);

    }


    const exportToFile = (url, type = null) => {

        url = url + `${type}`;

        if(type) {
            if(type === "pdf" || type === "excel") {

                let selectedTeamId = null;

                if(allTeamsData && allTeamsData.length > 0) {
                    allTeamsData.map(team => {
                        if(team.name === selectedTeam) {
                            selectedTeamId = team.team_id;
                        }
                    })
                }

                new DirectFetcher().fetch(url, "GET", null, selectedTeamId, null, true, "file");
            }else{
                toast.error("Invalid file format");
            }
        }

        if(props && props.closeExtraOptionsMenu) {
            props.closeExtraOptionsMenu();
        }
    }

    const sendCertificateEmail = () => {

        new DirectFetcher().fetch("/players/certificate/expiring/sendmail", "GET", null, null, null).then(json => {
            if(json && json.message) {
                if(json.message === "ok") {
                    toast.info("Mail certificati inviata");
                }else{
                    toast.error("Si + verificato un errore");
                }
            }else{
                toast.error("Si + verificato un errore");
            }
        });

        if(props && props.closeExtraOptionsMenu) {
            props.closeExtraOptionsMenu();
        }
    }

    const addPlayer = () => {
        if(props.argument === "registry") {
            history.push({
                pathname: "/giocatore",
                state: null
            });
        }

        if(props.argument === "teams") {
            document.getElementById("wg-addPlayerToTeam").classList.add("visible");
        }
    }

        return(
            <React.Fragment>
                <div className={"wg-filterArea"}>
                    {props.showSearch === false ?
                        null
                        :
                        <React.Fragment>
                            <Input id={"inputPlayer"}
                                   name={"inputPlayer"}
                                   placeholder={props.placeholder ? props.placeholder : "Filtra..."}
                                   type={"text"}
                                   vtype={"solid"}
                                   size={"standard"}
                                   state={"enabled"}
                                   icon={"wi-search"}
                                   onKeyUp={props.onFilter}
                            />
                            <div style={{marginRight: 6}} />
                        </React.Fragment>
                    }

                    <React.Fragment>

                        {props.argument !== "teams" && props.argument !== "teamsList" ?
                            <Dropdown options={teams} placeholder={"Scegli Squadra"}
                                      style={{width: "auto"}} id={"teams"} onChange={event => changeTeam(event)} value={selectedTeam} />
                        :
                            null
                        }

                        {props.argument === "teamsList" ?
                            <React.Fragment>
                                <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Esporta in PDF"} onClick={() => exportToFile("/teams/export/", "pdf")} />
                                <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Esporta in Excel"} onClick={() => exportToFile("/teams/export/", "excel")} />
                            </React.Fragment>
                        :
                            null
                        }

                        <div style={{marginRight: 6}} />
                    </React.Fragment>

                    {props.showSort === false ?
                        null
                        :
                        <React.Fragment>
                            <div className={"wg-divider"} />
                            {props.argument === "technical_area" ?
                                null
                            :
                                <Button vtype={"icon square"} id={"sortPlayer"} size={"large"} icon_id={"wg-sort"} icon={"wi-sort-asc"} onClick={props.onSort} />
                            }
                            {props.argument === "player_payments" || props.argument === "registry" || props.argument === "technical_area" || props.argument === "player_ratings" ?
                                <div className={"wg-extraOptions"} >
                                    <Button vtype={"icon square wg-extraOptionsIcon"} customclasses={props.extraOptionsButtonState} size={"large"} icon_id={"wi-ellipsis-h-solid"} icon={"wi-ellipsis-h-solid"} onClick={props.extraOptions} />
                                    <div className={"wg-extraOptionsMenu"}>
                                        <ul>
                                            {props.argument === "player_payments" ?
                                                <React.Fragment>
                                                    <li onClick={() => exportToFile("/subscription-fee/payments/export/", "pdf")} className={props.argument ? props.argument === "player_payments" ? "" : "hidden" : "hidden"}>Esporta pagamenti in PDF</li>
                                                    <li onClick={() => exportToFile("/subscription-fee/payments/export/", "excel")} className={props.argument ? props.argument === "player_payments" ? "" : "hidden" : "hidden"}>Esporta pagamenti in Excel</li>
                                                </React.Fragment>
                                                :
                                                null
                                            }
                                            {props.argument === "technical_area" ?
                                                <React.Fragment>
                                                    <li onClick={() => exportToFile("/stats/export/", "pdf")} className={props.argument ? props.argument === "technical_area" ? "" : "hidden" : "hidden"}>Esporta statistiche in PDF</li>
                                                    <li onClick={() => exportToFile("/stats/export/", "excel")} className={props.argument ? props.argument === "technical_area" ? "" : "hidden" : "hidden"}>Esporta statistiche in Excel</li>
                                                </React.Fragment>
                                                :
                                                null
                                            }
                                            {props.argument === "registry" ?
                                                <React.Fragment>
                                                    <li onClick={() => exportToFile("/players/certificate/export/", "pdf")} className={props.argument ? props.argument === "registry" ? "visible" : "hidden" : "hidden"}>Esporta certificati in PDF</li>
                                                    <li onClick={() => exportToFile("/players/certificate/export/","excel")} className={props.argument ? props.argument === "registry" ? "visible" : "hidden" : "hidden"}>Esporta certificati in Excel</li>
                                                    <li onClick={() => sendCertificateEmail()} className={props.argument ? props.argument === "registry" ? "visible" : "hidden" : "hidden"}>E-mail scadenze certificati</li>
                                                </React.Fragment>
                                                :
                                                null
                                            }
                                            {props.argument === "player_ratings" ?
                                                <React.Fragment>
                                                    <li onClick={() => exportToFile("/players/certificate/export/", "pdf")} className={props.argument ? props.argument === "player_ratings" ? "visible" : "hidden" : "hidden"}>Esporta valutazioni in PDF</li>
                                                    <li onClick={() => exportToFile("/players/certificate/export/","excel")} className={props.argument ? props.argument === "player_ratings" ? "visible" : "hidden" : "hidden"}>Esporta valutazioni in Excel</li>
                                                </React.Fragment>
                                                :
                                                null
                                            }
                                        </ul>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </React.Fragment>
                    }


                </div>
                <div className={"wg-filterAreaActions"}>
                    {props.argument === "teams" ?
                        role === "admin" || role === "officeManager" ?
                            <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Aggiungi giocatore"} onClick={() => addPlayer()}/>
                        :
                            null
                    :
                        null
                    }
                    {props.argument === "registry"?
                        role === "admin" || role === "officeManager" ?
                            <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Aggiungi giocatore"} onClick={() => addPlayer()}/>
                            :
                            null
                        :
                        null
                    }

                </div>
            </React.Fragment>

        );
}

export default FilterArea;
