export default class Auth {
    static getPermission(role) {

        let avaiableActionByRole = {
            edit: false
        };

        if(!role) {
            return avaiableActionByRole;
        }

        switch(role) {
            case "admin":
                avaiableActionByRole.edit = true;
                break;
            case "officeManager":
                avaiableActionByRole.edit = true;
                break;
            case "trainer":
                avaiableActionByRole.edit = false;
                break;
            case "guest":
                avaiableActionByRole.edit = false;
                break;
            default:
                avaiableActionByRole.edit = false;
                break;
        }
        return avaiableActionByRole;
    }
}
