import CookieManager from "../../storage/CookieManager";

import {toast} from 'react-toastify';

class DirectFetcher {

    fetchFormData(url, method, formData, teamId = '', season ) {

        const token = CookieManager.getCookie("token");
        let init = {
            'method': method,
            'headers': {
                "X-Authorization": token,
                "X-Season": localStorage.getItem("season")
            }
        };

        if (formData) {
            init.body = formData;
        }

        if(teamId) {
            init.headers["X-TeamId"] = teamId;
        }

        if(season) {
            init.headers["X-Season"] = season;
        }

        return fetch(process.env.REACT_APP_API_BASE_PATH + "/WeasyGoal/api/public"+url, init)
            .then((response) => {
                if(response) {
                    return response.json();
                }
            })
            .then((json) => {
                if(json) {
                    if (json.message && json.message !== "ok") {
                        toast.error(String(json.message));
                    } else {
                        return json;
                    }
                }
            });

    }

    fetch(url, method, body = '', teamId = '', season, checkJson = true, fetchType = "json") {

        const token = CookieManager.getCookie("token");
        let blobHeaders = null;
        let init = {
            'method': method,
            'headers': {
                "Content-Type": "application/json",
                "X-Authorization": token,
                "X-Season": localStorage.getItem("season")
            }
        };
        if (body) {
            init.body = JSON.stringify(body);
        }

        if(teamId) {
            init.headers["X-TeamId"] = teamId;
        }

        if(season) {
            init.headers["X-Season"] = season;
        }

        return fetch(process.env.REACT_APP_API_BASE_PATH + "/WeasyGoal/api/public"+url, init)
            .then((response) => {
                if(fetchType === "json") {
                    if(response) {
                        return response.json();
                    }
                }
                if(fetchType === "file") {
                    return {
                        headers: response.headers,
                        blob: response.blob()
                    }
                }
            })
            .then(fileOrJson => {
                if(fetchType === "json") {
                    return fileOrJson;
                }
                if(fetchType === "file") {
                    blobHeaders = fileOrJson.headers;
                    return fileOrJson.blob;
                }
            })
            .then((json) => {
                if(json) {
                    if(fetchType === "json") {
                        if (json.message && json.message !== "ok") {
                            toast.error(String(json.message));
                        } else {
                            return json;
                        }
                    }
                    if(fetchType === "file") {

                        const blob = new Blob([json], {type: 'octet/stream'})
                        const objectUrl = window.URL.createObjectURL(blob);
                        const anchor = document.createElement("a");

                        let fileName = blobHeaders.get('Content-Disposition');
                        const fileNameIndex= fileName.indexOf('filename');

                        fileName = fileName.substring(fileNameIndex + "filename=".length);

                        anchor.href = objectUrl;
                        anchor.download = fileName;
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    }
                }
            });
    }

}

export default DirectFetcher;
