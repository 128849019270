// Importing React Dependencies
import React from 'react';
import Button from "../Button/Button";
import Spacer from "../Spacer/Spacer";

// Importing WG Dependencies


// Manage Dependencies

const Dialog = (props) => {

    const dialogTitle = (props.title) ? props.title : "Dialog Title";

    React.useEffect(() => {
        if(props.visible) {
            document.getElementById("root").classList.add("locked");
        }
    }, [props.visible])

    function getProps(type, prop, unit) {

        const propsType = props[type];
        const propsProp = (propsType && typeof(props[type][prop]) !== "undefined") ? (props[type][prop]).toString() : '';

        if(unit) {
            return props[type][prop] + props[type].unit
        }

        if(propsType && propsProp) {
            return props[type][prop];
        }

        return '';
    }

    function action(el) {

        if(props[el] && props[el].action) {
            if(el === "secondarybtn") {
                document.getElementById("root").classList.remove("locked");
            }
            return props[el].action();
        }else{
            if(el !== "secondarybtn" && el !== "primarybtn" && el !== "thirdbtn") {
                console.log("No action defined for buttion action: " + el);
            }
        }

        return (props.setDialogVisible) ? props.setDialogVisible(false) : null;
    }

    if(props) {

        const customClasses = props.position && props.position.centered ? (props.visible) ? "wg-dialogCentered wg-dialogVisible " : "wg-dialogCentered" : null;

        return(
            <div className={customClasses}>
                {(props.btnAdd && props.btnAdd !== false) ?
                    <Button vtype={"add"} state={(props.visible ? "disabled" : "enabled")} size={"standard"} icon={"wi-plus"}
                            onClick={() => (props.setVisible ? props.setVisible(true) : null)} />
                :
                    null
                }
                <div className={(props.visible) ? "wg-dialog wg-dialogVisible " : "wg-dialog"} style={{
                    position: getProps("position", "position"),
                    top: getProps("position","top"),
                    right: getProps("position","right"),
                    bottom: getProps("position","bottom"),
                    left: getProps("position","left"),
                    width: getProps("size","width", "unit"),
                    height: getProps("size","height", "unit"),
                    display: getProps("visibility", "display")
                }}>
                    <div className={"wg-dialogContainer"}>
                        <div className={"wg-dialogTitle"} style={{marginBottom: 12}}>{dialogTitle}</div>
                        <div className={"wg-dialogBody"}>{props.children}</div>
                        <div className={props.dialogFooterFixed ? "wg-dialogFooter fixed" : "wg-dialogFooter"}>
                            <Spacer wgSize={"small"} />
                            <div className={"wg-dialogAction"} style={(props.align && props.align === "space-between") ? {justifyContent: "space-between"} : {justifyContent: "flex-end"}}>
                                {(props.secondarybtn && !props.secondarybtn.visible) ?
                                    <Button vtype={"simple"} size={"standard"}
                                            value={props.secondarybtn.value}
                                            id={props.secondarybtn.id ? props.secondarybtn.id : props.secondarybtn.value + new Date().getTime()}
                                            state={(props.secondarybtn.enabled) ? "enabled" : "disabled"}
                                            onClick={() => action("secondarybtn")} />
                                    :
                                    ''}
                                {(props.thirdbtn && !props.thirdbtn.visible) ?
                                    <Button vtype={"simple"} size={"standard"}
                                            value={props.thirdbtn.value}
                                            id={props.thirdbtn.id ? props.thirdbtn.id : props.thirdbtn.value + new Date().getTime()}
                                            state={(props.thirdbtn.enabled) ? "enabled" : "disabled"}
                                            onClick={() => action("thirdbtn")} style={{marginLeft: "auto"}} />
                                    :
                                    ''}
                                {(props.primarybtn && !props.primarybtn.visible) ?
                                    <Button vtype={"solid"} size={"standard"}
                                            value={props.primarybtn.value}
                                            id={props.primarybtn.id ? props.primarybtn.id : props.primarybtn.value + new Date().getTime()}
                                            state={(props.primarybtn.enabled) ? "enabled" : "disabled"}
                                            onClick={() => action("primarybtn")} />
                                    :
                                    ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return (<div>No props found</div>);
    }
};

export default Dialog;
