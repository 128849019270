/* eslint-disable */

// Importing React Dependencies
import React from 'react';
import MatTab from "../../components/common/Tab/MatTab";
import Input from "../../components/common/Input/Input";
import { ColorPicker, useColor } from "react-color-palette";

// Importing WG Dependencies


// Manage Dependencies
import "react-color-palette/lib/css/styles.css";
import Button from "../../components/common/Button/Button";
import ListWrapper from "../../components/common/List/ListWrapper";
import Auth from "../../components/services/Auth/Auth";
import storage from "../../components/storage/storage";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import Dialog from "../../components/common/Dialog/Dialog";
import Checkbox from "../../components/common/Checkbox/Checkbox";
import Loader from "../../components/common/Loader/Loader";
import * as ReactDOM from "react-dom";
import {useHistory} from "react-router-dom";
import FilterDataClass from "../../components/common/Filter/SearchClass";
import {toast} from 'react-toastify';

const Team = (props) => {

    const history = useHistory();

    // Tab and CTA states
    const [activeTab, setActiveTab] = React.useState(1);
    const [tabHandler, setTabHandler] = React.useState(null);
    const [visibleDeleteButton, setVisibleDeleteButton] = React.useState(true);
    const [saveState, setSaveState] = React.useState("enabled");
    const [dialogVisible, setDialogVisible] = React.useState(false);
    const [saveFeeHandler, setSaveFeeHandler] = React.useState(0);
    const [feeDialogHandler, setFeeDialogHandler] = React.useState(0);
    const [saveDataHandler, setSaveDataHandler] = React.useState(false);
    const [deleteHandler, setDeleteHandler] = React.useState(0);
    const [filterText, setFilterText] = React.useState("");

    // Generic Team Data
    const [colorPickerVisible, setColorPickerVisible] = React.useState(false);
    const [colorPickerVisibleHandler, setColorPickerVisibleHandler] = React.useState(0);
    const [teamPlayers, setTeamPlayers] = React.useState([]);
    const [loadingTeamPlayers, setLoadingTeamPlayers] = React.useState(true);
    const [addPlayersToTeamHandler, setAddPlayersToTeamHandler] = React.useState(0);

    // ALl the players
    const [societyPlayers, setSocietyPlayers] = React.useState([]);
    const [filteredSocietyPlayers, setFilteredSocietyPlayers] = React.useState([]);

    // States for players to add (Add Players Dialog)
    const [playersToAdd, setPlayersToAdd] = React.useState([]);
    const [selectedPlayer, setSelectedPlayer] = React.useState(null);
    const [selectedPlayerHandler, setSelectedPlayerHandler] = React.useState(0);
    const [addPlayerButtonState, setAddPlayerButtonState] = React.useState(false);

    // All Team Data
    const [teamData, setTeamData] = React.useState(props.location.state ? {
        team_id: props.location.state.team_id ? props.location.state.team_id : null,
        name: props.location.state.name ? props.location.state.name : null,
        color: props.location.state.color ? props.location.state.color : "#2623D4",
        subscription_fee_amount: props.location.state.subscription_fee_amount ? props.location.state.subscription_fee_amount : "0",
        players_year: props.location.state.players_year ? props.location.state.players_year : null,
        players: []
    } : {
        team_id: null,
        name: "",
        color: "",
        players_year: "",
        players: []
    })
    const [teamFees, setTeamFees] = React.useState([{
        fee: 0,
        is_subscription_fee: 1,
        description: "Quota d'iscrizione",
        team_fee_id: null
    }]);
    const [oldFeeData, setOldFeeData] = React.useState({});

    // State for input
    const [color, setColor] = useColor("hex", teamData.color);
    const [editingFee, setEditingFee] = React.useState({
        team_fee_id: null,
        description: null,
        fee: null,
        is_subscription_fee: 0
    });

    const role = localStorage.getItem("role");

    const updateTeamPlayers = players => {
        if(!players) {
            return;
        }

        if(players.length > 0) {
            const newTeamData = teamData;

            players.map(player => {
                if(player.player_id) {
                    newTeamData.players.push(player.player_id);
                }
            })

            setTeamData(newTeamData);
            setTeamPlayers(players);
        }
    }

    const applyColorSelection = color => {
        if(!color) {
            return;
        }

        teamData.color = color;
        document.getElementById("wg-colorPreview").style.backgroundColor = color;

    }

    React.useEffect(() => {
        if(deleteHandler > 0) {
            new DirectFetcher().fetch("/teams/delete/" + teamData.team_id,"DELETE").then(json => {
                if(json && json.message === "ok") {
                    window.location.href = "/squadre";
                }
            })
        }
    }, [deleteHandler])

    React.useEffect(() => {
        if(teamPlayers.length <= 0 && teamData.team_id) {
            new DirectFetcher().fetch("/teams/players", "POST", null, teamData.team_id).then(json => {
                if(json) {
                    const players = [];
                    if(json.length > 0) {
                        json.map(player => {
                            if(player.player_id) {
                                players.push(player);
                            }
                        })
                    }
                    setLoadingTeamPlayers(false);
                    updateTeamPlayers(players);
                }
            })
        }else{
            setLoadingTeamPlayers(false);
        }

        if(societyPlayers.length <= 0) {
            new DirectFetcher().fetch("/players", "POST").then(json => {
                if(json && json.length > 0) {
                    setSocietyPlayers(json);
                }
            })
        }

        if(teamData.team_id && teamData.subscription_fee_amount && role === "admin") {

            setTeamFees([
                {
                    fee: teamData.subscription_fee_amount,
                    is_subscription_fee: 1,
                    description: "Quota d'iscrizione",
                    team_fee_id: teamData.team_id
                }
            ]);

            /*new DirectFetcher().fetch("/teams/fees", "GET", null, teamData.team_id).then(json => {
               if(json && json.length > 0) {
                   setTeamFees(json);
               }
            });*/
        }

        if(document.getElementById("wg-mainTitle") && props.location && props.location.state) {
            document.getElementById("wg-mainTitle").innerText = teamData.name ? teamData.name : "Crea nuova squadra";
            document.getElementById("wg-mainDescription").innerText = props.location.state.players_count ? props.location.state.players_count + " giocatori" : "Inserisci i dati della squadra";
        }


    }, []);

    React.useEffect(() => {
        setFilteredSocietyPlayers(societyPlayers);
    }, [societyPlayers]);

    React.useEffect(() => {
        if(playersToAdd.length > 0) {
            setAddPlayerButtonState(true);
        }else{
            setAddPlayerButtonState(false);
        }
    }, [playersToAdd]);

    React.useEffect(() => {
        if(tabHandler > 0) {
            if(activeTab) {

                const teamDataTab = document.getElementById("team_data");
                const teamFeesTab = document.getElementById("team_fees");

                if(teamDataTab && teamFeesTab) {
                    if(activeTab.tabIndex === 0) {
                        if(!teamDataTab.classList.contains("visible")) {
                            teamDataTab.classList.add("visible")
                        }
                        teamFeesTab.classList.remove("visible");
                    }

                    if(activeTab.tabIndex === 1) {
                        if(!teamFeesTab.classList.contains("visible")) {
                            teamFeesTab.classList.add("visible")
                        }
                        teamDataTab.classList.remove("visible");
                    }
                }
            }
        }
    }, [tabHandler])

    React.useEffect(() => {
        if(feeDialogHandler > 0) {
            ReactDOM.render(<Dialog visible={true} position={{centered: true}}
                                    size={{width: 500, unit: "px"}} title={"Modifica quota d'iscrizione"}
                                    primarybtn={{
                                        id: "save_edited_fee",
                                        enabled: true,
                                        value: "Conferma",
                                        action: saveFee
                                    }}
                                    secondarybtn={{
                                        enabled: true,
                                        value: "Chiudi",
                                        action: closeEditFeeDialog
                                    }}
            >
                <Input name={"description"} id={"description"} type={"text"} size={"standard"}
                       placeholder={"Quota d'iscrizione"}
                       defaultValue={"quota d'iscrizione"}
                       key={"subscription_fee"}
                       onChange={(event) => handleChangeFee(event)}
                       disabled={true}
                />

                <Input name={"subscription_fee_amount"} id={"subscription_fee_amount"} type={"text"} size={"standard"}
                       placeholder={"Valore quota d'iscrizione"}
                       defaultValue={teamData.subscription_fee_amount}
                       key={editingFee.subscription_fee_amount ? "fee_val_key" : "fee_no_val_key"}
                       onChange={(event) => handleChangeFee(event)} />
            </Dialog>, document.getElementById("wg-editFeeDialog"))

            document.getElementById("wg-editFeeDialog").classList.add("visible");
        }
    }, [feeDialogHandler])

    const handleChange = (input, required = false) => {
        if (!input) {
            return;
        }

        const {id, value} = input.currentTarget;

        if(id) {
            setTeamData(teamData => ({
                ...teamData,
                [id]: value
            }));
        }
    }

    const filterSocietyPlayers = input => {
        if(!input) {
            return;
        }

        const f = new FilterDataClass();
        const filteredData = f.filter(input.currentTarget.value, filteredSocietyPlayers, ["name", "surname"], 1);

        if(filteredData) {
            setFilteredSocietyPlayers(filteredData);
        }else{
            setFilteredSocietyPlayers(societyPlayers);
        }

    }

    const handleChangeFee = input => {
        if (!input) {
            return;
        }

        const {name, value} = input.currentTarget;

        if(name) {
            setEditingFee(editingFee => ({
                ...editingFee,
                [name]: value
            }));
        }

    }

    const colorPickerVisibility = () => {
        document.getElementById("wg-closeColorPreview").classList.toggle("visible");
        setColorPickerVisibleHandler(new Date().getTime());
    }

    const selectPlayerToAdd = (event, player) => {
        if(!event || !player) {
            return;
        }

        setSelectedPlayer(player);
        setSelectedPlayerHandler(new Date().getTime());

    }

    const addPlayersToTeam = () => {
        setAddPlayersToTeamHandler(new Date().getTime());
    }

    const removePlayerFromTeam = playerId => {
        if(!playerId) {
            return;
        }

        const newTeamPlayers = teamPlayers.filter((item) => item.player_id !== playerId);
        setTeamPlayers(newTeamPlayers);
    }

    const deletePlayerDialog = () => {
        setDialogVisible(true);
    }

    const closeEditFeeDialog = () => {
        ReactDOM.unmountComponentAtNode(document.getElementById("wg-editFeeDialog"));
        document.getElementById("wg-editFeeDialog").classList.remove("visible");
    }

    const editFee = feeToEdit => {
        if(!feeToEdit) {
            return;
        }

        const newEditingFee = {
            description: feeToEdit.description ? feeToEdit.description : null,
            fee: feeToEdit.subscription_fee_amount ? feeToEdit.subscription_fee_amount : null,
            team_fee_id: feeToEdit.team_fee_id ? feeToEdit.team_fee_id : null,
            is_subscription_fee: feeToEdit.is_subscription_fee ? feeToEdit.is_subscription_fee : 0,
            new: feeToEdit.new ? feeToEdit.new : null
        }

        setEditingFee(newEditingFee);
        setOldFeeData(newEditingFee);
        setFeeDialogHandler(new Date().getTime());

    }

    const saveFee = () => {
        setSaveFeeHandler(new Date().getTime());
    }

    const undo = () => {
        window.location.reload();
    }
    const saveData = () => {
        setSaveDataHandler(new Date().getTime());
    }

    React.useEffect(() => {
        if(saveDataHandler > 0) {
            let bodyFees = teamFees;
            let players = [];

            if(bodyFees && bodyFees.length > 0) {
                bodyFees.map(fee => {
                    delete fee.new;
                })
            }

            if(teamData.players && teamData.players.length > 0) {
                teamData.players.map(player => {
                    if(player.player_id) {
                        players.push(player.player_id)
                    }else{
                        players.push(player);
                    }
                })
            }

            const bodyTeamData = teamData;
            bodyTeamData.fees = teamFees;
            bodyTeamData.players = players;

            new DirectFetcher().fetch("/teams/save", "POST", bodyTeamData, teamData.team_id ? teamData.team_id : null).then(json => {
                if(json && json.team_id) {
                    teamData.team_id = json.team_id;
                    history.push({
                        pathname: "/squadra",
                        state: teamData
                    })
                    setTeamFees(json.fees);
                    toast.info("La squadra " + teamData.name + " è stata salvata");
                }
            })
        }
    }, [saveDataHandler]);

    React.useEffect(() => {
        if(saveFeeHandler > 0) {
            if(editingFee.subscription_fee_amount) {
                setTeamData(teamData => ({
                    ...teamData,
                    ["subscription_fee_amount"]: editingFee.subscription_fee_amount
                }));
            }
            closeEditFeeDialog();
        }
    }, [saveFeeHandler])

    React.useEffect(() => {
        if(addPlayersToTeamHandler > 0) {
            if(playersToAdd.length > 0) {
                playersToAdd.map(playerToAdd => {
                    setTeamPlayers(teamPlayers => [playerToAdd, ...teamPlayers]);
                })
            }
            document.getElementById("wg-addPlayerToTeam").classList.remove("visible");

            const societyPlayersInput = Array.from(document.getElementsByClassName("wg-playerToAddItem"));

            if(societyPlayersInput.length > 0) {
                societyPlayersInput.map(input => {
                    if(input) {
                        input.querySelector("input").checked = false;
                    }
                })
            }

            setPlayersToAdd([]);

        }
    }, [addPlayersToTeamHandler]);

    React.useEffect(() => {
        if(teamPlayers.length > 0) {
            teamData.players = teamPlayers;
        }
    }, [teamPlayers]);

    React.useEffect(() => {

        if(selectedPlayer) {
            let toAdd = true;

            playersToAdd.map(player => {
                if(player.player_id === selectedPlayer.player_id) {
                    toAdd = false;
                }
            })

            if(toAdd) {
                setPlayersToAdd(playersToAdd => [...playersToAdd, selectedPlayer]);
            }else {
                setPlayersToAdd(playersToAdd.filter(player => {
                    return player.player_id !== selectedPlayer.player_id;
                }));
            }
        }
    }, [selectedPlayerHandler]);

    React.useEffect(() => {
        if(color) {
            const teamDataNew = teamData;
            teamDataNew["color"] = color.hex;

            setTeamData(teamDataNew);
        }
    }, [color]);

    React.useEffect(() => {
        if(colorPickerVisibleHandler > 0) {
            if(!colorPickerVisible) {
                setColorPickerVisible(true)
            }

            if(colorPickerVisible) {
                setColorPickerVisible(false);
            }
        }
    }, [colorPickerVisibleHandler]);

    return (
        <React.Fragment>

            <div className={"wg-closeColorPreview"} id={"wg-closeColorPreview"} onClick={() => colorPickerVisibility()}/>

            <Dialog visible={dialogVisible} position={{centered: true}}
                    size={{width: 30, unit: "%"}} title={"Sei sicuro di voler eliminare " + teamData.name + "?"}
                    primarybtn={{
                        enabled: true,
                        value: "Conferma",
                        action: () => setDeleteHandler(new Date().getTime())
                    }}
                    secondarybtn={{
                        enabled: true,
                        value: "Annulla",
                        action: () => setDialogVisible(false)
                    }}
            />

            <div style={{marginTop: 48}} />

            <MatTab items={
                (!localStorage.getItem('menu_items') || localStorage.getItem('menu_items').includes('payments'))
                && (!localStorage.getItem('role') || ['admin'].includes(localStorage.getItem('role')))
                ? ["Dati squadra", "Quota"]
                : ["Dati squadra"]}
                setActiveTab={setActiveTab} setTabHandler={setTabHandler} />

            <div className={"wg-teamDataContainer visible"} id={"team_data"}>
                <div className={"wg-teamDataBackground"}>
                    <div>Inserisci le informazioni della squadra</div>

                    <div className={"wg-teamDataInput"}>
                        {role === "admin" ?
                            <React.Fragment>
                                <Input id={"name"} type={"text"} size={"standard"} placeholder={"Nome squadra"}
                                       defaultValue={teamData["name"]} onChange={(event) => handleChange(event, true)} />

                                <Input id={"players_year"} type={"text"} size={"standard"} placeholder={"Anno di riferimento"}
                                       defaultValue={teamData["players_year"]} onChange={(event) => handleChange(event, true)} />
                            </React.Fragment>
                            :
                            null
                        }
                        {role !== "admin" ?
                            <React.Fragment>
                                <Input id={"name"} type={"text"} size={"standard"} placeholder={"Nome squadra"} disabled={"disabled"}
                                       defaultValue={teamData["name"]} onChange={(event) => handleChange(event, true)} />

                                <Input id={"players_year"} type={"text"} size={"standard"} placeholder={"Anno di riferimento"} disabled={"disabled"}
                                       defaultValue={teamData["players_year"]} onChange={(event) => handleChange(event, true)} />
                            </React.Fragment>
                        :
                            null
                        }
                    </div>

                    <div className={"wg-colorPickerContainer"}>
                        {role === "admin" ?
                            <React.Fragment>
                                <Button id={"choose_color"} vtype={"solid"} size={"standard"} value={"Scegli colore"} onClick={() => colorPickerVisibility()} />
                                <div style={{backgroundColor: teamData["color"]}} className={"wg-colorPreview"} id={"wg-colorPreview"} />
                            </React.Fragment>
                            :
                            null
                        }

                        {colorPickerVisible ?
                            <div className={"wg-colorPicker"} >
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#DC2C56"}} onClick={() => applyColorSelection("#DC2C56")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#DC2CB5"}} onClick={() => applyColorSelection("#DC2CB5")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#842CDC"}} onClick={() => applyColorSelection("#842CDC")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#2623D4"}} onClick={() => applyColorSelection("#2623D4")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#23B4D4"}} onClick={() => applyColorSelection("#23B4D4")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#71D423"}} onClick={() => applyColorSelection("#71D423")} />
                                <div className={"wg-colorPreview"} style={{backgroundColor: "#FF9900"}} onClick={() => applyColorSelection("#FF9900")} />
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

                {teamPlayers.length > 0 ?
                    <ListWrapper items={teamPlayers}
                                 columns={[
                                     {key: "player_id", value: "Player ID", visible: false},
                                     {unify: {
                                             keys: ["surname", "name"], value: "Nome"
                                         }},
                                     {key: "birth_date", value: "Data di nascita"},
                                     {key: "city", value: "Città di Residenza"},
                                     {key: "certificate_expiration", value: "Scadenza certificato"}
                                 ]}
                                 auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                                 keysToFilter={["name", "surname", "birth_date", "city", "cartificate_expiration"]}
                                 avaiableActions={{
                                     rowActions: ["delete"]
                                 }}
                                 onDelete={removePlayerFromTeam}
                                 showErrorIcon={false}
                                 argument={"teams"} />
                    :
                    loadingTeamPlayers === true ?
                        <Loader />
                        :
                        <div className={"wg-noPlayersFound"}>
                            <div>Nessun giocatore trovato</div>
                            {role === "admin" || role === "officeManager" ?
                                <Button id={"addPlayer"} vtype={"solid"} size={"standard"} value={"Aggiungi giocatore"}
                                        onClick={() => document.getElementById("wg-addPlayerToTeam").classList.add("visible")}/>
                                :
                            null
                            }
                        </div>
                }

                <div className={"wg-addPlayerToTeam"} id={"wg-addPlayerToTeam"}>
                    <Dialog visible={true} position={{centered: true}}
                            size={{width: 500, unit: "px"}} title={teamData.name ? "Aggiungi giocatori a " + teamData.name : "Aggiungi giocatori a squadra"}
                            primarybtn={{
                                enabled: addPlayerButtonState,
                                value: "Aggiungi",
                                action: () => addPlayersToTeam()
                            }}
                            secondarybtn={{
                                enabled: true,
                                value: "Chiudi",
                                action: () => document.getElementById("wg-addPlayerToTeam").classList.remove("visible")
                            }}
                    >
                        {filteredSocietyPlayers && filteredSocietyPlayers.length > 0 ?
                            <Input id={"filter_society_playrs"} type={"text"} size={"standard"} placeholder={"Cerca giocatore per nome e cognome"}
                                   defaultValue={filterText} onChange={(event) => filterSocietyPlayers(event)} />
                        :
                            null
                        }

                        {filteredSocietyPlayers && filteredSocietyPlayers.length > 0 ?
                            filteredSocietyPlayers.map(player => {
                                let alreadyPresent = false;
                                if(teamPlayers.length > 0) {
                                    teamPlayers.map(teamPlayer => {
                                        if(teamPlayer.player_id === player.player_id) {
                                            alreadyPresent = true;
                                        }
                                    })

                                    if(alreadyPresent === false) {
                                        return(
                                            <div className={"wg-playerToAddItem"} key={player.player_id}>
                                                <div className={"wg-select"}>
                                                    <Checkbox id={"wg-check_" + player.player_id} label={player.surname + " " + player.name + " - " + player.birth_date}
                                                              size={"standard"} defaultChecked={0} onClick={event => selectPlayerToAdd(event, player)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }else{
                                        return null
                                    }
                                }else{
                                    return(
                                        <div className={"wg-playerToAddItem"} key={player.player_id}>
                                            <div className={"wg-select"}>
                                                <Checkbox id={"wg-check_" + player.player_id} label={player.surname + " " + player.name + " - " + player.birth_date}
                                                          size={"standard"} defaultChecked={0} onClick={event => selectPlayerToAdd(event, player)}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        :
                            "Nessun giocatore trovato"
                        }
                    </Dialog>
                </div>
            </div>

            {role === "admin" ?
                <div className={"wg-teamDataContainer"} id={"team_fees"}>

                    <div className={"wg-editFeeDialog"} id={"wg-editFeeDialog"} />

                    <div className={"wg-mainCardContainer"}>
                        <div className={"wg-cardItem addCard"} onClick={() => setFeeDialogHandler(new Date().getTime())}>
                            <div className={"wg-cardIcon"}>
                                <i className={"wi-teams"} aria-hidden={"true"}/>
                            </div>
                            <div className={"wg-cardContent"}>
                                <span>Quota d'iscrizione</span>
                                <span>{teamData.subscription_fee_amount ? teamData.subscription_fee_amount + "€" : "0€"}</span>
                            </div>
                        </div>
                    </div>

                </div>
            :
                null
            }

            {role === "admin" || role === "officeManager" ?
                <div className={"wg-CTAction"}>
                    <div className={"left"}>
                        {visibleDeleteButton && role === "admin" ?
                            <Button id={"delete"} vtype={"solid"} customclasses={"delete"} size={"standard"} value={"Elimina squadra"} onClick={() => deletePlayerDialog()} />
                            :
                            null
                        }
                    </div>
                    <div className={"right"}>
                        <Button id={"undo"} vtype={"simple"} size={"standard"} value={"Annulla"} onClick={() => undo()} />
                        <Button id={"save"} vtype={"solid"} size={"standard"} value={"Salva"} state={saveState} onClick={() => saveData()} />
                    </div>
                </div>
                :
                null
            }

        </React.Fragment>
    );
}

export default Team;
