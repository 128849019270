// Importing React Dependencies
import React from 'react';
import ButtonNew from "../../components/common/Button/ButtonNew";
import InputNew from "../../components/common/Input/InputNew";
import Spacer from "../../components/common/Spacer/Spacer";

// Importing GG Dependencies


// Manage Dependencies
import loginBackground from '../../assets/img/login-background.png';
import {ToastContainer} from "react-toastify";
import DirectFetcher from "../../components/services/Fetcher/DirectFetcher";
import CookieManager from "../../components/storage/CookieManager";
import storage from "../../components/storage/storage";

function LoginNew() {

    if(CookieManager.getCookie("token")) {
        window.location.href = "/";
    }

    const [formData, setFormData] = React.useState({
        email: {
            value: '',
            state: ''
        },
        password: {
            value: '',
            state: ''
        }
    });

    const [formError, setFormError] = React.useState({
        email: "",
        password: "",
        submit: ""
    });

    const login = (e) => {

        e.preventDefault();

        const formDataKeys = Object.keys(formData);
        let errorCount = 0;

        if(formDataKeys && formDataKeys.length > 0) {
            // Check if fields are not empty
            formDataKeys.forEach(data => {
                if(!formData[data].value || formData[data].value === "") {
                    setFormData(formData => ({
                        ...formData,
                        [data]: {
                            state: "invalidField"
                        }
                    }));
                    setFormError(formError => ({
                        ...formError,
                        [data]: "Campo obbligatorio"
                    }));
                    errorCount++;
                }
            });
        }

        if(errorCount <= 0) {
            new DirectFetcher().fetch("/user/login",
                "POST",
                {email: formData.email.value, password: formData.password.value},
                null,
                null,
                true
                ).then(json => {
                    if(json && json.season) {
                        localStorage.setItem("season", json.season);
                    }
                    if(json && json.menu_items) {
                        localStorage.setItem("menu_items", json.menu_items);
                    }
                function checkToken() {
                    if(CookieManager.getCookie("token")) {
                        // Navigate to App Wrapper
                        window.location.href = "/";
                    }
                    setTimeout(() => {
                        checkToken();
                    }, 250);
                }

                setTimeout(() => {
                    checkToken();
                }, 250);

                    if(json) {
                        if (json.token) {
                            CookieManager.setCookie("token", json.token, (6.75 * 24 * 60 * 60 * 1000)); // (Days, hours, minutes, seconds)
                            if (json.role) {
                                storage.manageStorage("local", "set", "role", json.role);
                            }
                            if (json.clubName) {
                                storage.manageStorage("local", "set", "clubName", json.clubName);
                            }
                        } else {
                            const error = json;
                            if (error && error.message) {
                                setFormError(formError => ({
                                    ...formError,
                                    // eslint-disable-next-line
                                    ["submit"]: error.message
                                }));
                            }
                        }
                    }
            });

        }
    };

    const handleChange = (props) => {

        const {name, value} = props;

        if(name) {
            setFormData(formData => ({
                ...formData,
                [name]: {
                    value: value,
                }
            }));
            setFormError(formError => ({
                ...formError,
                [name]: ""
            }));
        }

        setFormError({
            email: "",
            password: "",
            submit: ""
        });

    };

    setTimeout(() => {
        document.getElementById("wg-loginFormContainer").classList.add("visible");
        document.getElementById("wg-loginFormContainer").classList.remove("hidden");
    }, 100);

    return(
        <React.Fragment>
            <main className={"wg-login"}>
                <div className={"wg-loginBackground"} style={{backgroundImage: `url(${loginBackground})`}} />
                <div className={"wg-loginForm"}>
                    <div className={"wg-loginFormContainer hidden"} id={"wg-loginFormContainer"}>
                        <form>
                            <div className={"wg-loginHeader"}>
                                <div className={"wg-headerSubTitle"}>Bentornato su WeasyGoal!</div>
                                <div className={"wg-headerTitle"}>Accedi al tuo account</div>
                            </div>
                            <div className={"wg-loginBody"}>
                                <Spacer wgSize={"large"} />
                                <ButtonNew wgStyle={"dark"} wgShadow={"medium"} wgSize={"large"} wgValue={"Registrati con Google"} wgIcon={"wi-google"} />
                                <div className={"wg-loginBodyDivider"}>Oppure</div>
                                <div className={"wg-loginBodyInput"}>
                                    <form onSubmit={login}>
                                        <InputNew wgType={"text"}
                                                  wgName={"email"}
                                                  wgSize={"large"}
                                                  wgStyle={"dark"}
                                                  wgShadow={"medium"}
                                                  wgPlaceholder={"E-mail"}
                                                  wgIcon={"wi-envelope-solid"}
                                                  wgValue={formData.email.value}
                                                  wgState={formData.email.state}
                                                  wgFocus={true}
                                                  wgError={formError.email}
                                                  handleChange={handleChange}
                                        />
                                        <Spacer wgSize={"small"} />
                                        <InputNew wgType={"password"}
                                                  wgName={"password"}
                                                  wgSize={"large"}
                                                  wgStyle={"dark"}
                                                  wgShadow={"medium"}
                                                  wgPlaceholder={"Password"}
                                                  wgIcon={"wi-key-solid"}
                                                  wgValue={formData.password.value}
                                                  wgState={formData.password.state}
                                                  wgError={formError.password}
                                                  handleChange={handleChange}
                                        />
                                        <Spacer wgSize={"medium"} />
                                        <ButtonNew wgStyle={"primary"}
                                                   wgShadow={"medium"}
                                                   wgSize={"large"}
                                                   wgError={formError.submit}
                                                   wgState={"standard"}
                                                   wgValue={"Accedi"}
                                                   wgType={"submit"}
                                                   onClick={login} />
                                    </form>
                                </div>

                            </div>
                            <div className={"wg-loginFooter"}></div>
                        </form>
                    </div>
                </div>
            </main>
            <ToastContainer autoClose={8000} hideProgressBar={true} newestOnTop={true} draggable={false} closeOnClick={false} toastClassName={"wg-toast"} />
        </React.Fragment>
    );
}

export default LoginNew
