// Importing React Dependencies
import React, {useState, useEffect} from 'react';

// Manage Dependencies

const Button = (buttonProps) => {

    // Set props variables
    const [props, setProps] = useState(buttonProps);

    useEffect(() => {
        setProps(props);
        // eslint-disable-next-line
    }, [buttonProps]);

    if(!props.icon) {
        return <button className={"wg-button " + buttonProps.state + ' ' + props.vtype + ' ' + props.size + ' ' + props.customclasses} {...props}>{buttonProps.value}</button>
    }else{
        return <button className={"wg-button " + buttonProps.state + ' ' + props.vtype + ' ' + props.size + ' ' + props.customclasses} {...props}>
            <i className={"wg-btnIcon " + props.icon}  id={props.icon_id ? props.icon_id : new Date().getTime()} aria-hidden={"true"} />
            {props.value}
        </button>
    }
};

export default Button;