// Importing React Dependencies
import React from 'react';
// import ReactDOM from 'react-dom';
import {Route} from 'react-router-dom';

// Importing WG Dependencies
import Teams from "../../routes/Teams/Teams";
import Registry from "../../routes/Registry/Registry";
import Payments from "../../routes/Payments/Payments";
import TechnicalArea from "../../routes/TechnicalArea/TechnicalArea";
import Events from "../../routes/TechnicalArea/Events";
import Player from "../../routes/Registry/Player";
import Team from "../../routes/Teams/Team";
import PlayerPayments from "../../routes/Payments/PlayerPayments";
import PlayersRatingsList from "../../routes/TechnicalArea/PlayersRatingsList";
import PlayerRatingsTemplate from "../../routes/TechnicalArea/PlayerRatingsTemplate";
import PlayerRatings from "../../routes/TechnicalArea/PlayerRatings";


// Manage Dependencies


function Routes() {

    let homeRoute = Registry;
    const role = localStorage.getItem("role") ? localStorage.getItem("role") : null;

    const setDescription = description => {
        if(document.getElementById("wg-mainDescription")) {
            document.getElementById("wg-mainDescription").innerText = description;
        }else{
            setTimeout(() => {
                setDescription(description);
            }, 150);
        }
    }

    const setTitle = title => {
        if(document.getElementById("wg-mainTitle")) {
            document.getElementById("wg-mainTitle").innerText = title;
        }else{
            setTimeout(() => {
                setTitle(title);
            }, 150);
        }
    }

    if(role) {
        // eslint-disable-next-line
        switch (role) {
            case "admin":
            case "officeManager":
                homeRoute = Registry;
                break;
            case "trainer":
                homeRoute = TechnicalArea;
                setDescription("Gestisci gli eventi per la squadra")
                setTitle("Area tecnica");
                break;
        }
    }

    return (
        <React.Fragment>
            <Route exact path={"/"} component={homeRoute} />
            <Route path={"/anagrafica"} component={Registry} />
            <Route path={"/squadre"} component={Teams} />
            <Route path={"/pagamenti"} component={Payments} />
            {/*<Route path={"/area_medica"} component={Medical_area} />*/}
            <Route path={"/area_tecnica"} component={TechnicalArea} />
            <Route path={"/evento"} component={Events} />
            <Route path={"/giocatore"} component={Player} />
            <Route path={"/squadra"} component={Team} />
            <Route path={"/pagamenti_giocatore"} component={PlayerPayments} />
            <Route path={"/valutazione_giocatori"} component={PlayersRatingsList} />
            <Route path={"/template_scheda_valutazione"} component={PlayerRatingsTemplate} />
            <Route path={"/valutazione_giocatore"} component={PlayerRatings} />
        </React.Fragment>
    );
}

export default Routes
