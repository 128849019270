/* eslint-disable */

// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies
import createResource from "../../components/services/Fetcher/Fetcher";
import Loader from "../../components/common/Loader/Loader";
import EventList from "../../components/common/EventList/EventList";
import FilterArea from "../../components/common/FilterArea/FilterArea";

// Manage Dependencies

function EventListByWeek (resources) {

    let eventList;

    if(resources) {
        eventList = resources.teamPlayerListResource.read();
    }

    const currentSeason = localStorage.getItem("season").slice(0, 4);
    const seasonStartDay = new Date("07/01/" + currentSeason);
    const date = new Date();
    const dateString = (date.getMonth() + 1) +'/'+(date.getDate())+'/'+date.getFullYear();
    const today = new Date(dateString);

    const DatesDiffInMilliseconds = today.getTime() - seasonStartDay.getTime();
    let datesDiffInDays = DatesDiffInMilliseconds / (1000 * 3600 * 24);

    let weekDaysArr = [];

    let seasonStartWeekDay = seasonStartDay.getDay();

    if(seasonStartWeekDay === 0) {
        seasonStartWeekDay = 7;
    }

    const firstWeekDaysToSunday = 7 - seasonStartWeekDay;

    let firstWeekEndDate = new Date(seasonStartDay);
        firstWeekEndDate.setDate(seasonStartDay.getDate() + firstWeekDaysToSunday);

    let weekStartDate = seasonStartDay;
    let weekEndDate = firstWeekEndDate;


    const weekStartDayString = weekStartDate.getDate() < 10 ? `0${weekStartDate.getDate()}` : weekStartDate.getDate();
    const weekStartMonthString  = weekStartDate.getMonth() + 1 < 10 ? `0${weekStartDate.getMonth() + 1}` : weekStartDate.getMonth() + 1;

    const weekEndDayString = weekEndDate.getDate() < 10 ? `0${weekEndDate.getDate()}` : weekEndDate.getDate();
    const weekEndMonthString  = weekEndDate.getMonth() + 1 < 10 ? `0${weekEndDate.getMonth() + 1}` : weekEndDate.getMonth() + 1;

    let weekStartDateString = weekStartDayString + "/" + weekStartMonthString + "/" + weekStartDate.getFullYear();
    let weekEndDateString = weekEndDayString + "/" + weekEndMonthString + "/" + weekEndDate.getFullYear();

    datesDiffInDays = datesDiffInDays + seasonStartWeekDay;
    const weeksByDayOne = Math.ceil(Number((datesDiffInDays / 7)));

    for(let i = 0; i < weeksByDayOne; i++) {

        if(i > 0) {
            let newWeekStartDate = new Date(weekEndDate);
                newWeekStartDate.setDate(weekEndDate.getDate() + 1);
                weekStartDate = newWeekStartDate;

            let newWeekEndDate = new Date(weekStartDate);
                newWeekEndDate.setDate(weekStartDate.getDate() + 6);
                weekEndDate = newWeekEndDate;

            const weekStartDay = newWeekStartDate.getDate() < 10 ? `0${newWeekStartDate.getDate()}` : newWeekStartDate.getDate();
            const weekStartMonth = newWeekStartDate.getMonth() + 1 < 10 ? `0${newWeekStartDate.getMonth() + 1}` : newWeekStartDate.getMonth() + 1;

            const weekEndDay = newWeekEndDate.getDate() < 10 ? `0${newWeekEndDate.getDate()}` : newWeekEndDate.getDate();
            const weekEndMonth = newWeekEndDate.getMonth() + 1 < 10 ? `0${newWeekEndDate.getMonth() + 1}` : newWeekEndDate.getMonth() + 1;

            weekStartDateString = weekStartDay + "/" + weekStartMonth + "/" + newWeekStartDate.getFullYear();
            weekEndDateString = weekEndDay + "/" + weekEndMonth + "/" + newWeekEndDate.getFullYear();
        }

        weekDaysArr.push({
            week: i + 1,
            date: weekStartDateString + " - " + weekEndDateString
        });
    }

    if (eventList && eventList.length > 0) {

        return (
            <React.Fragment>
                {
                <div className={"wg-eventListContainer"}>
                    <EventList list={resources.teamPlayerListResource.read()} seasonWeek={weekDaysArr}
                               playerList={resources.playerList} teamId={resources.teamId}/>
                </div>
                }
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {
                    <div className={"wg-eventListContainer"}>
                        <EventList list={[]} seasonWeek={weekDaysArr}
                                   playerList={resources.playerList} teamId={resources.teamId}/>
                    </div>
                }
            </React.Fragment>
        );
    }
}

function TeamList (resources) {

    const teamList = resources.teamListRecource ? resources.teamListRecource.read() : null;
    const playerList = resources.playerList ? resources.playerList.read() : null;
    const [selectedTeam, setSelectedTeam] = React.useState(null);

    const onChangeTeam = team => {
        if(!team) {
            return;
        }

        setSelectedTeam(team);

    }

    const extraOptions = (evt) => {

        if(!evt) {
            return;
        }

        let target = evt.currentTarget;
        let activeExtraOptionsMenu = document.getElementsByClassName("wg-extraOptions")[0];

        if(!target.parentElement.classList.contains("visible")) {
            setTimeout(() => {
                target.parentElement.classList.add("visible");
            }, 50);
        }else{
            target.parentElement.classList.remove("visible");
        }

        document.addEventListener("click", evt => {

            const clickTarget = evt.target;

            if(activeExtraOptionsMenu.classList.contains("visible")) {
                if(clickTarget !== activeExtraOptionsMenu) {
                    activeExtraOptionsMenu.classList.remove("visible");
                }
            }
        });

    }

    React.useEffect(() => {
        if(selectedTeam) {
            if(teamList && teamList.length > 0) {
                teamList.map(team => {
                    if(team.name === selectedTeam) {
                        resources.setTeamId(team.team_id);
                    }
                })
            }
        }
    }, [selectedTeam]);

    if(teamList && teamList.length > 0) {
        if(!resources.teamId) {
            resources.setTeamId(teamList[0].team_id);
        }
    }

    if(selectedTeam) {
        return (
            <React.Fragment>
                <FilterArea argument={"technical_area"} onChangeTeam={onChangeTeam} defaultSelection={true} showSearch={false} showSort={true} extraOptions={event => extraOptions(event)} />
                {teamList && teamList.length > 0 ?
                    <EventListByWeek
                        teamPlayerListResource={
                            createResource(
                                "/events",
                                "GET",
                                null,
                                resources.teamId,
                                null,
                            )
                        }
                        playerList={playerList}
                        teamId={resources.teamId}
                    />
                    :
                    null
                }
            </React.Fragment>
        );
    }else{
        return <FilterArea argument={"technical_area"} onChangeTeam={onChangeTeam} defaultSelection={true} showSearch={false} showSort={false} />
    }
}

export default function TechnicalArea() {

    const [teamId, setTeamId] = React.useState(null);

    return (
        <React.Fragment>
            <Suspense fallback={<Loader />}>
                <TeamList teamListRecource={
                    createResource(
                        "/teams",
                        "GET",
                        null,
                        null,
                        null
                    )
                }
                          playerList={
                              teamId ?
                                  createResource(
                                      "/teams/players",
                                      "POST",
                                      ["player_id", "name", "surname", "birth_date", "first_role"],
                                      teamId,
                                      null
                                  )
                                  :
                                  null
                          }
                  setTeamId={setTeamId}
                  teamId={teamId}
                />
            </Suspense>
        </React.Fragment>
    )
}
