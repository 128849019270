// Importing React Dependencies
import React from 'react';
import Event from "./Event";
import NoResultFound from "../Filter/NoResultFound";

// Importing WG Dependencies


// Manage Dependencies

const EventList = (props) => {

    const [seasonWeek, setSeasonWeek] = React.useState(props.seasonWeek.reverse());
    const [eventByWeek, setEventByWeek] = React.useState(props.list);

    React.useEffect(() => {
        setSeasonWeek(props.seasonWeek.reverse());
        setEventByWeek(props.list);
    }, [props]);

    return(
        seasonWeek.length > 0 ?
            seasonWeek.map((week, weekIndex) => {
                return (
                    <div className={"wg-eventWeekSection"} key={"week_"+week.week} id={`week_${week.week}`}>
                        {week.week === seasonWeek.length ?
                                <div className={"wg-eventWeekTitle active"}>{week.date}</div>
                                :
                                <div className={"wg-eventWeekTitle"}>{week.date}</div>
                        }

                        {eventByWeek && eventByWeek.length > 0 ?
                            <div className={"wg-eventWeekItems"}>
                                <Event data={null} add={true} date={week} playerList={props.playerList} teamId={props.teamId} key={week + "add" + weekIndex } week={week} addEvent={props.addEvent} />
                                {
                                    // eslint-disable-next-line
                                    eventByWeek.map((event, index) => {
                                        if(Number(event.week) === week.week) {
                                            return <Event data={event} date={week} playerList={props.playerList} key={week.week + event.event_id + index } teamId={props.teamId} editEvent={props.editEvent}/>
                                        }
                                    })
                                }
                            </div>
                            :
                            <div className={"wg-eventWeekItems"}>
                                <Event data={null} add={true} date={week} playerList={props.playerList} teamId={props.teamId} key={week + "add" + weekIndex } week={week.week} addEvent={props.addEvent} />
                            </div>
                        }
                    </div>
                )
            })
        :
        <NoResultFound />
    )
}

export default EventList;
