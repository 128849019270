// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import AddItemVariant from "../Items/AddItem/AddItemVariant";
import Dialog from "../Dialog/Dialog";
import createResource from "../../services/Fetcher/Fetcher";

// Manage Dependencies

export default function TeamsDetail(props) {

    // List Items state
    const [itemList, setItemList] = React.useState(props.items);
    const [tempList, setTempList] = React.useState([]);
    const [avaiableItems, setAvaiableItems] = React.useState([]);
    const [updateHandler, setUpdateHandler] = React.useState(0);

    // Filtered and sorted data
    // eslint-disable-next-line
    const [filteredData, setFilteredData] = React.useState(itemList);
    // eslint-disable-next-line
    const [sortedData, setSortedData] = React.useState([]);

    // Dialog State
    const [eraseList, setEraseList] = React.useState(0);
    // eslint-disable-next-line
    const [dialogVisible, setDialogVisible] = React.useState(props.dialogVisible);

    const saveItemsAction = () => {
        setUpdateHandler(updateHandler + 1);
    };

    const saveItems = (items) => {

        let fakeItemList = itemList;
        let playerIdList = [];
        if(items && items.length > 0) {
            // eslint-disable-next-line
            items.map((item) => {
                if(item) {
                    fakeItemList.push(item);
                    playerIdList.push(item.player_id);
                }
            });
        }

        savePlayerListResourceById(playerIdList);
        setItemList(fakeItemList);
        setTempList([]);
        setEraseList(eraseList + 1);

        setFilteredData(fakeItemList);
    };

    const savePlayerListResourceById= (playerIdList) => {
        createResource(
            "/teams/players/add",
            "POST",
            playerIdList,
            props.teamId,
            null,
        );
    };

    React.useEffect(() => {
        setFilteredData(sortedData[0]);
        // eslint-disable-next-line
    }, [sortedData]);

    // Synchronize Team List with current List Items
    React.useEffect(() => {
        if(updateHandler > 0) {
            saveItems(tempList);
            props.setItemList(itemList);
            props.setDialogVisible(false);
        }
        // eslint-disable-next-line
    }, [updateHandler]);

    // Synchronize Filtered List with current List Items
    React.useEffect(() => {
        if(itemList && itemList.length > 0) {
            setFilteredData(itemList);
        }
        // eslint-disable-next-line
    }, [itemList]);

    React.useEffect(() => {
        if(props.avaiableItemList && props.avaiableItemList.length > 0 ) {
            const newAvaiableItems = props.avaiableItemList;
            setAvaiableItems(newAvaiableItems);
        }
        // eslint-disable-next-line
    }, [dialogVisible]);

    return (
        <React.Fragment>
            {(dialogVisible) ?
                <Dialog visible={dialogVisible} setDialogVisible={props.setDialogVisible}
                        position={{position: "fixed", right: 70, bottom: 70}}
                        size={{width: 500, unit: "px"}} title={"Add person"}
                        primarybtn={{
                            enabled: true,
                            value: "Conferma",
                            action: saveItemsAction
                        }}
                        secondarybtn={{
                            enabled: true,
                            value: "Chiudi"
                        }}
                >
                    {avaiableItems && avaiableItems.length > 0 ?
                        <AddItemVariant itemList={{items: tempList, update: setTempList}}
                                        avaiableItemList={avaiableItems}
                                        eraseList={eraseList}
                        />
                    :
                        null
                    }
                </Dialog>
                :
                null
            }
        </React.Fragment>
    )
}
