/* eslint-disable */

// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies


// Manage Dependencies

const MatTab = (props) => {

    const [activeTab, setActiveTab] = React.useState(0);
    const role = localStorage.getItem("role");

    const handleClick = (tab, tabIndex) => {
        if(!tab) {
            return;
        }

        setActiveTab(tabIndex);
        if(props.setActiveTab) {
            props.setActiveTab({tab, tabIndex});
        }

        if(props.setTabHandler) {
            props.setTabHandler(new Date().getTime());
        }
    }

    React.useEffect(() => {
        if(props && props.items && props.items.length > 0 && props.setActiveTab) {
            const tab = props.items[0];
            props.setActiveTab(tab, activeTab);
        }
    }, []);


    if(props && props.items && props.items.length > 0) {
        return props.items.map((tab, tabIndex) => {
            if(tab === "Tasse") {
                if(role === "admin") {
                    return(
                        <div className={"wg-tabContainer material"} key={tabIndex} id={(props.id ? props.id : new Date().getTime())} onClick={() => handleClick(tab, tabIndex)}>
                            <div className={`wg-tab ${(tabIndex === activeTab ? "active" : "")}`}>
                                {tab}
                            </div>
                        </div>
                    );
                }
            }else{
                return(
                    <div className={"wg-tabContainer material"} key={tabIndex} id={(props.id ? props.id : new Date().getTime())} onClick={() => handleClick(tab, tabIndex)}>
                        <div className={`wg-tab ${(tabIndex === activeTab ? "active" : "")}`}>
                            {tab}
                        </div>
                    </div>
                );
            }
        })
    }

}

export default MatTab;
