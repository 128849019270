import CookieManager from "../../storage/CookieManager";
import {toast} from "react-toastify";

async function fetchData(props) {

    let fetchProperties = {};
    const token = CookieManager.getCookie("token");

    if(token) {
        fetchProperties = {
            url: props.url,
            options: {
                "method": props.method,
                "headers": {
                    "Content-Type": "application/json",
                    "X-Authorization": token,
                }
            }
        };

        if(props.body) {
            fetchProperties.options["body"] = JSON.stringify(props.body);
        }

        if(props.teamId) {
            fetchProperties.options.headers["X-TeamId"] = props.teamId;
        }

        if(props.season) {
            fetchProperties.options.headers["X-Season"] = props.season;
        }

        const response = await fetch(fetchProperties.url, fetchProperties.options);

        if(response.status === 200) {
            try {
                return await response.json();
            }catch(e){
                // Uncomment the line below only if develop mode, for show errors catched by server
            }
        }else{
            const error = await response.json();

            if(props.displayErrors) {
                toast.error(`${error.message}`);
            }
        }


    }
}

export default function createResource(url, method, body = null, teamId = null, season = null, displayErrors = true, returnEmptyData = false) {
    let status = "pending";
    let result;
    let suspender = fetchData({
        url: process.env.REACT_APP_API_BASE_PATH + "/WeasyGoal/api/public"+url,
        method: method,
        body: body,
        teamId: teamId,
        season: localStorage.getItem("season"),
        displayErrors: displayErrors,
        returnEmptyData: returnEmptyData
    }).then(
        response => {
            status = "success";
            result = response;
        },
        error => {
            status = "error";
            result = error;
        }
    );

    return {
        read() {
            if(status === "pending") {
                throw suspender;
            }else if(status === "error") {
                throw result;
            }else if(status === "success") {
                return result;
            }
        }
    };
}
