// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies
import createResource from "../../components/services/Fetcher/Fetcher";
import Auth from "../../components/services/Auth/Auth";
import storage from "../../components/storage/storage";
import ListWrapper from "../../components/common/List/ListWrapper";
import Loader from "../../components/common/Loader/Loader";

// Manage Dependencies

function Players(resources) {
    const playerList = resources.playerListResource.read();

    return (
        <ListWrapper items={playerList}
                     columns={[
                         {key: "player_id", value: "Player ID", visible: false},
                         {unify: {
                                 keys: ["surname", "name"], value: "Nome"
                             }},
                         {key: "birth_date", value: "Data di nascita"},
                         {key: "city", value: "Città di Residenza"},
                         {key: "certificate_expiration", value: "Scadenza Certificato Medico"},
                         {key: "team_names", value: "Squadra/e"}
                     ]}
                     auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                     keysToFilter={["name", "surname", "birth_date", "city"]}
                     avaiableActions={{
                         rowActions: ["edit", "click"],
                         dialogActions: ["click"]
                     }}
                     argument={"registry"}
        />
    );
}

export default function Registry() {

    return(
        <React.Fragment>
            <Suspense fallback={<Loader />}>
                <Players playerListResource={
                    createResource(
                        "/players",
                        "POST",
                        null,
                        null,
                        null
                    )
                }
                />
            </Suspense>
        </React.Fragment>
    );
}
