// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies


// Manage Dependencies

function DialogList(props) {

    function getData(item) {
        props.getData(item);
    }

    return (
        <div className={"wg-dialogList"}>
            {props.data && props.data.length > 0 ?
                <ul>
                    {props.data.map((item) => {
                        return <li key={item.player_id} onClick={(event) => getData(item)}>{item.name + " " + item.surname}</li>
                    })}
                </ul>
                :
                ''
            }
        </div>
    )
}

export default DialogList;