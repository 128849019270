// Importing React Dependencies
import React, {useState, useEffect} from 'react';

// Importing WG Dependencies
import Field from "../Fields/Field";
import Button from "../Button/Button";
import DirectFetcher from "../../services/Fetcher/DirectFetcher";
import {toast} from 'react-toastify';

// Manage Dependencies

const RatingsTemplate = (props) => {

    const [categoriesTemplate, setCategoriesTemplate] = React.useState({
        player_info: {
            description: "Dati anagrafici",
            fields: []
        },
        technics: {
            description: "Abilità tecniche",
            fields: []
        },
        tactics: {
            description: "Abilità tattiche",
            fields: []
        },
        athletics: {
            description: "Abilità atletiche",
            fields: []
        },
        behaviors: {
            description: "Caratteristiche comportamentali",
            fields: []
        }
    });

    const [categoriesToEdit, setCategoriesToEdit] = React.useState(props.categories);

    const [handler, setHandler] = React.useState(0);
    const [categoriesToEditHandler, setCategoriesToEditHandler] = React.useState(0);

    const [saveDataHandler, setSaveDataHandler] = React.useState(false);
    const [saveButtonState, setSaveButtonState] = React.useState("disabled");

    const role = localStorage.getItem("role");

    React.useEffect(() => {
        if(props.categories && props.categories.length > 0) {

            const categoryList = categoriesTemplate;

            props.categories.map(category => {
                if(category) {
                    switch(category.category) {
                        case "player_info":
                            categoryList.player_info.fields.push(category);
                            break;
                        case "technics":
                            categoryList.technics.fields.push(category);
                            break;
                        case "tactics":
                            categoryList.tactics.fields.push(category);
                            break;
                        case "athletics":
                            categoryList.athletics.fields.push(category);
                            break;
                        case "behaviors":
                            categoryList.behaviors.fields.push(category);
                            break;
                        default:
                    }
                }
            });

            setCategoriesTemplate(categoryList);
            setHandler(new Date().getTime());

        }
    }, [props])

    React.useEffect(() => {
        if(saveDataHandler > 0) {

            new DirectFetcher().fetch("/players/evaluations/items/save", "POST", categoriesToEdit).then(json => {
                if(json && json.message === "ok") {
                    toast.info("Template scheda valutazione salvato");
                }else{
                    toast.error("Errore nel salvataggio del tamplate");
                }
            });
        }
    }, [saveDataHandler])

    React.useEffect(() => {
        if(categoriesToEditHandler > 0) {
            setSaveButtonState("enabled");
        }else{
            setSaveButtonState("disabled");
        }
    }, [categoriesToEditHandler])

    const saveData = () => {
        setSaveDataHandler(new Date().getTime());
    }

    const handleChange = (field) => {
        if(field) {

            const newEditedCategories = props.categories;

            if(newEditedCategories && newEditedCategories.length > 0) {

                newEditedCategories.map((category, index) => {

                    if(category.player_evaluation_item_id === field.player_evaluation_item_id) {
                        newEditedCategories[index].enabled = category.enabled === "1" ? "0" : "1";
                    }

                });

            }else{
                console.log("Elemento non trovato...")
            }

            setCategoriesToEdit(newEditedCategories);
            setCategoriesToEditHandler(new Date().getTime());

        }
    }

    return(
        <React.Fragment>
            {handler > 0 ?
                props.categories && props.categories.length > 0 ?
                        Object.keys(categoriesTemplate).map((key, index) => {
                            return(
                                <React.Fragment>
                                    <div className={"wg-PlayerRatingTemplateSection"}>
                                        <div className={"wg-PlayerRatingTemplateSectionTitle"}>
                                            {categoriesTemplate[key].description}
                                        </div>
                                        <div className={"wg-PlayerRatingTemplateFields"}>
                                            {categoriesTemplate[key].fields && categoriesTemplate[key].fields.length > 0 ?
                                                categoriesTemplate[key].fields.map(field => {
                                                    return <Field key={field.player_evaluation_item_id} selectable={field.is_numeric} checked={field.enabled === "1" ? true : false} value={field.description} id={field.player_evaluation_item_id} onClick={() => handleChange(field) } />
                                                })
                                                :
                                                null
                                            }
                                        </div>
                                    </div>

                                    {role === "admin" ?
                                        <div className={"wg-CTAction"}>
                                            <div className={"left"} />
                                            <div className={"right"}>
                                                <Button id={"undo"} vtype={"simple"} size={"standard"} value={"Annulla"} onClick={() => window.location.reload()} />
                                                <Button id={"save"} vtype={"solid"} size={"standard"} state={saveButtonState} value={"Salva"} onClick={() => saveData()} />
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </React.Fragment>
                            )
                        })
                    :null
            : null}
        </React.Fragment>
    )

};

export default RatingsTemplate;
