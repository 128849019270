export default class ClassManager {

    static refElement = HTMLElement;

    static add(id, className) {

        this.refElement = document.getElementById(id);

        if(this.refElement && className) {
            if(!this.refElement.classList.contains(className)) {
                this.refElement.classList.add(className);
            }
        }
    }

    static remove(id, className) {

        this.refElement = document.getElementById(id);
        
        if (this.refElement && className) {
            if (this.refElement.classList.contains(className)) {
                this.refElement.classList.remove(className);
            }
        }
    }
}