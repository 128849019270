// Importing React Dependencies
import React, {useState, useEffect} from 'react';
import Switch from "../Switch/Switch";

// Importing WG Dependencies

// Manage Dependencies

const Field = (props) => {

    return(
        <React.Fragment>
            <div className={"wg-fieldMain"} id={props.id} onClick={props.onClick} >
                {props.value ? props.value : "Field name"}
                {props.selectable ? <Switch checked={props.checked ? props.checked : false} /> : null}
            </div>
        </React.Fragment>
    );

};

export default Field;
