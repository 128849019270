import {toast} from "react-toastify";

class SearchClass {

    constructor() {
        this.filteredData = [];
    }

    prepareFilteredData(array, keyToCompare) {
        if(array && array.length > 0) {
            return array
                .map(e => e[keyToCompare])

                // Get unique keys from array
                .map((e, i, uniqueKey) => uniqueKey.indexOf(e) === i && i)

                // Remove duplicated keys
                .filter(e => array[e]).map(e => array[e]);
        }
    }

     filter(input, json, keysToFilter, minCharsForSearch, like = true, byTeam = {selectedTeam: null}) {

        let itemValue = '';

        if(input && json && keysToFilter && minCharsForSearch) {
            if(input.length >= minCharsForSearch) {
                if(json.length > 0) {
                    // eslint-disable-next-line
                     json.map((item) => {
                        if(Object.keys(item).length > 0) {
                            // eslint-disable-next-line
                            Object.keys(item).map((itemKey) => {
                                if(keysToFilter.length > 0) {
                                    // eslint-disable-next-line
                                    keysToFilter.map((key) => {
                                        if(key === itemKey) {
                                            itemValue = item[key];
                                            if(itemValue) {
                                                itemValue = itemValue.toLowerCase();
                                                if(like) {
                                                    if(byTeam && byTeam.selectedTeam) {

                                                        const sTeam = byTeam.selectedTeam ? byTeam.selectedTeam.toLowerCase() : byTeam.selectedTeam;
                                                        const pTeam = item.team_names ? item.team_names.toLowerCase() : item.team_names;

                                                        if(pTeam) {
                                                            if(pTeam.includes(sTeam)) {
                                                                if(itemValue.includes(input.toLowerCase(), 0)) {
                                                                    this.filteredData.push(item);
                                                                }
                                                            }
                                                        }
                                                    }else{
                                                        if(itemValue.includes(input.toLowerCase(), 0)) {
                                                            this.filteredData.push(item);
                                                        }
                                                    }
                                                }else{
                                                    if(itemValue === input.toLowerCase().replace(" ", "")) {
                                                        this.filteredData.push(item);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }else{
            let missingProps = "";
            if(typeof(input) === "undefined" || typeof(json) === "undefined" || typeof(keysToFilter) === "undefined") {
                missingProps = "Input";
                toast.error("Missing filter props: " + missingProps);
            }
        }

        return this.prepareFilteredData(this.filteredData, "player_id");
    }
}

export default SearchClass;
