// Importing React Dependencies
import React from 'react';

// Importing GG Dependencies


// Manage Dependencies
let firstLoad = true;


function InputNew(props) {

    const [inputProps, setInputProps] = React.useState({
        style: "solid",
        size: "standard",
        icon: null,
        shadow: "mediumShadow",
        value: "",
        state: "enabled",
        placeholder: "Input placeholder",
        focus: false,
        error: ""
    });

    setTimeout(() => {
        if(firstLoad) {
            if(props.wgFocus && props.wgName) {
                document.getElementById(`wg-input${props.wgName}`).focus();
            }
            firstLoad = !firstLoad;
        }
    }, 50);

    React.useEffect(() => {
        setInputProps({
            type: (props.wgType ? props.wgType : 'text'),
            style: (props.wgStyle ? props.wgStyle : ''),
            size: (props.wgSize ? props.wgSize : ''),
            icon: (props.wgIcon ? props.wgIcon : ''),
            shadow:(props.wgShadow ? props.wgShadow : ''),
            value: (props.wgValue ? props.wgValue : ''),
            state: (props.wgState ? props.wgState : ''),
            name: (props.wgName ? props.wgName : ''),
            error: (props.wgError ? props.wgError : ''),
            placeholder: (props.wgPlaceholder ? props.wgPlaceholder : 'Input placeholder')
        });
    }, [props]);

    const handleChange = (target) => {
        props.handleChange(target);
    };

    return (
        <React.Fragment>
            <div className={"wg-inputContainer"}>
                <input type={inputProps.type} id={`wg-input${inputProps.name}`}
                       className={`wg-inputNew ${inputProps.style} ${inputProps.size} ${inputProps.shadow}Shadow ${inputProps.state}`}
                       placeholder={inputProps.placeholder}
                       value={inputProps.value}
                       onChange={event => handleChange(event.currentTarget)}
                       name={inputProps.name}
                />
                <span className={"wg-inputIcon"}>{(inputProps.icon && inputProps.icon !== "w-google" ? <i className={inputProps.icon} aria-hidden={"true"} /> : null)}</span>
                {inputProps.error ?
                    <span className={"wg-inputError"}>{(inputProps.error)}</span>
                :
                    null
                }
            </div>
        </React.Fragment>
    );
}

export default InputNew