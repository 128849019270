// Importing React Dependencies
import React from 'react';

// Importing GG Dependencies


// Manage Dependencies


function Spacer(props) {

    let spacerProps = null;

    switch(props.wgSize) {
        case 'small':
            spacerProps = 24;
            break;
        case 'medium':
            spacerProps = 48;
            break;
        case 'large':
            spacerProps = 72;
            break;
        default:
            spacerProps = 6;
    }

    return (
        <div style={{marginTop: (spacerProps ? spacerProps : 12), marginBottom: (spacerProps ? spacerProps : 12)}} />
    );
}

export default Spacer