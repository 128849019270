// Importing React Dependencies
import React from 'react';
import {Link} from "react-router-dom";

// Importing WG Dependencies


// Manage Dependencies

const Event = (props) => {

    const event = props.data;
    let eventType = null;
    let eventDescription = null;

    let matchResult, resultClass, matchType = "";

    if(event) {
        switch (event["event_type"]) {
            case "training":
                eventType = "Allenamento";
                eventDescription = "Presenti " + event.presents + " su " + event.total;
                break;
            case "match":
                eventType = "Partita";

                if(event.won === "0") {
                    matchResult = "Pareggiato";
                }

                if(event.won === "1") {
                    matchResult = "Vinto";
                }

                if(event.won === "-1") {
                    matchResult = "Perso";
                }

                if(event.home_team_score > event.away_team_score) {
                    eventDescription = (`${matchResult} ${event.home_team_score} - ${event.away_team_score}`);
                }else if(event.home_team_score === event.home_team_score){
                    eventDescription = (`${matchResult} ${event.away_team_score} - ${event.home_team_score}`);
                }else{
                    eventDescription = (`${matchResult} ${event.home_team_score} - ${event.away_team_score}`);
                }


                break;
            default:
                eventType = "Allenamento";
        }

        switch(matchResult) {
            case "Vinto":
                resultClass = "win";
                break;
            case "Pareggiato":
                resultClass = "draw";
                break;
            case "Perso":
                resultClass = "lose";
                break;
            default:
                resultClass = "draw";
        }

        // eventType ? `${eventType}` : event.match_type ? `${event.match_type

        if(eventType) {
            matchType = eventType;
            if(event.match_type) {
                if(event.match_type === "Campionato" || event.match_type === "Torneo" || event.match_type === "Amichevole") {
                    let homeTeam = localStorage.getItem('clubName') ? localStorage.getItem('clubName') : 'Nome Squadra';
                    matchType = event.is_home_match ? homeTeam + " vs " + event.opposite_team : event.opposite_team + " vs " + homeTeam
                }else{
                    matchType = matchType + " - " + event.match_type;
                }
            }
        }else{
            matchType = "-";
        }
    }

    if(event) {
        let eventId;
        return (
            <Link to={{
                pathname: "/evento",
                props: {
                    week: props.week ? props.week.week : props.week,
                    eventId: props.data.event_id,
                    eventData: props.data,
                    playerList: props.playerList,
                    navigated: true,
                    team_id: props.teamId,
                    date: props.date
                }
            }}>
                <div className={"wg-eventItem"} id={"event_"+event.event_id}>
                    <div className={"wg-eventItemHandler"} />
                    <div className={`wg-eventIcon ${resultClass}`} >
                        {
                            event.event_type === "training" ?
                                <i className={"wi-dumbbell-solid"} aria-hidden={"true"}/>
                                :
                                event.event_type === "match" ?
                                    <i className={"wi-trophy-solid win"} aria-hidden={"true"}/>
                                    :
                                    null
                        }
                    </div>
                    <div className={"wg-eventInfo"}>
                        <span className={"wg-eventInfoName"}>{matchType}</span>
                        <span className={"wg-eventInfoDate"}>{event.date ? event.date : "-"}</span>
                    </div>
                    <div className={"wg-eventExtraInfo"}>{eventDescription}</div>
                </div>
            </Link>
        );
    }else{

        const week = props.week && props.week.week ? props.week.week : props.week;

        return(
            <Link to={{
                pathname: "/evento",
                props: {
                    week: week,
                    eventId: null,
                    playerList: props.playerList,
                    navigated: true,
                    team_id: props.teamId,
                    date: props.date
                }
            }}>
                <div className={"wg-eventItem add"}>
                    <div className={"wg-eventIcon"}><i className={"wi-plus"} aria-hidden={"true"} /> </div>
                    <div className={"wg-eventInfo"}>Aggiungi evento</div>
                    <div className={"wg-eventExtraInfo"} />
                </div>
            </Link>
        )
    }
}

export default Event;
