// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import {Link} from "react-router-dom";
import {toast} from 'react-toastify';

// Manage Dependencies
import SocietyLogo from "../../../assets/img/logo_virtus.png";
import BaldoLogo from "../../../assets/img/baldo.png";
import Button from "../Button/Button";
import Dropdown from "react-dropdown";


const MainMenu = () => {

    const menuList = [
        {
            to: "anagrafica",
            icon: "agenda",
            text: "Anagrafica",
            key: "registry",
            roles: ["admin", "officeManager"]
        },
        {
            to: "squadre",
            icon: "teams",
            text: "Squadre",
            key: "teams",
            roles: ["admin", "officeManager", "trainer"]
        },
        {
            to: "pagamenti",
            icon: "dollar-sign-solid",
            text: "Pagamenti",
            key: "payments",
            roles: ["admin", "officeManager"]
        },
        {
            to: "area_tecnica",
            icon: "futbol-solidr",
            text: "Eventi",
            key: "events",
            roles: ["admin", "trainer"]
        },
        {
            to: "valutazione_giocatori",
            icon: "futbol-solidr",
            text: "Valutazione giocatori",
            key: "ratings",
            roles: ["admin", "trainer"]
        }
    ];

    const [activeMenuItem, setActiveMenuItem] = React.useState(null);

    React.useEffect(() => {
        if(!activeMenuItem) {
            const currentRoute = window.location.pathname.replace("/", "");

            if(currentRoute) {
                // eslint-disable-next-line
                menuList.map((item, index) => {
                    if(item.to === currentRoute) {
                        setActiveMenuItem(index);
                    }
                });
            }else{
                setActiveMenuItem(0);
            }
        }
        // eslint-disable-next-line
    }, [activeMenuItem]);

    function handleClick(index) {
        if(typeof index === "undefined") {
            return;
        }

        closeMobileMenu();
        setActiveMenuItem(index);
    }

    const handleLogout = () => {
        document.cookie = "token=; expires=-1; Path=/";
        window.location.href = "/login";

    }

    const changeSeason = (event) => {

        localStorage.setItem("season", event.value);
        window.location.reload();

    }

    const closeMobileMenu = () => {
        document.getElementById("wg-mainMenu").classList.remove("open");
        document.getElementById("wg-mainMenuMobile").classList.remove("open");
        document.getElementById("wg-hamburgerMenu").classList.remove("open");
        document.getElementById("wg-mainContainer").classList.remove("slideLeft");
    }

    const showMobileMenu = () => {
        document.getElementById("wg-mainMenu").classList.toggle("open");
        document.getElementById("wg-mainMenuMobile").classList.toggle("open");
        document.getElementById("wg-hamburgerMenu").classList.toggle("open");
        document.getElementById("wg-mainContainer").classList.toggle("slideLeft");
    }

    return(
        <React.Fragment>
            <div className={"wg-mainMenuMobile"} id={"wg-mainMenuMobile"}>
                <div className={"wg-mainMenuMobileOverlay"} onClick={() => showMobileMenu()}/>
                <div className={"wg-societyLogo"}>
                    <Link to={"/"}>
                        <img src={localStorage.getItem('clubName') && localStorage.getItem('clubName').toLowerCase().includes('baldo') ? BaldoLogo : SocietyLogo} alt={"Society Logo Mobile"} />
                    </Link>
                </div>
                <div className={"wg-hamburgerMenu"} id={"wg-hamburgerMenu"} onClick={() => showMobileMenu()}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className={"wg-mainMenu"} id={"wg-mainMenu"}>
                <div className={"wg-societyLogo"}>
                    <Link to={"/"}>
                        <img src={localStorage.getItem('clubName') && localStorage.getItem('clubName').toLowerCase().includes('baldo') ? BaldoLogo : SocietyLogo} alt={"Society Logo"} />
                    </Link>
                </div>
                {/*<Button value={"Gestisci società"} vtype={"solid"} size={"xl"} />*/}

                <div style={{width: "80%"}}>
                    <Dropdown options={["2021/2022", "2022/2023","2023/2024", "2024/2025"]} placeholder={"Scegli stagione"}
                              onChange={event => changeSeason(event)}
                              value={localStorage.getItem("season") ? localStorage.getItem("season") : "2023/2024"}
                    />
                </div>

                <nav className={"wg-menuNav"}>
                    <ul>
                        {menuList.map((item, index) => {
                            return(
                                <div key={index}>
                                    {
                                        item.roles && item.roles.length > 0 ?
                                            // eslint-disable-next-line
                                            item.roles.map(role => {
                                                if(role === localStorage.getItem("role")) {

                                                    const menuItemsStorage = localStorage.getItem("menu_items");
                                                    let menuItems = new Array();
                                                    menuItems = menuItemsStorage ? menuItemsStorage.split(",") : ["events","payments","ratings","registry","teams"];

                                                    if(menuItems && menuItems.length > 0) {
                                                        return (
                                                            menuItems.map(menuItem => {
                                                                if(menuItem === item.key) {
                                                                    return(
                                                                        <li key={item.text}>
                                                                            <Link to={{
                                                                                pathname: `/${item.to}`,
                                                                                state: {
                                                                                    prevPath: window.location.pathname
                                                                                }
                                                                            }}
                                                                                  className={index === activeMenuItem ? "active" : ""}
                                                                                  onClick={() => handleClick(index)}>
                                                                                <div className={"wg-menuItem"}>
                                                                                    <i className={`wi-${item.icon}`} aria-hidden={"true"} />
                                                                                    <span className={"wg-itemText"}>{item.text}</span>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    );
                                                                }
                                                            })
                                                        )
                                                    }
                                                }
                                            })
                                            :
                                            null
                                    }
                                </div>
                            )
                        })}
                        <div className={"wg-divider"} />
                        <li>
                            <Link to={{
                                pathname: `/`,
                                state: {
                                    prevPath: window.location.pathname
                                }
                            }}
                                  onClick={() => toast.info("Funzione non disponibile")}>
                                <div className={"wg-menuItem"}>
                                    <i className={`wi-life-ring-solid`} aria-hidden={"true"} />
                                    <span className={"wg-itemText"}>Supporto</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={{
                                pathname: `/`,
                                state: {
                                    prevPath: window.location.pathname
                                }
                            }}
                                  onClick={() => handleLogout()}>
                                <div className={"wg-menuItem"}>
                                    <i className={`wi-logout-solid`} aria-hidden={"true"} />
                                    <span className={"wg-itemText"}>Logout</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>
    );
}

export default MainMenu;
