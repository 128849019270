// Importing React Dependencies
import React, {Suspense} from 'react';

// Importing WG Dependencies
import createResource from "../../components/services/Fetcher/Fetcher";
import Loader from "../../components/common/Loader/Loader";
import ListWrapper from "../../components/common/List/ListWrapper";
import storage from "../../components/storage/storage";
import Auth from "../../components/services/Auth/Auth";
import FilterArea from "../../components/common/FilterArea/FilterArea";


// Manage Dependencies

function TeamList (resources) {

    const teamList = resources.teamListRecource.read();

    if(teamList && teamList.length > 0) {
        return(
            <React.Fragment>
                <FilterArea showSearch={false} showSort={false} argument={"teamsList"} />
                <ListWrapper items={teamList}
                             showFilterArea={false}
                             columns={["team_id", "name", "players_count"]}
                             auth={Auth.getPermission(storage.manageStorage("local", "get", "role"))}
                             keysToFilter={["name"]}
                             listType={"card"}
                             avaiableActions={{
                                 rowActions: ["click"],
                                 dialogActions: ["click"]
                             }}
                             addCard={localStorage.getItem("role") === "admin" ?
                                 {
                                     title: "Crea squadra",
                                     description: "Inserisci i dati di squadra, giocatori e tasse"
                                 }
                             :
                                null
                             }
                             argument={"teams"}
                />
            </React.Fragment>
        )
    }else{
        return ("No teams available");
    }
}

export default function Teams() {

    return (
        <React.Fragment>
            <Suspense fallback={<Loader />}>
                <TeamList teamListRecource={
                    createResource(
                        "/teams",
                        "GET",
                        null,
                        null
                    )
                }
                />
            </Suspense>
        </React.Fragment>
    )
}
