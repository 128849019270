// Importing React Dependencies
import React, {useState, useEffect} from 'react';

// Importing WG Dependencies

// Manage Dependencies

const Checkbox = (checkboxProps) => {

    // Set props variables
    const [props, setProps] = useState(checkboxProps);

    useEffect(() => {
        setProps(props);
        // eslint-disable-next-line
    }, [checkboxProps]);

    return (
        <label className={"wg-checkboxLabel"} htmlFor={props.id}>
            {props.label}
            <input type={"checkbox"} {...props} defaultChecked={props.defaultChecked === "1" ? 1 : props.defaultChecked === "0" ? 0 : props.defaultChecked } />
            <span className={"wg-checkboxMark " + props.size + ' ' + props.state + ' ' + props.required} />
        </label>
    );
};

export default Checkbox;
