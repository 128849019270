/* eslint-disable */

// Importing React Dependencies
import React from 'react';

// Importing Wg Dependencies
import ListItems from "./ListItems";
import TeamsDetail from "../Detail/TeamsDetail";

// Manage Dependencies

let firstLoad = false;

const ListWrapper = (listProps) => {

    // List states
    const [itemList, setItemList] = React.useState(listProps.items);
    const [playerId, setPlayerId] = React.useState(null);
    const [playerData, setPlayerData] = React.useState(null);


    // Dialog States
    const [dialogVisible, setDialogVisible] = React.useState(false);

    const refreshList = (playerId, value, argument, globalRefresh = false, updatedData, custom = false) => {

        if(!globalRefresh) {
            let fakeItemList = listProps.items;

            // eslint-disable-next-line
            fakeItemList.map(item => {
                if(item.player_id === playerId) {
                    if(!custom) {
                        item[argument] = value;
                    }else{
                        // eslint-disable-next-line
                        item[argument].map((argument, index) => {
                            if(value[index] && value[index].payments) {
                                if(value[index].payments.length > 0) {
                                    let paid = 0;
                                    // eslint-disable-next-line
                                    value[index].payments.map(val => {
                                       paid = paid + Number(val.payment);
                                    });
                                    argument.subscription_fee_amount_paid = paid;
                                }
                                argument.subscription_fee_amount = value[index].subscription_fee_amount;
                            }
                        });
                    }
                }
            });

            setItemList(fakeItemList);
        }else{
            setItemList(updatedData);
        }

    };

    const getActionHandler = (visible = false) => {

        if(!listProps.argument) {
            return;
        }

        switch (listProps.argument) {
            case 'teams':
                return (
                    <TeamsDetail setItemList={setItemList} items={listProps.items} avaiableItemList={listProps.avaiableItemList} dialogVisible={dialogVisible} setDialogVisible={setDialogVisible} teamId={listProps.teamId} />
                );
            case 'payments':
                return <PaymentsDetailNew playerId={playerId}
                                          dialogVisible={dialogVisible}
                                          setDialogVisible={setDialogVisible}
                                          playerData={playerData}
                                          teamId={listProps.teamId}
                                          refreshList={refreshList} />
            case 'medical_area':
                return <MedicalAreaDetail playerId={playerId} setPlayerId={setPlayerId} playerData={playerData} setPlayerData={setPlayerData}
                                          teamId={listProps.teamId} dialogVisible={dialogVisible} setDialogVisible={setDialogVisible}
                                          refreshList={refreshList}
                    />;
            default:
                return;
        }
    };

    React.useEffect(() => {
        if(playerId) {
            setDialogVisible(true);
        }
    }, [playerId]);

    React.useEffect(() => {
        if(listProps.updateData > 0) {
        }
    }, [listProps.updateData]);


    //******************** Registry Management ********************//

    const getPlayerData = (playerData) => {
        if(!playerData) {
            return;
        }

        if(playerData) {
            let newItemList = itemList;
            newItemList.unshift(playerData);
            setItemList(newItemList);
        }
    };

    React.useEffect(() => {
        if(!firstLoad) {
            firstLoad = true;
        }else{
            if(!dialogVisible) {
                getActionHandler(false);
            }
        }

        if(!dialogVisible) {
            setPlayerId(null);
        }
        // eslint-disable-next-line
    }, [dialogVisible]);

    return (
        <React.Fragment>
            <ListItems {...listProps} itemList={listProps.items}
                       showFilterArea={listProps.showFilterArea === false ? false : true}
                       dialogActions={(listProps && listProps.avaiableActions && listProps.avaiableActions.dialogActions ? getActionHandler : null)}
                       dialogVisible={dialogVisible}
                       setDialogVisible={setDialogVisible}
                       setPlayerId={setPlayerId}
                       setPlayerData={setPlayerData}
                       argument={listProps.argument}
                       teamId={listProps.teamId}
                       fee={listProps.subscription_fee_amount}
                       refreshList={refreshList}
            />
        </React.Fragment>
    );
};

export default ListWrapper
