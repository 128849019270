// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import NoResultImage from '../../../assets/img/no-results-found.png';

// Manage Dependencies


function NoResultFound(props) {



    return (
        <div className={"wg-noResultFound"}>
            <div className={"wg-noResultFoundImage"}>
                <img src={NoResultImage} alt={"Nessun risultato"} />
            </div>
            <div className={"wg-noResultFoundLabel"}>{props.message ? props.message : "Nessun risultato trovato"}</div>
        </div>
    );
}

export default NoResultFound
