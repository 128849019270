// Importing React Dependencies
import React from 'react';

// Importing WG Dependencies
import App from "./App";

import '../assets/style/Light.css';


// Manage Dependencies


function Index() {
    return <App />;
}

export default Index