import React from 'react';

// Import dependencies
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import CookieManager from "../components/storage/CookieManager";

// Import routes
import AppWrapper from "./AppWrapper";
import LoginNew from "./Login/LoginNew";


const App = () => {
    return (
        <Router>
            <React.Fragment>
                <Switch>
                    <Route path={"/login"}>
                        <LoginNew />
                    </Route>t
                    <ProtectedRoute path={"/"}>
                        <AppWrapper />
                    </ProtectedRoute>
                </Switch>
            </React.Fragment>
        </Router>
    );
};

function isAuth() {
    return CookieManager.getCookie("token");
}

// Create protected route
function ProtectedRoute({children, path}) {
    return(
        <Route
            path={path}
            render={({location}) =>
                isAuth() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

setTimeout(() => {
    // console.clear();
}, 1500);

export default App;
