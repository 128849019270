// Importing React Dependencies
import React, {useState, useEffect} from 'react';

// Importing WG Dependencies
import {toast} from 'react-toastify';

// Manage Dependencies

const Input = (inputProps) => {

    // Set props variables
    const [props] = useState(inputProps);
    let isValid = !!props;

    // Set general button variables
    let value, placeholder, size, type, state, required;
    let classes = "wg-input";
    let hasIcon = !!props.icon;

    // Error Variable
    const errorIdentifier = 'Button #' + props.id + ':';

    // Checking props and set default if prop undefined
    if(isValid) {

        // If nothing return, set default value
        value = props.defaultValue === "" ?  null : props.defaultValue;
        placeholder = !props.placeholder ?  null : props.placeholder;
        size = !props.size ? "standard" : props.size;
        type = !props.type ? "text" : props.type;
        state = !props.state ? "enabled" : props.state;
        required = !props.required ? "optional" : "invalidField"
    }

    isValid = isValid ? !!props.defaultValue || !!props.placeholder : false;

    if(!props.id) {
        isValid = false;
    }

    value = value !== "" ? value : null;
    placeholder = placeholder !== "" ? placeholder : null;
    size = size !== "small" && size !== "standard" && size !== "higher" ? null : size;
    type = type !== "text" && type !== "password" && type !== "textarea" && type !== "number" && type !== "tel" ? null : type;
    state = state !== "enabled" && state !== "disabled" ? null : state;
    required = required !== null ? required : "optional";

    if(size === null || type === null || state === null || required === null || (value === null && placeholder === null)) {
        isValid = false;
    }

    useEffect(() => {
        if(!props.id) {
            // eslint-disable-next-line
            toast.error('Missing Input id!');
        }else{


            // If props aren't correct, show error
            if(value === null && placeholder === null) {
                toast.error(errorIdentifier + "Missing default value or placeholder");
            }
            if(size === null) {
                toast.error(errorIdentifier + "Wrong size");
            }
            if(type === null) {
                toast.error(errorIdentifier + "Wrong type");
            }
            if(state === null) {
                toast.error(errorIdentifier + "Wrong state");
            }
        }

    }, [errorIdentifier, props.id, size, state, type, value, placeholder, props.defaultValue]);


    // Return
    if(isValid) {

        // Classes variable setup ( Input size, type, state )
        classes = size || type || state || required ? classes + ' ' + size + ' ' + type + ' ' + state + ' ' + required : classes;

        if(type !== "textarea") {
            if(!hasIcon) {
                return (
                    <label className={"wg-inputLabel"} htmlFor={props.id}>
                        {props.label}
                        <input className={classes} id={props.id} name={props.name} type={type} {...props} defaultValue={props.defaultValue} autoComplete={"off"}/>
                    </label>
                );
            }else {
                return (
                    <label className={"wg-inputLabel"} htmlFor={props.id}>
                        {props.label}
                        <input className={classes} id={props.id} name={props.name} type={type} {...props} autoComplete={"off"} />
                        <i className={"wg-" + props.icon}/>
                    </label>
                );
            }
        }else{
           return(
               <label className={"wg-inputLabel"} htmlFor={props.id}>
                   {props.label}
                   <textarea className={classes} id={props.id} name={props.name} {...props} autoComplete={"off"}/>
               </label>
           )
        }
    }else{
        return null;
    }
};

export default Input;
